import React from "react";
import ReactDOM from 'react-dom';
import { Storage } from 'aws-amplify';
import imgLogo from './imgs/irvine-logo.svg';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

import AWS from 'aws-sdk';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ThankYouERAIrvine from './thankYouERAIrvine';
import Amplify from 'aws-amplify';

class RegisterERAIrvineUser extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,

            disqualified: true,
            showPopup: false,
            enableEligibleButton: false,

            dialogText: "",
            disqualifiedText: "According to your response, you do not qualify for the City of Irvine's current Emergency Rental Assistance Program. To learn about other assistance programs please call 211.",
            userRegisteredSuccessfully: false,


            doYouLiveInIrvine: null,
            doYouRent: null,
            areYouBehindOnRent: null,
            doYouHoldSection8HousingVoucher: null,
            experiencedFinancialHardshipInCovid: null,
            isAnnualIncomeLessThanMaxLimit: null,

            firstName: "",
            middleName: "",
            lastName: "",
            suffix: "",
            addressLine1: "",
            addressLine2: "",
            city: "Irvine",
            state: "",
            zipcode: "",
            phone: "",
            phoneType: "",
            email: "",
            gender: "",
            race: "",
            ethnicity: "",
            dateOfBirth: '0000-00-00',
            dontKnowDateOfBirth: false,
            interpretationServicesRequiredForLanguage: "",
            secondaryFirstName: "",
            secondaryLastName: "",
            secondaryEmail: "",
            secondaryPhone: "",
            householdNumber: null,
            countAdult: null,
            countChildren: null,
            headOfHousehold: null,
            veteran: null,
            currentMonthlyIncome: "",
            totalHouseholdAnnualIncome: "",
            // impactOfCovid19: "",
            qualifiedForUnemploymentBenefits: false,
            experiencedReductionInHouseholdIncome: false,
            incurredSignificantCosts: false,
            reductionOfChildSupport: false,
            otherFinancialHardship: false,
            isSomeoneOnRentalOrLeaseAgreement: null,
            landlordFullName: "",
            landlordEmail: "",
            landlordPhone: "",
            monthlyRentPayment: "",
            monthsBehindOnRent: "",
            amountBehindOnRent: "",
            receivedHousingSubsidy: null,
            subsidyExplanation: "",
            filedCovid19FinancialStressDeclaration: null,
            receivedEvictionNotice: null,
            dateEvictionNoticeReceived: '0000-00-00',
            createdDate: null,
            createdTime: null,
            ipAddress: null,



            // Validation Error States
            doYouLiveInIrvine_Error: "",
            doYouRent_Error: "",
            areYouBehindOnRent_Error: "",
            doYouHoldSection8HousingVoucher_Error: "",
            experiencedFinancialHardshipInCovid_Error: "",
            isAnnualIncomeLessThanMaxLimit_Error: "",

            firstName_Error: "",
            middleName_Error: "",
            lastName_Error: "",
            suffix_Error: "",
            addressLine1_Error: "",
            addressLine2_Error: "",
            city_Error: "",
            state_Error: "",
            zipcode_Error: "",
            phone_Error: "",
            phoneType_Error: "",
            email_Error: "",
            gender_Error: "",
            race_Error: "",
            ethnicity_Error: "",
            dateOfBirth_Error: "",
            interpretationServicesRequiredForLanguage_Error: "",
            secondaryFirstName_Error: "",
            secondaryLastName_Error: "",
            secondaryEmail_Error: "",
            secondaryPhone_Error: "",
            householdNumber_Error: "",
            countAdult_Error: "",
            countChildren_Error: "",
            headOfHousehold_Error: "",
            veteran_Error: "",
            currentMonthlyIncome_Error: "",
            totalHouseholdAnnualIncome_Error: "",
            impactOfCovid19_Error: "",
            isSomeoneOnRentalOrLeaseAgreement_Error: "",
            landlordFullName_Error: "",
            landlordEmail_Error: "",
            landlordPhone_Error: "",
            monthlyRentPayment_Error: "",
            monthsBehindOnRent_Error: "",
            amountBehindOnRent_Error: "",
            receivedHousingSubsidy_Error: "",
            subsidyExplanation_Error: "",
            filedCovid19FinancialStressDeclaration_Error: "",
            receivedEvictionNotice_Error: "",
            dateEvictionNoticeReceived_Error: "",

            uploadFileSize_Error: "",

        }

        // preserve the initial state in a new object
        this.initialState = { ...this.state };

        this.rentalAgreementUpload = null;

        /////////// Validation Control References - Start ///////////
        this.doYouLiveInIrvineRef = React.createRef();
        this.doYouRentRef = React.createRef();
        this.areYouBehindOnRentRef = React.createRef();
        this.doYouHoldSection8HousingVoucherRef = React.createRef();
        this.experiencedFinancialHardshipInCovidRef = React.createRef();
        this.isAnnualIncomeLessThanMaxLimitRef = React.createRef();

        this.firstNameRef = React.createRef();
        this.middleNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.suffixRef = React.createRef();
        this.addressLine1Ref = React.createRef();
        this.addressLine2Ref = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipcodeRef = React.createRef();
        this.phoneRef = React.createRef();
        this.phoneTypeRef = React.createRef();
        this.emailRef = React.createRef();
        this.genderRef = React.createRef();
        this.raceRef = React.createRef();
        this.ethnicityRef = React.createRef();
        this.dateOfBirthRef = React.createRef();
        this.interpretationServicesRequiredForLanguageRef = React.createRef();
        this.secondaryFirstNameRef = React.createRef();
        this.secondaryLastNameRef = React.createRef();
        this.secondaryEmailRef = React.createRef();
        this.secondaryPhoneRef = React.createRef();
        this.householdNumberRef = React.createRef();
        this.countAdultRef = React.createRef();
        this.countChildrenRef = React.createRef();
        this.headOfHouseholdRef = React.createRef();
        this.veteranRef = React.createRef();
        this.currentMonthlyIncomeRef = React.createRef();
        this.totalHouseholdAnnualIncomeRef = React.createRef();
        this.impactOfCovid19Ref = React.createRef();
        this.qualifiedForUnemploymentBenefitsRef = React.createRef();
        this.experiencedReductionInHouseholdIncomeRef = React.createRef();
        this.incurredSignificantCostsRef = React.createRef();
        this.reductionOfChildSupportRef = React.createRef();
        this.otherFinancialHardshipRef = React.createRef();
        this.isSomeoneOnRentalOrLeaseAgreementRef = React.createRef();
        this.landlordFullNameRef = React.createRef();
        this.landlordEmailRef = React.createRef();
        this.landlordPhoneRef = React.createRef();
        this.monthlyRentPaymentRef = React.createRef();
        this.monthsBehindOnRentRef = React.createRef();
        this.amountBehindOnRentRef = React.createRef();
        this.receivedHousingSubsidyRef = React.createRef();
        this.subsidyExplanationRef = React.createRef();
        this.filedCovid19FinancialStressDeclarationRef = React.createRef();
        this.receivedEvictionNoticeRef = React.createRef();
        this.dateEvictionNoticeReceivedRef = React.createRef();
        /////////// Validation Control References - Ends ///////////

    }

    async componentDidMount() {
        //console.log("submit");
        //console.log(process.env.REACT_APP_ShowComingSoon);

        if (process.env.REACT_APP_ShowComingSoon === "1" || process.env.REACT_APP_ShowProgramClose === "1") {
            window.location.assign("/");
        }
        else {
            var addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = this.googleTranslateElementInit;

            await fetch('https://api.ipify.org/?format=json')
                .then(response => response.json())
                .then(data => {
                    /* use data here */
                    //console.log(data);
                    if (data !== undefined) {
                        this.setState({ ipAddress: data.ip });
                    }
                });
        }
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    submitApplication = async () => {
        this.setState({ isLoading: true });

        if (this.validateControls() === false) {
            // Validation Failed - early return.
            this.setState({ isLoading: false });
            return;
        }

        try {
            var res = await this.registerUser();
            //var res = await this.dummyRequest();
            //console.log(res);

            if (res.message === "Endpoint request timed out") {
                console.log("Error: Endpoint request timed out.");

                // if its a last attempt - show timeout message to the user.
                this.setState({ dialogText: "Request timed out. Please try submitting again." }, () => { this.setState({ showPopup: true }); });
            }
            else {
                if (res.statusCode === 200) {
                    await this.uploadDocument();
                    await this.sendEmail(res.body);
                    // this.setState({ userRegisteredSuccessfully: true });
                    this.setState({ isLoading: false });

                    ReactDOM.render(<ThankYouERAIrvine individualID={res.body} />, document.getElementById('root'));
                    return;
                }

                if (res !== null && res !== undefined && res.body !== '' && res.body !== null && res.body !== undefined) {
                    //alert(res.body);
                    this.setState({ dialogText: res.body }, () => { this.setState({ showPopup: true }); });

                    // if (res.statusCode === 200) {
                    //     window.location.reload();
                    // }
                }
            }
        }
        catch (err) {

            console.log(err.response);
            if (err.response && err.response.status === 504) {
                console.log("Error: Endpoint request timed out.");

                // if its a last attempt - show timeout message to the user.
                this.setState({ dialogText: "Request timed out. Please try submitting again." }, () => { this.setState({ showPopup: true }); });
            }
            console.log(err);
        }

        this.setState({ isLoading: false });
    }

    registerUser = async () => {
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    'info':
                    {
                        "doYouLiveInIrvine": this.state.doYouLiveInIrvine,
                        "doYouRent": this.state.doYouRent,
                        "areYouBehindOnRent": this.state.areYouBehindOnRent,
                        "doYouHoldSection8HousingVoucher": this.state.doYouHoldSection8HousingVoucher,
                        "experiencedFinancialHardshipInCovid": this.state.experiencedFinancialHardshipInCovid,
                        "isAnnualIncomeLessThanMaxLimit": this.state.isAnnualIncomeLessThanMaxLimit,

                        "firstName": this.state.firstName,
                        "middleName": this.state.middleName,
                        "lastName": this.state.lastName,
                        "suffix": this.state.suffix,
                        "addressLine1": this.state.addressLine1,
                        "addressLine2": this.state.addressLine2,
                        "city": this.state.city,
                        "state": this.state.state,
                        "zipcode": this.state.zipcode,
                        "phone": this.state.phone,
                        "phoneType": this.state.phoneType,
                        "email": this.state.email,
                        "gender": this.state.gender,
                        "race": this.state.race,
                        "ethnicity": this.state.ethnicity,
                        "dateOfBirth": (this.state.dateOfBirth === '0000-00-00') ? '1000-01-01' : this.state.dateOfBirth,
                        "dontKnowDateOfBirth": this.state.dontKnowDateOfBirth,
                        "interpretationServicesRequiredForLanguage": this.state.interpretationServicesRequiredForLanguage,
                        "secondaryFirstName": this.state.secondaryFirstName,
                        "secondaryLastName": this.state.secondaryLastName,
                        "secondaryEmail": this.state.secondaryEmail,
                        "secondaryPhone": this.state.secondaryPhone,
                        "householdNumber": this.state.householdNumber,
                        "countAdult": this.state.countAdult,
                        "countChildren": this.state.countChildren,
                        "headOfHousehold": this.state.headOfHousehold,
                        "veteran": this.state.veteran,
                        "currentMonthlyIncome": this.state.currentMonthlyIncome,
                        "totalHouseholdAnnualIncome": this.state.totalHouseholdAnnualIncome,
                        // "impactOfCovid19": this.state.impactOfCovid19,
                        "qualifiedForUnemploymentBenefits": this.state.qualifiedForUnemploymentBenefits,
                        "experiencedReductionInHouseholdIncome": this.state.experiencedReductionInHouseholdIncome,
                        "incurredSignificantCosts": this.state.incurredSignificantCosts,
                        "reductionOfChildSupport": this.state.reductionOfChildSupport,
                        "otherFinancialHardship": this.state.otherFinancialHardship,
                        "isSomeoneOnRentalOrLeaseAgreement": this.state.isSomeoneOnRentalOrLeaseAgreement,
                        "landlordFullName": this.state.landlordFullName,
                        "landlordEmail": this.state.landlordEmail,
                        "landlordPhone": this.state.landlordPhone,
                        "monthlyRentPayment": this.state.monthlyRentPayment,
                        "monthsBehindOnRent": this.state.monthsBehindOnRent,
                        "amountBehindOnRent": this.state.amountBehindOnRent,
                        "receivedHousingSubsidy": this.state.receivedHousingSubsidy,
                        "subsidyExplanation": this.state.subsidyExplanation,
                        "filedCovid19FinancialStressDeclaration": this.state.filedCovid19FinancialStressDeclaration,
                        "receivedEvictionNotice": this.state.receivedEvictionNotice,
                        "dateEvictionNoticeReceived": (this.state.dateEvictionNoticeReceived === '0000-00-00') ? '1000-01-01' : this.state.dateEvictionNoticeReceived,
                        "ipAddress": this.state.ipAddress,
                    }
                })
        };

        const response = await fetch('https://fqbpnvf0xd.execute-api.us-west-2.amazonaws.com/uw-api-stage/registererairvineuser', requestOptions);
        return await response.json();
    };

    uploadDocument = async () => {
        if (this.rentalAgreementUpload === null || this.rentalAgreementUpload.files === null || this.rentalAgreementUpload.files.length === 0) {
            // early return
            return;
        }

        this.setState({ isLoading: true });

        // Upload the rental agreement document
        if (this.state.email !== '' && this.state.email !== null && this.state.email !== undefined &&
            this.rentalAgreementUpload !== null && this.rentalAgreementUpload.files !== null && this.rentalAgreementUpload.files.length > 0) {
            try {

                Amplify.configure({
                    Auth: {
                        identityPoolId: process.env.REACT_APP_UserIdentityPoolID, 
                        region: process.env.REACT_APP_Region, 
                        userPoolId: process.env.REACT_APP_UserUserPoolId,
                        userPoolWebClientId: process.env.REACT_APP_UserUserPoolWebClientId,
                    },
                    Storage: {
                      AWSS3: {
                          bucket: process.env.REACT_APP_S3FrontendBucket, //REQUIRED -  Amazon S3 bucket
                          region: process.env.REACT_APP_Region, //OPTIONAL -  Amazon service region
                      }
                    },
                    API: {
                      endpoints: [
                          {
                              name: process.env.REACT_APP_APIGatewayName,
                              endpoint: process.env.REACT_APP_APIEndpoint
                          }
                      ]
                    }
                });

                // User - S3UploadUser
                AWS.config.update({
                    accessKeyId: "AKIA3XX74FPPVTXLEZGA",
                    secretAccessKey: "mwjUuQUbcCduG8YTP98i07J4ICnviXkt1c+auLtC",
                    "region": "us-west-2" //   <- If you want send something to your bucket, you need take off this settings, because the S3 are global. 
                }); // for simplicity. In prod, use loadConfigFromFile, or env variables

                var result = await Storage.put(`ERA-IRVINE/${this.state.email}/rental_agreement/${this.rentalAgreementUpload.files[0].name}`,
                    this.rentalAgreementUpload.files[0],
                    { contentType: this.rentalAgreementUpload.files[0].type });
                //console.log(result);
                this.rentalAgreementUpload = null;
            }
            catch (err) {
                console.log(err)
                this.setState({ isLoading: false });
                // this.setState({ dialogText: "Error uploading the document. But, we have recorded the other details." }, () => { this.setState({ showPopup: true }); });

                return;
            }
        }
        this.setState({ isLoading: false });
    }

    sendEmail = async (individualID) => {
        // Simple POST request with a JSON body using fetch
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    'info':
                    {
                        "individualID": individualID,

                        "firstName": this.state.firstName,
                        "lastName": this.state.lastName,
                        "email": this.state.email,
                    }
                })
        };

        const response = await fetch('https://fqbpnvf0xd.execute-api.us-west-2.amazonaws.com/uw-api-stage/irvinesendapplicationsubmittedemail', requestOptions);
        return await response.json();
    }

    resetValues = () => {
        // Reset to initial state
        this.setState(this.initialState);
    }

    checkEligibility = async () => {
        var eligible = true;
        this.setState({ isLoading: true });

        if (this.state.isAnnualIncomeLessThanMaxLimit === "" || this.state.isAnnualIncomeLessThanMaxLimit === null || this.state.isAnnualIncomeLessThanMaxLimit === undefined) {
            this.setState({ isAnnualIncomeLessThanMaxLimit_Error: "Answer is required" });
            this.isAnnualIncomeLessThanMaxLimitRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ isAnnualIncomeLessThanMaxLimit_Error: "" });
        }

        if (this.state.experiencedFinancialHardshipInCovid === false || this.state.experiencedFinancialHardshipInCovid === "" || this.state.experiencedFinancialHardshipInCovid === null || this.state.experiencedFinancialHardshipInCovid === undefined) {
            this.setState({ experiencedFinancialHardshipInCovid_Error: "Must be Yes to continue" });
            this.experiencedFinancialHardshipInCovidRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ experiencedFinancialHardshipInCovid_Error: "" });
        }

        if (this.state.doYouHoldSection8HousingVoucher === true || this.state.doYouHoldSection8HousingVoucher === "" || this.state.doYouHoldSection8HousingVoucher === null || this.state.doYouHoldSection8HousingVoucher === undefined) {
            this.setState({ doYouHoldSection8HousingVoucher_Error: "Must be No to continue" });
            this.doYouHoldSection8HousingVoucherRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ doYouHoldSection8HousingVoucher_Error: "" });
        }

        if (this.state.areYouBehindOnRent === false || this.state.areYouBehindOnRent === "" || this.state.areYouBehindOnRent === null || this.state.areYouBehindOnRent === undefined) {
            this.setState({ areYouBehindOnRent_Error: "Must be Yes to continue" });
            this.areYouBehindOnRentRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ areYouBehindOnRent_Error: "" });
        }

        if (this.state.doYouRent === false || this.state.doYouRent === "" || this.state.doYouRent === null || this.state.doYouRent === undefined) {
            this.setState({ doYouRent_Error: "Must be Yes to continue" });
            this.doYouRentRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ doYouRent_Error: "" });
        }

        if (this.state.doYouLiveInIrvine === false || this.state.doYouLiveInIrvine === "" || this.state.doYouLiveInIrvine === null || this.state.doYouLiveInIrvine === undefined) {
            this.setState({ doYouLiveInIrvine_Error: "Must be Yes to continue" });
            this.doYouLiveInIrvineRef.current.focus();

            eligible = false;
        }
        else {
            this.setState({ doYouLiveInIrvine_Error: "" });
        }

        if (eligible === false) {
            this.setState({ isLoading: false, disqualified: true, dialogText: this.state.disqualifiedText }, () => { this.setState({ showPopup: true }); });

            //alert("According to your response, you do not qualify for the City of Irvine's current Emergency Rental Assistance Program.  To learn about other assistance programs please call 211.");
            return false;
        }

        window.scrollTo(0, 0); // scroll to the top of the window

        this.setState({ isLoading: false, disqualified: false }, () => { this.firstNameRef.current.focus(); });
        return true;
    }

    handleBack = () => {
        this.setState({ isLoading: false, disqualified: true });
    }

    closePopup = () => {
        if (this.state.userRegisteredSuccessfully === true) {
            window.location.reload();
        }
        this.setState({ isLoading: false, showPopup: false, userRegisteredSuccessfully: false });
    }

    validateControls = () => {
        var validationError = false;

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // if (this.rentalAgreementUpload !== null && this.rentalAgreementUpload.files !== null && this.rentalAgreementUpload.files.length > 0) {
        //     let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB
        //     if (this.rentalAgreementUpload.files[0].size > maxAllowedSize) {
        //         this.setState({ uploadFileSize_Error: "File too big, please select a file less than 5 MB" });
        //         // this.subsidyExplanationRef.current.focus();
    
        //         validationError = true;
        //     }
        //     else {
        //         this.setState({ uploadFileSize_Error: "" });
        //     }
        // }

        if (this.state.receivedHousingSubsidy === true &&
            (this.state.subsidyExplanation === "" || this.state.subsidyExplanation === null || this.state.subsidyExplanation === undefined)) {
            this.setState({ subsidyExplanation_Error: "Subsidy Explanation is required" });
            this.subsidyExplanationRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ subsidyExplanation_Error: "" });
        }
        if (this.state.receivedHousingSubsidy === "" || this.state.receivedHousingSubsidy === null || this.state.receivedHousingSubsidy === undefined) {
            this.setState({ receivedHousingSubsidy_Error: "Answer is required" });
            this.receivedHousingSubsidyRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ receivedHousingSubsidy_Error: "" });
        }

        if (this.state.amountBehindOnRent === "" || this.state.amountBehindOnRent === null || this.state.amountBehindOnRent === undefined) {
            this.setState({ amountBehindOnRent_Error: "Answer is required" });
            this.amountBehindOnRentRef.current.focus();

            validationError = true;
        }
        else {
            var amount = new RegExp(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/);
            if (!amount.test(this.state.amountBehindOnRent)) {
                this.setState({ amountBehindOnRent_Error: "Numbers only. No comma. No characters" });
                this.amountBehindOnRentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ amountBehindOnRent_Error: "" });
            }
        }

        if (this.state.monthsBehindOnRent === "" || this.state.monthsBehindOnRent === null || this.state.monthsBehindOnRent === undefined) {
            this.setState({ monthsBehindOnRent_Error: "Answer is required" });
            this.monthsBehindOnRentRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ monthsBehindOnRent_Error: "" });
        }

        if (this.state.monthlyRentPayment === "" || this.state.monthlyRentPayment === null || this.state.monthlyRentPayment === undefined) {
            this.setState({ monthlyRentPayment_Error: "Answer is required" });
            this.monthlyRentPaymentRef.current.focus();

            validationError = true;
        }
        else {
            var amount4 = new RegExp(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/);
            if (!amount4.test(this.state.monthlyRentPayment)) {
                this.setState({ monthlyRentPayment_Error: "Numbers only. No comma. No characters" });
                this.monthlyRentPaymentRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ monthlyRentPayment_Error: "" });
            }
        }

        if (this.state.landlordPhone === "" || this.state.landlordPhone === null || this.state.landlordPhone === undefined) {
            this.setState({ landlordPhone_Error: "Landlord phone is required" });
            this.landlordPhoneRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ landlordPhone_Error: "" });
        }

        if (this.state.landlordEmail === "" || this.state.landlordEmail === null || this.state.landlordEmail === undefined) {
            this.setState({ landlordEmail_Error: "Landlord email is required" });
            this.landlordEmailRef.current.focus();

            validationError = true;
        }
        else {
            var emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern.test(this.state.landlordEmail)) {
                this.setState({ landlordEmail_Error: "Invalid Landlord email" });
                this.landlordEmailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ landlordEmail_Error: "" });
            }
        }

        if (this.state.landlordFullName === "" || this.state.landlordFullName === null || this.state.landlordFullName === undefined) {
            this.setState({ landlordFullName_Error: "Landlord's full name is required" });
            this.landlordFullNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ landlordFullName_Error: "" });
        }

        if (this.state.isSomeoneOnRentalOrLeaseAgreement === "" || this.state.isSomeoneOnRentalOrLeaseAgreement === null || this.state.isSomeoneOnRentalOrLeaseAgreement === undefined) {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "Answer is required" });
            this.isSomeoneOnRentalOrLeaseAgreementRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }

        if (this.state.otherFinancialHardship === false &&
            this.state.reductionOfChildSupport === false &&
            this.state.incurredSignificantCosts === false &&
            this.state.experiencedReductionInHouseholdIncome === false &&
            this.state.qualifiedForUnemploymentBenefits === false) {
            this.setState({ impactOfCovid19_Error: "Impact selection is required" });
            this.isSomeoneOnRentalOrLeaseAgreementRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ impactOfCovid19_Error: "" });
        }

        if (this.state.totalHouseholdAnnualIncome === "" || this.state.totalHouseholdAnnualIncome === null || this.state.totalHouseholdAnnualIncome === undefined) {
            this.setState({ totalHouseholdAnnualIncome_Error: "Answer is required" });
            this.totalHouseholdAnnualIncomeRef.current.focus();

            validationError = true;
        }
        else {
            var amount2 = new RegExp(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/);
            if (!amount2.test(this.state.totalHouseholdAnnualIncome)) {
                this.setState({ totalHouseholdAnnualIncome_Error: "Numbers only. No comma. No characters" });
                this.totalHouseholdAnnualIncomeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ totalHouseholdAnnualIncome_Error: "" });
            }
        }

        if (this.state.currentMonthlyIncome === "" || this.state.currentMonthlyIncome === null || this.state.currentMonthlyIncome === undefined) {
            this.setState({ currentMonthlyIncome_Error: "Answer is required" });
            this.currentMonthlyIncomeRef.current.focus();

            validationError = true;
        }
        else {
            var amount1 = new RegExp(/^[0-9]\d{0,9}(\.\d{1,5})?%?$/);
            if (!amount1.test(this.state.currentMonthlyIncome)) {
                this.setState({ currentMonthlyIncome_Error: "Numbers only. No comma. No characters" });
                this.currentMonthlyIncomeRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ currentMonthlyIncome_Error: "" });
            }
        }

        if (this.state.veteran === "" || this.state.veteran === null || this.state.veteran === undefined) {
            this.setState({ veteran_Error: "Answer is required" });
            this.veteranRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ veteran_Error: "" });
        }

        if (this.state.headOfHousehold === "" || this.state.headOfHousehold === null || this.state.headOfHousehold === undefined) {
            this.setState({ headOfHousehold_Error: "Answer is required" });
            this.headOfHouseholdRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ headOfHousehold_Error: "" });
        }
        if (this.state.countChildren === "" || this.state.countChildren === null || this.state.countChildren === undefined) {
            this.setState({ countChildren_Error: "Answer is required" });
            this.countChildrenRef.current.focus();

            validationError = true;
        }
        else if (this.state.countChildren > 100 || this.state.countChildren < 0) {
            this.setState({ countChildren_Error: "Value should be between 0 to 100" });
            this.countChildrenRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ countChildren_Error: "" });
        }
        if (this.state.countAdult === "" || this.state.countAdult === null || this.state.countAdult === undefined) {
            this.setState({ countAdult_Error: "Answer is required" });
            this.countAdultRef.current.focus();

            validationError = true;
        }
        else if (this.state.countAdult > 100 || this.state.countAdult < 0) {
            this.setState({ countAdult_Error: "Value should be between 0 to 100" });
            this.countAdultRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ countAdult_Error: "" });
        }
        if (this.state.householdNumber === "" || this.state.householdNumber === null || this.state.householdNumber === undefined) {
            this.setState({ householdNumber_Error: "Answer is required" });
            this.householdNumberRef.current.focus();

            validationError = true;
        }
        else if (this.state.householdNumber > 100 || this.state.householdNumber < 0) {
            this.setState({ householdNumber_Error: "Value should be between 0 to 100" });
            this.householdNumberRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ householdNumber_Error: "" });
        }
        if (this.state.dontKnowDateOfBirth !== true &&
            (this.state.dateOfBirth === "" || this.state.dateOfBirth === null || this.state.dateOfBirth === undefined)) {
            this.setState({ dateOfBirth_Error: "Date Of Birth is required" });
            this.dateOfBirthRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ dateOfBirth_Error: "" });
        }
        if (this.state.ethnicity === "" || this.state.ethnicity === null || this.state.ethnicity === undefined) {
            this.setState({ ethnicity_Error: "Ethnicity is required" });
            this.ethnicityRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ ethnicity_Error: "" });
        }
        if (this.state.race === "" || this.state.race === null || this.state.race === undefined) {
            this.setState({ race_Error: "Race is required" });
            this.raceRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ race_Error: "" });
        }
        if (this.state.gender === "" || this.state.gender === null || this.state.gender === undefined) {
            this.setState({ gender_Error: "Gender is required" });
            this.genderRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ gender_Error: "" });
        }
        if (this.state.email === "" || this.state.email === null || this.state.email === undefined) {
            this.setState({ email_Error: "Email is required" });
            this.emailRef.current.focus();

            validationError = true;
        }
        else {
            var emailPattern1 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!emailPattern1.test(this.state.email)) {
                this.setState({ email_Error: "Invalid Email" });
                this.emailRef.current.focus();

                validationError = true;
            }
            else {
                this.setState({ email_Error: "" });
            }
        }

        if (this.state.phoneType === "" || this.state.phoneType === null || this.state.phoneType === undefined) {
            this.setState({ phoneType_Error: "Type of phone is required" });
            this.phoneTypeRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ phoneType_Error: "" });
        }

        if (this.state.phone === "" || this.state.phone === null || this.state.phone === undefined) {
            this.setState({ phone_Error: "Phone is required" });
            this.phoneRef.current.focus();

            validationError = true;
        }
        else if (this.state.phone.length < 10 || /^[0-9]*$/.test(this.state.phone) !== true) {
            this.setState({ phone_Error: "Invalid Phone" });
            this.phoneRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ phone_Error: "" });
        }

        if (this.state.zipcode === "" || this.state.zipcode === null || this.state.zipcode === undefined) {
            this.setState({ zipcode_Error: "Zip Code is required" });
            this.zipcodeRef.current.focus();

            validationError = true;
        }
        else if (this.state.zipcode.length < 5 || /^\d+$/.test(this.state.zipcode) !== true) {
            this.setState({ zipcode_Error: "Invalid Zip Code" });
            this.zipcodeRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ zipcode_Error: "" });
        }

        if (this.state.state === "" || this.state.state === null || this.state.state === undefined) {
            this.setState({ state_Error: "State is required" });
            this.stateRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ state_Error: "" });
        }

        if (this.state.city === "" || this.state.city === null || this.state.city === undefined) {
            this.setState({ city_Error: "City is required" });
            this.cityRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ city_Error: "" });
        }

        if (this.state.addressLine1 === "" || this.state.addressLine1 === null || this.state.addressLine1 === undefined) {
            this.setState({ addressLine1_Error: "Address is required" });
            this.addressLine1Ref.current.focus();

            validationError = true;
        }
        else {
            this.setState({ addressLine1_Error: "" });
        }

        if (this.state.lastName === "" || this.state.lastName === null || this.state.lastName === undefined) {
            this.setState({ lastName_Error: "Last Name is required" });
            this.lastNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ lastName_Error: "" });
        }

        if (this.state.firstName === "" || this.state.firstName === null || this.state.firstName === undefined) {
            this.setState({ firstName_Error: "First Name is required" });
            this.firstNameRef.current.focus();

            validationError = true;
        }
        else {
            this.setState({ firstName_Error: "" });
        }

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            return false;
        }

        return true;
    }

    updateButton = () => {
        if (this.state.doYouLiveInIrvine === true &&
            this.state.doYouRent === true &&
            this.state.areYouBehindOnRent === true &&
            this.state.doYouHoldSection8HousingVoucher === false &&
            this.state.experiencedFinancialHardshipInCovid === true &&
            this.state.isAnnualIncomeLessThanMaxLimit === true
        ) {
            this.setState({ enableEligibleButton: true });
        }
        else {
            this.setState({ enableEligibleButton: false });
        }
    }

    updateDoYouLiveInIrvineYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ doYouLiveInIrvine: true, doYouLiveInIrvine_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateDoYouLiveInIrvineNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                doYouLiveInIrvine: false,
                doYouLiveInIrvine_Error: "Must be Yes to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }
    updateDoYouRentYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ doYouRent: true, doYouRent_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateDoYouRentNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                doYouRent: false,
                doYouRent_Error: "Must be Yes to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }
    updateAreYouBehindOnRentYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ areYouBehindOnRent: true, areYouBehindOnRent_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateAreYouBehindOnRentNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                areYouBehindOnRent: false,
                areYouBehindOnRent_Error: "Must be Yes to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }
    updateDoYouHoldSection8HousingVoucherYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                doYouHoldSection8HousingVoucher: true,
                doYouHoldSection8HousingVoucher_Error: "Must be No to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }
    updateDoYouHoldSection8HousingVoucherNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ doYouHoldSection8HousingVoucher: false, doYouHoldSection8HousingVoucher_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateExperiencedFinancialHardshipInCovidYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ experiencedFinancialHardshipInCovid: true, experiencedFinancialHardshipInCovid_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateExperiencedFinancialHardshipInCovidNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                experiencedFinancialHardshipInCovid: false,
                experiencedFinancialHardshipInCovid_Error: "Must be Yes to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }
    updateIsAnnualIncomeLessThanMaxLimitYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ isAnnualIncomeLessThanMaxLimit: true, isAnnualIncomeLessThanMaxLimit_Error: "" }, () => { this.updateButton(); });
        }
    }
    updateIsAnnualIncomeLessThanMaxLimitNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({
                isAnnualIncomeLessThanMaxLimit: false,
                isAnnualIncomeLessThanMaxLimit_Error: "Must be Yes to continue",
                dialogText: this.state.disqualifiedText
            },
                () => {
                    this.setState({ showPopup: true });
                    this.updateButton();
                });
        }
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstName: name, firstName_Error: "" });
    }
    updateMiddleName = (evt) => {
        var name = evt.target.value;
        this.setState({ middleName: name, middleName_Error: "" });
    }
    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastName: name, lastName_Error: "" });
    }
    updateSuffix = (evt) => {
        var name = evt.target.value;
        this.setState({ suffix: name, suffix_Error: "" });
    }
    updateAddressLine1 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine1: name, addressLine1_Error: "" });
    }
    updateAddressLine2 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine2: name, addressLine2_Error: "" });
    }
    updateCity = (evt) => {
        var name = evt.target.value;
        this.setState({ city: name, city_Error: "" });
    }
    updateState = (evt) => {
        var name = evt.target.value;
        this.setState({ state: name, state_Error: "" });
    }
    updateZipcode = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ zipcode: name, zipcode_Error: "" });
    }
    updatePhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ phone: name, phone_Error: "" });
    }
    updatePhoneType = (evt) => {
        var name = evt.target.value;
        this.setState({ phoneType: name, phoneType_Error: "" });
    }
    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name, email_Error: "" });
    }
    updateGender = (evt) => {
        var name = evt.target.value;
        this.setState({ gender: name, gender_Error: "" });
    }
    updateRace = (evt) => {
        var name = evt.target.value;
        this.setState({ race: name, race_Error: "" });
    }
    updateEthnicity = (evt) => {
        var name = evt.target.value;
        this.setState({ ethnicity: name, ethnicity_Error: "" });
    }
    updateDateOfBirth = (evt) => {
        var name = evt.target.value;
        this.setState({ dateOfBirth: name, dateOfBirth_Error: "" });
    }
    updateDontKnowDateOfBirth = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ dontKnowDateOfBirth: true, dateOfBirth_Error: "" });
            document.getElementById("dateOfBirthId").readOnly = true;
        }
        else {
            this.setState({ dontKnowDateOfBirth: false, dateOfBirth_Error: "" });
            document.getElementById("dateOfBirthId").readOnly = false;
        }
    }
    updateInterpretationServicesRequiredForLanguage = (evt) => {
        var name = evt.target.value;
        this.setState({ interpretationServicesRequiredForLanguage: name, interpretationServicesRequiredForLanguage_Error: "" });
    }
    updateSecondaryFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryFirstName: name, secondaryFirstName_Error: "" });
    }
    updateSecondaryLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryLastName: name, secondaryLastName_Error: "" });
    }
    updateSecondaryEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryEmail: name, secondaryEmail_Error: "" });
    }
    updateSecondaryPhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ secondaryPhone: name, secondaryPhone_Error: "" });
    }
    updateHouseholdNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdNumber: name, householdNumber_Error: "" });
    }
    updateCountAdult = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countAdult: name, countAdult_Error: "" });
    }
    updateCountChildren = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countChildren: name, countChildren_Error: "" });
    }
    updateHeadOfHousehold = (evt) => {
        var name = evt.target.value;
        this.setState({ headOfHousehold: name, headOfHousehold_Error: "" });
    }
    updateVeteranYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ veteran: true, veteran_Error: "" });
        }
        else {
            this.setState({ veteran: false, veteran_Error: "" });
        }
    }
    updateVeteranNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ veteran: false, veteran_Error: "" });
        }
        else {
            this.setState({ veteran: true, veteran_Error: "" });
        }
    }
    updateCurrentMonthlyIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ currentMonthlyIncome: name, currentMonthlyIncome_Error: "" });
    }
    updateTotalHouseholdAnnualIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ totalHouseholdAnnualIncome: name, totalHouseholdAnnualIncome_Error: "" });
    }
    //updateImpactOfCovid19 = (evt) => {
    //}
    updateQualifiedForUnemploymentBenefits = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ qualifiedForUnemploymentBenefits: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ qualifiedForUnemploymentBenefits: false, impactOfCovid19_Error: "" });
        }
    }
    updateExperiencedReductionInHouseholdIncome = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ experiencedReductionInHouseholdIncome: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ experiencedReductionInHouseholdIncome: false, impactOfCovid19_Error: "" });
        }
    }
    updateIncurredSignificantCosts = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ incurredSignificantCosts: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ incurredSignificantCosts: false, impactOfCovid19_Error: "" });
        }
    }
    updateReductionOfChildSupport = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ reductionOfChildSupport: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ reductionOfChildSupport: false, impactOfCovid19_Error: "" });
        }
    }
    updateOtherFinancialHardship = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ otherFinancialHardship: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ otherFinancialHardship: false, impactOfCovid19_Error: "" });
        }
    }
    updateIsSomeoneOnRentalOrLeaseAgreementYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: true, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: false, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
    }
    updateIsSomeoneOnRentalOrLeaseAgreementNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: false, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: true, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
    }
    updateLandlordFullName = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordFullName: name, landlordFullName_Error: "" });
    }
    updateLandlordEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordEmail: name, landlordEmail_Error: "" });
    }
    updateLandlordPhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordPhone: name, landlordPhone_Error: "" });
    }
    updateMonthlyRentPayment = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyRentPayment: name, monthlyRentPayment_Error: "" });
    }
    updateMonthsBehindOnRent = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ monthsBehindOnRent: name, monthsBehindOnRent_Error: "" });
    }
    updateAmountBehindOnRent = (evt) => {
        var name = evt.target.value;
        this.setState({ amountBehindOnRent: name, amountBehindOnRent_Error: "" });
    }
    updateReceivedHousingSubsidyYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedHousingSubsidy: true, receivedHousingSubsidy_Error: "" });
        }
        else {
            this.setState({ receivedHousingSubsidy: false, receivedHousingSubsidy_Error: "" });
        }
    }
    updateReceivedHousingSubsidyNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedHousingSubsidy: false, receivedHousingSubsidy_Error: "" });
        }
        else {
            this.setState({ receivedHousingSubsidy: true, receivedHousingSubsidy_Error: "" });
        }
    }
    updateSubsidyExplanation = (evt) => {
        var name = evt.target.value;
        this.setState({ subsidyExplanation: name, subsidyExplanation_Error: "" });
    }
    updateFiledCovid19FinancialStressDeclarationYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ filedCovid19FinancialStressDeclaration: true, filedCovid19FinancialStressDeclaration_Error: "" });
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration: false, filedCovid19FinancialStressDeclaration_Error: "" });
        }
    }
    updateFiledCovid19FinancialStressDeclarationNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ filedCovid19FinancialStressDeclaration: false, filedCovid19FinancialStressDeclaration_Error: "" });
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration: true, filedCovid19FinancialStressDeclaration_Error: "" });
        }
    }
    updateReceivedEvictionNoticeYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedEvictionNotice: true, receivedEvictionNotice_Error: "" });
        }
        else {
            this.setState({ receivedEvictionNotice: false, receivedEvictionNotice_Error: "" });
        }
    }
    updateReceivedEvictionNoticeNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedEvictionNotice: false, receivedEvictionNotice_Error: "" });
        }
        else {
            this.setState({ receivedEvictionNotice: true, receivedEvictionNotice_Error: "" });
        }
    }
    updateDateEvictionNoticeReceived = (evt) => {
        var name = evt.target.value;
        this.setState({ dateEvictionNoticeReceived: name, dateEvictionNoticeReceived_Error: "" });
    }
    updateRentalAgreementUpload = () => {
        this.setState({ uploadFileSize_Error: "" });
    }

    googleTranslateElementInit() {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }

    render() {
        if (process.env.REACT_APP_ShowComingSoon !== "1" && process.env.REACT_APP_ShowProgramClose !== "1") {
            return (
                <div className="d-flex flex-column sticky-footer-wrapper">

                    {/* header starts */}
                    <header>
                        <nav className="navbar navbar-light navbar-inner py-1" id="headerBg">
                            <a className="navbar-brand" href="#">
                                <img src={imgLogo} height="100" className="logo" alt="Assist OC logo" loading="lazy" />
                            </a>
                            <div className="ml-auto d-flex">
                                <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                    <button className="btn btn-light d-inline-block mr-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                                </div>
                                <div id="google_translate_element"></div>
                            </div>
                        </nav>
                    </header>
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main flex-fill">
                        <div className="container-fluid">
                            <div className="details-page">
                                {/* content area starts */}
                                <div className="box-shadow p-3 mb-3">
                                    <h4 className="text-primary">City of Irvine, Emergency Rental Assistance Program (ERAP)</h4>
                                    <p>To qualify for this ERA program, you must be a resident of the City of Irvine.  To determine eligibility and apply for the program, please complete the application below.  Applications will be accepted until 5:00pm PDT Friday, March 19, 2021.  The application review process will begin after March 19th and selected applicants will be contacted for further information.</p>
                                    <p>Please use the translation feature dropdown to apply in languages other than English. If you require assistance in Korean, Mandarin, Farsi, Vietnamese or Spanish please call toll-free 844-950-4675.</p>
                                    <p>By proceeding with an application and clicking submit, you agree to the Privacy Policy that can be found at <a target="_blank" href="https://www.unitedwayoc.org/irvine-emergency-rental-assistance-program-privacy-notice/">this link</a>.</p>
                                    <hr />
                                    <div style={{ display: this.state.disqualified === true ? 'block' : 'none' }}>
                                        <div className="qualify-questions">
                                            <div className="form-row">
                                                {/* <div className="col-lg-4 border-right pl-0"> */}
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Do you live in the City of Irvine? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouLiveInIrvineYes} id="doYouLiveInIrvineYes" name="doYouLiveInIrvine" ref={this.doYouLiveInIrvineRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouLiveInIrvineYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouLiveInIrvineNo} id="doYouLiveInIrvineNo" name="doYouLiveInIrvine" class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouLiveInIrvineNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.doYouLiveInIrvine_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                <hr />
                                                <br />
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Do you rent? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouRentYes} id="doYouRentYes" name="doYouRent" ref={this.doYouRentRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouRentYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouRentNo} id="doYouRentNo" name="doYouRent" class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouRentNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.doYouRent_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                <hr />
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Are you behind on rent? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateAreYouBehindOnRentYes} id="areYouBehindOnRentYes" name="areYouBehindOnRent" ref={this.areYouBehindOnRentRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="areYouBehindOnRentYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateAreYouBehindOnRentNo} id="areYouBehindOnRentNo" name="areYouBehindOnRent" class="custom-control-input" />
                                                        <label class="custom-control-label" for="areYouBehindOnRentNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.areYouBehindOnRent_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                <hr className="d-block d-lg-none" />
                                                {/* </div>
                                            <div className="col-lg-8 mt-2 mt-lg-0 pr-0 pl-0 pl-lg-3"> */}
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Do you currently hold a Section 8 Housing Voucher? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouHoldSection8HousingVoucherYes} id="doYouHoldSection8HousingVoucherYes" name="doYouHoldSection8HousingVoucher" ref={this.doYouHoldSection8HousingVoucherRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouHoldSection8HousingVoucherYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateDoYouHoldSection8HousingVoucherNo} id="doYouHoldSection8HousingVoucherNo" name="doYouHoldSection8HousingVoucher" class="custom-control-input" />
                                                        <label class="custom-control-label" for="doYouHoldSection8HousingVoucherNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.doYouHoldSection8HousingVoucher_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                <hr />
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Has your household experienced financial hardships as a result of COVID-19 coronavirus pandemic? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateExperiencedFinancialHardshipInCovidYes} id="experiencedFinancialHardshipInCovidYes" name="experiencedFinancialHardshipInCovid" ref={this.experiencedFinancialHardshipInCovidRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="experiencedFinancialHardshipInCovidYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateExperiencedFinancialHardshipInCovidNo} id="experiencedFinancialHardshipInCovidNo" name="experiencedFinancialHardshipInCovid" class="custom-control-input" />
                                                        <label class="custom-control-label" for="experiencedFinancialHardshipInCovidNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.experiencedFinancialHardshipInCovid_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                <hr />
                                                {/* <div className="form-row"> */}
                                                <div className="col-12 pl-2 mb-3">
                                                    <div><label>Refer to the table below - find your household size.  Is your annual household income less than what is listed in the table? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateIsAnnualIncomeLessThanMaxLimitYes} id="isAnnualIncomeLessThanMaxLimitYes" name="isAnnualIncomeLessThanMaxLimit" ref={this.isAnnualIncomeLessThanMaxLimitRef} class="custom-control-input" />
                                                        <label class="custom-control-label" for="isAnnualIncomeLessThanMaxLimitYes">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" onChange={this.updateIsAnnualIncomeLessThanMaxLimitNo} id="isAnnualIncomeLessThanMaxLimitNo" name="isAnnualIncomeLessThanMaxLimit" class="custom-control-input" />
                                                        <label class="custom-control-label" for="isAnnualIncomeLessThanMaxLimitNo">No</label>
                                                    </div>
                                                    <div className="alert-small-text">{this.state.isAnnualIncomeLessThanMaxLimit_Error}</div>
                                                </div>
                                                {/* </div> */}
                                                {/* </div> */}
                                            </div>
                                        </div>

                                        {/* <h6 className="mt-5">The income limits according to the household size:</h6> */}
                                        <table class="table table-bordered qualify-info-table mt-2">
                                            <thead>
                                                <tr>
                                                    <th rowspan="2">Household Size</th>
                                                    <th rowspan="2">Maximum Household Income 80% AMI</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>$71,750</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>$82,000</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>$92,250</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>$102,450</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>$110,650</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>$118,850</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>$127,050</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>$135,250</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="text-right">
                                            <button class="btn btn-secondary mb-3 mb-sm-0" onClick={this.checkEligibility} disabled={this.state.enableEligibleButton === false}><i class="fas fa-arrow-right"></i> Continue</button>
                                        </div>
                                    </div>

                                    <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                        <div className="record-detail-main validation-text">
                                            <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none"><i class="fas fa-edit"></i> SUBMIT IRVINE-ERAP APPLICATION</h4>
                                            <div className="data-section">
                                                <div className="">
                                                    <React.Fragment>

                                                        <div className="box-gray mb-4 py-3">
                                                            <div className="box-details">
                                                                <div className="form-row">
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label>First Name <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.firstNameRef} onChange={this.updateFirstName} value={this.state.firstName} maxlength="100"></input>
                                                                            <div className="alert-small-text">{this.state.firstName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label>Middle Name </label>
                                                                            <input className="form-control" type="text" ref={this.middleNameRef} onChange={this.updateMiddleName} value={this.state.middleName} maxlength="100"></input>
                                                                            <div className="alert-small-text">{this.state.middleName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Last Name <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.lastNameRef} onChange={this.updateLastName} value={this.state.lastName} maxlength="100"></input>
                                                                            <div class="alert-small-text">{this.state.lastName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label>Suffix </label>
                                                                            <input className="form-control" type="text" ref={this.suffixRef} onChange={this.updateSuffix} maxlength="10" value={this.state.suffix}></input>
                                                                            <div className="alert-small-text">{this.state.suffix_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>Address Line 1 <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.addressLine1Ref} onChange={this.updateAddressLine1} maxlength="255" value={this.state.addressLine1}></input>
                                                                            <div className="alert-small-text">{this.state.addressLine1_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="form-group">
                                                                            <label>Address Line 2 </label>
                                                                            <input className="form-control" type="text" ref={this.addressLine2Ref} onChange={this.updateAddressLine2} maxlength="255" value={this.state.addressLine2}></input>
                                                                            <div class="alert-small-text">{this.state.addressLine2_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.cityRef} disabled onChange={this.updateCity} maxlength="30" value={this.state.city}></input>
                                                                            <div class="alert-small-text">{this.state.city_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>State <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.stateRef} onChange={this.updateState} value={this.state.state}>
                                                                                <option value="selectstate">-- select --</option>
                                                                                <option value="AL">AL</option>
                                                                                <option value="AK">AK</option>
                                                                                <option value="AR">AR</option>
                                                                                <option value="AZ">AZ</option>
                                                                                <option value="CA">CA</option>
                                                                                <option value="CO">CO</option>
                                                                                <option value="CT">CT</option>
                                                                                <option value="DC">DC</option>
                                                                                <option value="DE">DE</option>
                                                                                <option value="FL">FL</option>
                                                                                <option value="GA">GA</option>
                                                                                <option value="HI">HI</option>
                                                                                <option value="IA">IA</option>
                                                                                <option value="ID">ID</option>
                                                                                <option value="IL">IL</option>
                                                                                <option value="IN">IN</option>
                                                                                <option value="KS">KS</option>
                                                                                <option value="KY">KY</option>
                                                                                <option value="LA">LA</option>
                                                                                <option value="MA">MA</option>
                                                                                <option value="MD">MD</option>
                                                                                <option value="ME">ME</option>
                                                                                <option value="MI">MI</option>
                                                                                <option value="MN">MN</option>
                                                                                <option value="MO">MO</option>
                                                                                <option value="MS">MS</option>
                                                                                <option value="MT">MT</option>
                                                                                <option value="NC">NC</option>
                                                                                <option value="NE">NE</option>
                                                                                <option value="NH">NH</option>
                                                                                <option value="NJ">NJ</option>
                                                                                <option value="NM">NM</option>
                                                                                <option value="NV">NV</option>
                                                                                <option value="NY">NY</option>
                                                                                <option value="ND">ND</option>
                                                                                <option value="OH">OH</option>
                                                                                <option value="OK">OK</option>
                                                                                <option value="OR">OR</option>
                                                                                <option value="PA">PA</option>
                                                                                <option value="RI">RI</option>
                                                                                <option value="SC">SC</option>
                                                                                <option value="SD">SD</option>
                                                                                <option value="TN">TN</option>
                                                                                <option value="TX">TX</option>
                                                                                <option value="UT">UT</option>
                                                                                <option value="VT">VT</option>
                                                                                <option value="VA">VA</option>
                                                                                <option value="WA">WA</option>
                                                                                <option value="WI">WI</option>
                                                                                <option value="WV">WV</option>
                                                                                <option value="WY">WY</option>
                                                                            </select>

                                                                            <div class="alert-small-text">{this.state.state_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Zip Code <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.zipcodeRef} onChange={this.updateZipcode} maxlength="5" value={this.state.zipcode}></input>
                                                                            <div class="alert-small-text">{this.state.zipcode_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Phone <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.phoneRef} onChange={this.updatePhone} maxlength="20" value={this.state.phone}></input>
                                                                            <div class="alert-small-text">{this.state.phone_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Phone Type <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.phoneTypeRef} onChange={this.updatePhoneType} value={this.state.phoneType}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="Mobile">Mobile</option>
                                                                                <option value="Home">Home</option>
                                                                                <option value="Work">Work</option>
                                                                                <option value="Other">Other</option>
                                                                            </select>
                                                                            <div class="alert-small-text">{this.state.phoneType_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Email <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.emailRef} onChange={this.updateEmail} value={this.state.email} maxlength="255" ></input>
                                                                            <div class="alert-small-text">{this.state.email_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label>Gender <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.genderRef} onChange={this.updateGender} value={this.state.gender}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="Female">Female</option>
                                                                                <option value="Male">Male</option>
                                                                                <option value="Trans Female (MTF or Male to Female)">Trans Female (MTF or Male to Female)</option>
                                                                                <option value="Trans Male (FTM or Female to Male)">Trans Male (FTM or Female to Male)</option>
                                                                                <option value="Gender Non-Conforming">Gender Non-Conforming</option>
                                                                                <option value="Do not know">Do not know</option>
                                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                                            </select>
                                                                            <div className="alert-small-text">{this.state.gender_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Race <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.raceRef} onChange={this.updateRace} value={this.state.race}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="White (Hispanic or Latino)">White (Hispanic or Latino)</option>
                                                                                <option value="White (Non-Hispanic or Non-Latino)">White (Non-Hispanic or Non-Latino)</option>
                                                                                <option value="Black or African American">Black or African American</option>
                                                                                <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
                                                                                <option value="Middle Eastern and North African">Middle Eastern and North African</option>
                                                                                <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
                                                                                <option value="Asian">Asian</option>
                                                                                <option value="Other">Other</option>
                                                                                <option value="Do not know">Do not know</option>
                                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                                            </select>
                                                                            <div class="alert-small-text">{this.state.race_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Ethnicity <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.ethnicityRef} onChange={this.updateEthnicity} value={this.state.ethnicity}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="Non-Hispanic or Latino">Non-Hispanic or Latino</option>
                                                                                <option value="Hispanic or Latino">Hispanic or Latino</option>
                                                                                <option value="Do not know">Do not know</option>
                                                                                <option value="Refuse to answer">Refuse to answer</option>
                                                                            </select>
                                                                            <div class="alert-small-text">{this.state.ethnicity_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label>Date of Birth <i class="fas fa-asterisk asterisk mr-3"></i>
                                                                                <div class="custom-control custom-checkbox custom-control-inline">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateDontKnowDateOfBirth} id="dontKnowDateOfBirthId" />
                                                                                    <label class="custom-control-label" for="dontKnowDateOfBirthId">I don't know</label>
                                                                                </div>
                                                                            </label>
                                                                            <input className="form-control" type="date" ref={this.dateOfBirthRef} onChange={this.updateDateOfBirth} value={this.state.dateOfBirth} id="dateOfBirthId"></input>
                                                                            <div className="alert-small-text">{this.state.dateOfBirth_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>Are interpretation services required in one of the following languages?</label>
                                                                            <select className="form-control" onChange={this.updateInterpretationServicesRequiredForLanguage} value={this.state.interpretationServicesRequiredForLanguage}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="Spanish">Spanish</option>
                                                                                <option value="Farsi">Farsi</option>
                                                                                <option value="Mandarin">Mandarin</option>
                                                                                <option value="Vietnamese">Vietnamese</option>
                                                                                <option value="Korean">Korean</option>
                                                                                <option value="None">None</option>
                                                                            </select>
                                                                            <div class="alert-small-text">{this.state.interpretationServicesRequiredForLanguage_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="form-group">
                                                                            <label>Secondary Contact First Name </label>
                                                                            <input className="form-control" type="text" ref={this.secondaryFirstNameRef} onChange={this.updateSecondaryFirstName} value={this.state.secondaryFirstName} maxlength="100"></input>
                                                                            <div className="alert-small-text">{this.state.secondaryFirstName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Secondary Contact Last Name </label>
                                                                            <input className="form-control" type="text" ref={this.secondaryLastNameRef} onChange={this.updateSecondaryLastName} value={this.state.secondaryLastName} maxlength="100"></input>
                                                                            <div class="alert-small-text">{this.state.secondaryLastName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Secondary Contact Email</label>
                                                                            <input className="form-control" type="text" ref={this.secondaryEmailRef} onChange={this.updateSecondaryEmail} value={this.state.secondaryEmail} maxlength="255" ></input>
                                                                            <div class="alert-small-text">{this.state.secondaryEmail_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div class="form-group">
                                                                            <label>Secondary Contact Phone </label>
                                                                            <input className="form-control" type="text" ref={this.secondaryPhoneRef} onChange={this.updateSecondaryPhone} value={this.state.secondaryPhone} maxlength="20" ></input>
                                                                            <div class="alert-small-text">{this.state.secondaryPhone_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>How many total people live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="number" name="household" ref={this.householdNumberRef} onChange={this.updateHouseholdNumber} value={this.state.householdNumber} />
                                                                            <div class="alert-small-text">{this.state.householdNumber_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>How many adults live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="number" name="household" ref={this.countAdultRef} onChange={this.updateCountAdult} value={this.state.countAdult} />
                                                                            <div class="alert-small-text">{this.state.countAdult_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>How many children age 0-17 live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="number" name="household" ref={this.countChildrenRef} onChange={this.updateCountChildren} value={this.state.countChildren} />
                                                                            <div class="alert-small-text">{this.state.countChildren_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <label>Are you the Head of Household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <select className="form-control" ref={this.headOfHouseholdRef} onChange={this.updateHeadOfHousehold} value={this.state.headOfHousehold}>
                                                                                <option value="">-- select --</option>
                                                                                <option value="Yes">Yes</option>
                                                                                <option value="No">No</option>
                                                                                <option value="Don't Know">Don't Know</option>
                                                                            </select>
                                                                            <div className="alert-small-text">{this.state.headOfHousehold_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <div><label>Are you or anyone in your household a U.S. Veteran? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateVeteranYes} ref={this.veteranRef} id="veteranYes" name="veteran" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="veteranYes">Yes</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateVeteranNo} id="veteranNo" name="veteran" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="veteranNo">No</label>
                                                                            </div>
                                                                            <div class="alert-small-text">{this.state.veteran_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="form-group">
                                                                            <label>What is your current monthly household income? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.currentMonthlyIncomeRef} onChange={this.updateCurrentMonthlyIncome} value={this.state.currentMonthlyIncome}></input>
                                                                            <div class="alert-small-text">{this.state.currentMonthlyIncome_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div class="form-group">
                                                                            <label>What is your total household annual income? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.totalHouseholdAnnualIncomeRef} onChange={this.updateTotalHouseholdAnnualIncome} value={this.state.totalHouseholdAnnualIncome}></input>
                                                                            <div class="alert-small-text">{this.state.totalHouseholdAnnualIncome_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <div><label>Check all that apply to you and/or anyone in your household due to the impact of the COVID19 coronavirus pandemic <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                            <div className="form-row">
                                                                                <div className="col-md-6">
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateQualifiedForUnemploymentBenefits} id="qualifiedForUnemploymentBenefitsId" ref={this.impactOfCovid19Ref} />
                                                                                        <label class="custom-control-label" for="qualifiedForUnemploymentBenefitsId">Qualified for unemployment benefits</label>
                                                                                    </div>
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateExperiencedReductionInHouseholdIncome} id="experiencedReductionInHouseholdIncomeId" />
                                                                                        <label class="custom-control-label" for="experiencedReductionInHouseholdIncomeId">Experienced a reduction in household income</label>
                                                                                    </div>
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateIncurredSignificantCosts} id="incurredSignificantCostsId" />
                                                                                        <label class="custom-control-label" for="incurredSignificantCostsId">Incurred significant costs</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-6">
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateReductionOfChildSupport} id="reductionOfChildSupportId" />
                                                                                        <label class="custom-control-label" for="reductionOfChildSupportId">Reduction/loss of child support</label>
                                                                                    </div>
                                                                                    <div class="custom-control custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" onChange={this.updateOtherFinancialHardship} id="otherFinancialHardshipId" />
                                                                                        <label class="custom-control-label" for="otherFinancialHardshipId">Other financial hardship(s)</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="alert-small-text">{this.state.impactOfCovid19_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <div><label>Is someone in your household listed on the rental or lease agreement? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateIsSomeoneOnRentalOrLeaseAgreementYes} ref={this.isSomeoneOnRentalOrLeaseAgreementRef} id="isSomeoneOnRentalOrLeaseAgreementYes" name="isSomeoneOnRentalOrLeaseAgreement" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="isSomeoneOnRentalOrLeaseAgreementYes">Yes</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateIsSomeoneOnRentalOrLeaseAgreementNo} id="isSomeoneOnRentalOrLeaseAgreementNo" name="isSomeoneOnRentalOrLeaseAgreement" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="isSomeoneOnRentalOrLeaseAgreementNo">No</label>
                                                                            </div>
                                                                            <div class="alert-small-text">{this.state.isSomeoneOnRentalOrLeaseAgreement_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Landlord's full name <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.landlordFullNameRef} onChange={this.updateLandlordFullName} value={this.state.landlordFullName} maxlength="255"></input>
                                                                            <div class="alert-small-text">{this.state.landlordFullName_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Landlord email address <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.landlordEmailRef} onChange={this.updateLandlordEmail} value={this.state.landlordEmail} maxlength="255" ></input>
                                                                            <div class="alert-small-text">{this.state.landlordEmail_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div class="form-group">
                                                                            <label>Landlord phone number <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.landlordPhoneRef} onChange={this.updateLandlordPhone} value={this.state.landlordPhone} maxlength="20" ></input>
                                                                            <div class="alert-small-text">{this.state.landlordPhone_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>What is your monthly rent payment? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.monthlyRentPaymentRef} onChange={this.updateMonthlyRentPayment} value={this.state.monthlyRentPayment}></input>
                                                                            <div class="alert-small-text">{this.state.monthlyRentPayment_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>How many months are you behind on rent? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.monthsBehindOnRentRef} onChange={this.updateMonthsBehindOnRent} value={this.state.monthsBehindOnRent}></input>
                                                                            <div class="alert-small-text">{this.state.monthsBehindOnRent_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>What is the total dollar amount that you are behind on rent? <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.amountBehindOnRentRef} onChange={this.updateAmountBehindOnRent} value={this.state.amountBehindOnRent}></input>
                                                                            <div class="alert-small-text">{this.state.amountBehindOnRent_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <div><label>Since April 2020, have you received a housing subsidy? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateReceivedHousingSubsidyYes} ref={this.receivedHousingSubsidyRef} id="receivedHousingSubsidyYes" name="receivedHousingSubsidy" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="receivedHousingSubsidyYes">Yes</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateReceivedHousingSubsidyNo} id="receivedHousingSubsidyNo" name="receivedHousingSubsidy" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="receivedHousingSubsidyNo">No</label>
                                                                            </div>
                                                                            <div class="alert-small-text">{this.state.receivedHousingSubsidy_Error}</div>
                                                                        </div>
                                                                        <div class="form-group" style={{ display: this.state.receivedHousingSubsidy === true ? 'block' : 'none' }}>
                                                                            <label>If yes, please explain <i class="fas fa-asterisk asterisk"></i></label>
                                                                            <input className="form-control" type="text" ref={this.subsidyExplanationRef} onChange={this.updateSubsidyExplanation} maxlength="255" value={this.state.subsidyExplanation}></input>
                                                                            <div class="alert-small-text">{this.state.subsidyExplanation_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <div><label>Have you filed a Declaration of COVID-19-related financial distress with your landlord?</label></div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateFiledCovid19FinancialStressDeclarationYes} ref={this.filedCovid19FinancialStressDeclarationRef} id="filedCovid19FinancialStressDeclarationYes" name="filedCovid19FinancialStressDeclaration" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="filedCovid19FinancialStressDeclarationYes">Yes</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateFiledCovid19FinancialStressDeclarationNo} id="filedCovid19FinancialStressDeclarationNo" name="filedCovid19FinancialStressDeclaration" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="filedCovid19FinancialStressDeclarationNo">No</label>
                                                                            </div>
                                                                            <div class="alert-small-text">{this.state.filedCovid19FinancialStressDeclaration_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <div><label>Have you received an eviction notice?</label></div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateReceivedEvictionNoticeYes} ref={this.receivedEvictionNoticeRef} id="receivedEvictionNoticeYes" name="receivedEvictionNotice" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="receivedEvictionNoticeYes">Yes</label>
                                                                            </div>
                                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                                <input type="radio" onChange={this.updateReceivedEvictionNoticeNo} id="receivedEvictionNoticeNo" name="receivedEvictionNotice" class="custom-control-input" />
                                                                                <label class="custom-control-label" for="receivedEvictionNoticeNo">No</label>
                                                                            </div>
                                                                            <div class="alert-small-text">{this.state.receivedEvictionNotice_Error}</div>
                                                                        </div>
                                                                        <div className="form-group" style={{ display: this.state.receivedEvictionNotice === true ? 'block' : 'none' }}>
                                                                            <label>If yes, what is the Eviction Notice Date</label>
                                                                            <input className="form-control" type="date" ref={this.dateEvictionNoticeReceivedRef} onChange={this.updateDateEvictionNoticeReceived} value={this.state.dateEvictionNoticeReceived}></input>
                                                                            <div className="alert-small-text">{this.state.dateEvictionNoticeReceived_Error}</div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>Upload a copy of your rental or lease agreement (Upto 5 MB)</label>
                                                                            <div>
                                                                                <div class="file-upload mt-2">
                                                                                    <input id="upload" ref={ref => (this.rentalAgreementUpload = ref)} onChange={this.updateRentalAgreementUpload} type="file" name="file-upload" />
                                                                                </div>
                                                                                <div className="alert-small-text">{this.state.uploadFileSize_Error}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                </div>

                                                                <div className="text-right">
                                                                    <button class="btn btn-primary mb-3 mb-sm-0 mr-3" onClick={this.submitApplication}><i class="fas fa-user-check mr-2"></i> Submit</button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <Dialog
                                                            open={this.state.showPopup}
                                                            onClose={this.closePopup}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">{"Register User"}</DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    {this.state.dialogText}
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={this.closePopup} color="primary" autoFocus>
                                                                    OK
                                                            </Button>
                                                            </DialogActions>
                                                        </Dialog>

                                                        <Backdrop className="MuiBackdrop-root irvine-loader" open={this.state.isLoading} >
                                                            <CircularProgress />
                                                        </Backdrop>
                                                    </React.Fragment>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* content area ends */}
                        </div>
                    </main>
                    {/* main content ends */}

                    {/* footer starts */}
                    <footer className="foot bg-primary text-white text-center p-2">
                        <p className="mb-1">Powered by Orange County United Way</p>
                        {/* <Link target="_blank" to="/faqs" className="text-white">Read our FAQs</Link> */}
                    </footer>
                    {/* footer ends */}

                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default RegisterERAIrvineUser;
