import React from "react";
import { Auth, API } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import imgLogo from './imgs/logo-assist-oc.png';
import imgHomeless from './imgs/homeless.png';
import GoogleTranslate from './google';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from '@material-ui/core';

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,

            isGoolgeTranslateLoadedForSignIn: false,
            isGoolgeTranslateLoadedForSignedIn: false,

            isUserLoaded: false,
            applicantEmail: null,

            name: null,
            applicantID: null,
            fundingSource: null,
            dateApplied: null,
            dateApplied_Formatted: null,
            claimStatus: null,

            status: "Processing",

            paymentId:"",
            reconciliationId:"",
            isShowPaymentBtn: true,
            individualIDFundingSourceTransactionId: "",
            isSingalPayment: false,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });

        // const { accessToken } = await Auth.currentSession();
        // const cognitogroups = accessToken.payload['cognito:groups'];
        // console.log(cognitogroups);
        // if (cognitogroups && cognitogroups.length > 0 && cognitogroups[0] === 'IrvineLandlords') {  // IrvineApplicants
        // avoid duplicate calls
        // var payRes = await this.getData();

        // if (payRes[0] == 0) {
        //     document.getElementById("managePayment").disabled = true;
        // } else if (payRes[0] > 1) {
        //     this.setState({ isSingalPayment: false,name: payRes[1][3],claimStatus: payRes[1][4]});
        // }
        // else {
        //     this.setState({ isSingalPayment: true,individualIDFundingSourceTransactionId: payRes[1][0],paymentId: payRes[1][1],reconciliationId: payRes[1][2],name: payRes[1][3],claimStatus: payRes[1][4]});
        // }

        // var res = JSON.parse(response.body);

        // if (res[0][0] > 1) {
        //     this.setState({ isSingalApplicant: false, name: res[0][1], applicantID: res[0][2], fundingSource: res[0][3], claimStatus: res[0][4] });
        // }
        // else {
        //     this.setState({ isSingalApplicant: true, name: res[0][1], applicantID: res[0][2], fundingSource: res[0][3], claimStatus: res[0][4] });
        // }

        

        this.setState({ isLoading: false });
    }

    async componentDidUpdate() {
        if (this.props.authState === "signIn" && this.state.isGoolgeTranslateLoadedForSignIn === false) {
            this.setState({ isGoolgeTranslateLoadedForSignIn: true, isGoolgeTranslateLoadedForSignedIn: false });

            var addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = this.googleTranslateElementInit;
        }
        else if (this.props.authState === "signedIn" && this.state.isGoolgeTranslateLoadedForSignedIn === false) {
            this.setState({ isGoolgeTranslateLoadedForSignedIn: true, isGoolgeTranslateLoadedForSignIn: false });

            var addScript = document.createElement('script');
            addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
            document.body.appendChild(addScript);
            window.googleTranslateElementInit = this.googleTranslateElementInit;
        }

        if (this.props.authState === "signedIn") {
            if (this.state.isUserLoaded === false) {
                this.setState({ isLoading: true, isUserLoaded: true });

                var user = await Auth.currentAuthenticatedUser();
                this.setState({ applicantEmail: user.attributes.email });

                var payRes = await this.getData();

                if (payRes[0] == 0) {
                    document.getElementById("managePayment").disabled = true;
                } else if (payRes[0] > 1) {
                    this.setState({ isSingalPayment: false,name: payRes[1][3],claimStatus: payRes[1][4]});
                }
                else {
                    this.setState({ isSingalPayment: true,individualIDFundingSourceTransactionId: payRes[1][0],paymentId: payRes[1][1],reconciliationId: payRes[1][2],name: payRes[1][3],claimStatus: payRes[1][4]});
                    // window.location.assign("/irvineclaim/landloarddetails/" + payRes[0][1] +"/S");
                }    
                // var res = await this.getData();

                // if (res[0][0] > 1) {
                //     this.setState({ isSingalApplicant: false, name: res[0][1], applicantID: res[0][2], fundingSource: res[0][3], claimStatus: res[0][4] });
                // }
                // else {
                //     this.setState({ isSingalApplicant: true, name: res[0][1], applicantID: res[0][2], fundingSource: res[0][3], claimStatus: res[0][4] });
                // }
                
                this.setState({ isLoading: false });
            }
        }
    }

    getData = async (e) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/checksantaanaapplicantpayment';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    email: this.state.applicantEmail,
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
         // return;
        }
    }

    googleTranslateElementInit() {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            // this.props.loggedIn = false;
        }
        catch (e) {
            console.log(e)
        }
    }

    viewApplication = () => {
        //  window.location.assign("/irvineclaim/edit/" + this.state.applicantID + "");

    }

    manageDocuments = () => {
        // console.log("click");
        if (this.state.isSingalPayment == true) {
             window.location.assign("/santaanaclaim/applicantdetails/" + this.state.individualIDFundingSourceTransactionId +  "/S");
        }
        else {
            window.location.assign("/santaanaclaim/applicantpayment");
        }
    }

    render() {
        if (this.props.authState === "signedIn") {
            document.body.classList.remove('login');
            return (
                <div >
                    {/* new design starts */}

                    <div>
                        {/* header starts */}
                        <header>
                            <nav className="navbar navbar-light navbar-inner  py-1 py-sm-3" id="headerBg">
                                <a className="navbar-brand" href="#">
                                    <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                                </a>
                                <div className="ml-auto">
                                    <div className="landing-gtraslate"><GoogleTranslate /></div>
                                    <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                                </div>
                            </nav>
                        </header>
                        {/* header ends */}

                        {/* main content starts */}
                        <main className="main">
                            <div className="container-fluid">
                                <div className="card-shadow mb-5 mb-md-0">

                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-md-6 first text-center">
                                                <div className="welcome-box">
                                                    <p className="text-welcome">Welcome!</p>
                                                    <h2 className="text-primary title mb-0">City of Santa Ana, Emergency Rental Assistance</h2>
                                                    <hr />
                                                    <div>
                                                        <div class="row">
                                                            <label class="col-6 text-right col-form-label">Applicant Name:</label>
                                                            <div class="col-6 text-left">
                                                                <label className="col-form-label">{this.state.name}</label>
                                                            </div>
                                                        </div>
                                                        {/* <div class="row">
                                                            <label class="col-6 text-right col-form-label">Applicant ID:</label>
                                                            <div class="col-6 text-left">
                                                                <label className="col-form-label">{this.state.applicantID}</label>
                                                            </div>
                                                        </div> */}
                                                        {/* <div class="row">
                                                            <label class="col-6 text-right col-form-label">Date Applied:</label>
                                                            <div class="col-6 text-left">
                                                                <label className="col-form-label">{this.state.dateApplied_Formatted}</label>
                                                            </div>
                                                        </div> */}
                                                        <div class="row">
                                                            <label class="col-6 text-right col-form-label">Applicant Email:</label>
                                                            <div class="col-6 text-left">
                                                                <label className="col-form-label">
                                                                    <span>{this.state.applicantEmail} &nbsp;</span>
                                                                    {/* <span onClick={(e) => { e.preventDefault(); this.refreshStatus() }}>
                                                                        <Tooltip title="Refresh" arrow>
                                                                        <IconButton class="fas fa-sync-alt border-0"></IconButton>
                                                                        </Tooltip>
                                                                    </span> */}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    </div>
                                                    {/* <button className="btn btn-secondary mt-3 mr-3" onClick={(e) => { e.preventDefault(); this.viewApplication() }}>View Application <i className="fas fa-paper-plane ml-2"></i></button> */}
                                                    <button className="btn btn-primary mt-3" id="managePayment"  onClick={(e) => { e.preventDefault(); this.manageDocuments() }}>Manage Information <i className="fas fa-file ml-2"></i></button>
                                                    <hr />
                                                    <p className="text-primary mb-0">Powered by Orange County United Way</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 order-md-first">
                                                <div className="hero-image" style={{ backgroundImage: "url(" + imgHomeless + ")" }}>
                                                    <img src={imgHomeless} className="d-block d-md-none img-fluid w-100" alt="homeless person image" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>

                                        <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                                            <CircularProgress />
                                        </Backdrop>
                                    </React.Fragment>

                                </div>
                            </div>
                        </main>
                        {/* main content ends */}

                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default App;
