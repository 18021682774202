import React from "react";
import { Auth, API } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import { Storage } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import imgLogo from './imgs/logo-assist-oc.png';
import GoogleTranslate from './google';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class ApplicantPaymentDetails extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            //shouldBlockNavigation: false,

            email: "",
            individualID: "",
            fundingSource: "",
            applicantName: "",
            dateApplied_Formatted: "",
            claimStatus: null,
            applicantDocUploadStatus: "Pending",
            individualCase: 'Open',
            //new
            landloardEmail: "",
            address1: "",
            address2: "",
            dateApplied: "",
            requestDate: "",
            landlordName: "",
            landlordEmail: "",
            landlordPhone: "",
            //
            //Payment state started
            isSingalPayment: false,

            payeename: "",
            bankName: "",
            routingNumber: "",
            bankAccountType: "",
            bankAccountNumber: "",

            addresstobeUsedforMailing: "",
            payeenameCheck: "",

            updatedpayeename: "",
            updatedbankName: "",
            updatedroutingNumber: "",
            updatedbankAccountType: "",
            updatedbankAccountNumber: "",

            updatedpayeenameforCheck: "",
            updatedaddresstobeUsedforMailing: "",

            isPreviouslySelectedCheck: "",
            isReuseBankingDetails: "true",
            isShowReuseBankingDetailsRadio: false,
            transactionMode: "Check",
            isDisableCheckBankDetails: false,

            santaAnaTransactionId: "",
            reconciliationId: 0,

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            informationStatus: '',
            validationErrors: {},
            isLockPaymentSection: false,
            issuedDateTime: '',

            wantToReuseDocuments: "true",
            isDocumentUploaded: "true",
            staticDoc: "",

            photo_identification: "",
            tenant_payment_agreement: "",

            photo_identification_uploaded: false,
            tenant_payment_agreement_uploaded: false,

            msg: "The documents are successfully submitted to City of Santa Ana, Emergency Rental Assistance program Administrator. In case any further information is required, the administrator will get in touch with you via email. You can now sign out of this application.",
            open: false,
        }
        this.photo_identification_upload = null;
        this.tenant_payment_agreement_upload = null;

    }

    async componentDidMount() {
        window.scrollTo(0, 0); // scroll to the top of the window

        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;

        this.setState({ isLoading: true });

        var user = await this.getCurrentUser();
        //  this.setState({ email: user.attributes.email });

        if (this.props.match.params.mode == "S") {
            this.setState({ isSingalApplicant: true });
        }
        else {
            this.setState({ isSingalApplicant: false });
        }

        // this.setState({ email: user.attributes.email, individualID: this.props.match.params.id, fundingSource: this.props.match.params.fundingSource });
        this.setState({ email: user.attributes.email, santaAnaTransactionId: this.props.match.params.id });
        var res = await this.getApplicantInfo();
        //   var res = await this.getLandloardInfo();
        // console.log(res);
        this.setState({

            applicantName: res[0][0],
            address1: res[0][1],
            address2: res[0][2],
            requestDate: res[0][3],
            landlordName: res[0][4],
            landlordEmail: res[0][5],
            landlordPhone: res[0][6],
            email: res[0][7],
            individualCase: res[0][8],
            addresstobeUsedforMailing: res[0][10],
            santaAnaTransactionId: res[0][11],
            reconciliationId: res[0][12],
            informationStatus: res[0][13],
            payeenameCheck: res[0][14],
            issuedDateTime: res[0][15],
            individualID: res[0][17],
            fundingSource: res[0][18],
            isDocumentUploaded: res[0][19] === 1 ? "true" : "false",
            wantToReuseDocuments: res[0][19] === 1 ? "false" : "true",

        });

        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var template = await Storage.get(`StaticDocs/TenantPaymentAgreement/TenantPaymentAgreement.pdf`);
            this.setState({ staticDoc: template });
        }
        catch (e) {
            console.log(e);
        }
        res = await this.loadUploadedDocuments();
        //console.log(res);

        this.setState({ isLoading: false });
    }

    getApplicantInfo = async (e) => {
        //console.log(this.state.email);

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        //console.log(apiName);
        let path = '/getsantaanaapplicantpaymentdetailsbyemail';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    individualID: this.state.individualID,
                    fundingSource: this.state.fundingSource,
                    santaAnaTransactionId: this.state.santaAnaTransactionId,
                    email: this.state.email
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
            // return;
        }
    }

    handleBack = () => {
        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

        var blockNavigation = false;
        // Check if there are any unsaved changes.
        if ((this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) ||
            (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0)) //||
        {
            blockNavigation = true;
        }

        if (blockNavigation === true) {
            var result = window.confirm("You have unsaved changes, are you sure you want to leave?");
            if (result === false) {
                return;
            }
        }

        this.setState({ isLoading: false });
        window.location.assign("/");
    }

    validateUploadFileSizes = () => {
        var validationError = false;

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null) {
            // early return
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // Validate the file size which is being uploaded
        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            if (this.photo_identification_upload.files[0].size > maxAllowedSize) {
                document.getElementById("photo_identification_size_validation_id").style.display = "block";
                this.photo_identification_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("photo_identification_size_validation_id").style.display = "none";
            }
        }

        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            if (this.tenant_payment_agreement_upload.files[0].size > maxAllowedSize) {
                document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "block";
                this.tenant_payment_agreement_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "none";
            }
        }

        // Reset elements
        this.photo_identification_upload = null;
        this.tenant_payment_agreement_upload = null;

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            return false;
        }

        return true;
    }

    UploadIndividualDocuments = async () => {
        var photoIdfolderName = "";
       var tenantPayamentAgreementfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_tenant_payment_agreement`;

        if (this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false") {
            photoIdfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_photo_identification`;
        }
        else {
            photoIdfolderName = "photo_identification";
        }

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null) {
            // early return
            return;
        }

        if (this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment' || this.state.informationStatus === "Issued") {
            // early return. don't allow to upload the documents if individual case is set to other than open.
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/${photoIdfolderName}/${this.photo_identification_upload.files[0].name}`,
                    this.photo_identification_upload.files[0],
                    { contentType: this.photo_identification_upload.files[0].type });
                //console.log(result);
                this.photo_identification_upload = null;

                this.setState({ photo_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            try {
                result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/${tenantPayamentAgreementfolderName}/${this.tenant_payment_agreement_upload.files[0].name}`,
                    this.tenant_payment_agreement_upload.files[0],
                    { contentType: this.tenant_payment_agreement_upload.files[0].type });
                //console.log(result);
                this.tenant_payment_agreement_upload = null;

                this.setState({ tenant_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        // Reset elements
        this.photo_identification_upload = null;
        this.tenant_payment_agreement_upload = null;
    }

    updateDocumentUploadStatus = async (docUploadStatus) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/santaanaapplicantupdatedocuploadstatus';

        let dateApplicantDocumentSubmitted = null;
        let timeApplicantDocumentSubmitted = null;

        if (docUploadStatus === 'Ready') {
            var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
            var todaysDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2)
            ].join('-');

            var todaysTime = [
                ('0' + (d.getHours())).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2),
            ].join(':');

            dateApplicantDocumentSubmitted = todaysDate;
            timeApplicantDocumentSubmitted = todaysTime;
        }

        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "fundingSource": this.state.fundingSource,
                    "applicantDocUploadStatus": docUploadStatus,
                    "dateApplicantDocumentSubmitted": dateApplicantDocumentSubmitted,
                    "timeApplicantDocumentSubmitted": timeApplicantDocumentSubmitted,
                    "isDocumentUploaded": this.state.isDocumentUploaded,
                    "reconciliationId": this.state.reconciliationId,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    fileUploadOnChange = async (e, fileChanged) => {
        this.setState({ isLoading: true });

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        //console.log(e, fileChanged);

        switch (fileChanged) {
            case 1:
                {
                    this.photo_identification_upload = document.getElementById("photo_identification_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
                        if (this.photo_identification_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("photo_identification_size_validation_id").style.display = "block";
                            this.photo_identification_upload = null;
                        }
                        else {
                            document.getElementById("photo_identification_size_validation_id").style.display = "none";

                            var res = await this.UploadIndividualDocuments();
                            res = await this.loadUploadedDocuments();
                            await this.checkAllDocumentsUpload();
                        }
                    }
                }
                break;
            case 2:
                {
                    this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
                        if (this.tenant_payment_agreement_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "block";
                            this.tenant_payment_agreement_upload = null;
                        }
                        else {
                            document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "none";

                            var res = await this.UploadIndividualDocuments();
                            res = await this.loadUploadedDocuments();
                            await this.checkAllDocumentsUpload();
                        }
                    }
                }
                break;
            default:
                break;
        }

        this.setState({ isLoading: false });
    }

    checkAllDocumentsUpload = async () => {
        if (this.state.tenant_payment_agreement_uploaded === true) {
            this.setState({ openSnackbar: true, snackbarMessage: 'Attached documents are uploaded successfully.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }

        if (this.state.photo_identification_uploaded === true) {
            if (this.state.wantToReuseDocuments === "false") {
                this.setState({ isDocumentUploaded: "true" });
            }
            else {
                this.setState({ isDocumentUploaded: "false" });
            }
        }
        else {
            this.setState({ isDocumentUploaded: "false" });
        }

        await this.updateDocumentUploadStatus(this.state.tenant_payment_agreement_uploaded === true? "Ready" : "Info Requested");
    }

    lockDocuments = async () => {
        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

        if (this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment' || this.state.informationStatus === "Issued") {
            if (this.photo_identification_upload !== null) {
                this.photo_identification_upload.disabled = true;
            }
            if (this.tenant_payment_agreement_upload !== null) {
                this.tenant_payment_agreement_upload.disabled = true;
            }
        }

        if (this.state.wantToReuseDocuments === "true") {
            if (this.photo_identification_upload !== null) {
                this.photo_identification_upload.disabled = true;
            }
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        var photoIdfolderName = "";
        var tenantPayamentAgreementfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_tenant_payment_agreement/`;

        if (this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false") {
            photoIdfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_photo_identification/`;
        }
        else {
            photoIdfolderName = "/photo_identification/";
        }

        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;

        // Lock documents if individual case is set to other than open
        this.lockDocuments();

        var fileUploadTag = document.getElementById("photo_identification_upload_id");
        if (fileUploadTag !== null && fileUploadTag !== undefined) {
            if (fileUploadTag.addEventListener) {
                fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 1) });
            }
            else if (fileUploadTag.attachEvent) {
                fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 1) });
            }
        }
        fileUploadTag = document.getElementById("tenant_payment_agreement_upload_id");
        if (fileUploadTag !== null && fileUploadTag !== undefined) {
            if (fileUploadTag.addEventListener) {
                fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 2) });
            }
            else if (fileUploadTag.attachEvent) {
                fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 2) });
            }
        }

        // Reset all the flags
        this.setState({
            photo_identification_uploaded: false,
            tenant_payment_agreement_uploaded: false,
        });

        //get file names for s3 keys
        try {
            var key = (this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false") ?
                `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliationId}/` :
                `SantaAna/Case/Case_${this.state.individualID}/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes(photoIdfolderName)) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ photo_identification: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode === "User") {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag1 = document.getElementById('deleteS3File1');
                        if (aTag1 !== null && aTag1 !== undefined) {
                            if ((this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment') ||
                                this.state.informationStatus === "Issued" ||
                                this.state.wantToReuseDocuments === "true") {
                                aTag1.style.visibility = "hidden";
                            }
                            else {
                                if (aTag1.addEventListener) {
                                    aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                                }
                                else if (aTag1.attachEvent) {
                                    aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                                }
                            }
                        }
                    }
                    this.setState({ photo_identification_uploaded: true });
                }
                catch (err) {
                    console.log("Error photo_identification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 1;
                }
            }
        }

        //get file names for s3 keys
        try {
            var key = `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliationId}/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes(tenantPayamentAgreementfolderName)) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tenant_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode === "User") {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File2');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if ((this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment') ||
                                this.state.informationStatus === "Issued") {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(2) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(2) });
                                }
                            }
                        }
                    }
                    var aTagDownload = document.getElementById('downloadForm');
                    if (aTagDownload !== null && aTagDownload !== undefined) {

                        if (aTagDownload.addEventListener) {
                            aTagDownload.addEventListener("click", () => { this.downloadForm() });
                        }
                        else if (aTagDownload.attachEvent) {
                            aTagDownload.attachEvent("click", () => { this.downloadForm() });
                        }

                    }
                    this.setState({ tenant_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error tenant_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm" >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 2;
                }
            }
        }

        // Set the document upload flag to Pending in case if any mandatory document is missing.
        if (this.state.tenant_payment_agreement_uploaded === false) {
            if (this.state.applicantDocUploadStatus !== "Info Requested") {
                var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //console.log(uploadRes);

                var res = await this.getApplicantInfo();
                this.setState({
                    applicantName: res[0][0],
                    address1: res[0][1],
                    address2: res[0][2],
                    requestDate: res[0][3],
                    landlordName: res[0][4],
                    landlordEmail: res[0][5],
                    landlordPhone: res[0][6],
                    email: res[0][7],
                    individualCase: res[0][8],
                    //ST.transactionType as Paymentmode = res[0][9],
                    addresstobeUsedforMailing: res[0][10],
                    santaAnaTransactionId: res[0][11],
                    reconciliationId: res[0][12],
                    informationStatus: res[0][13],
                    payeenameCheck: res[0][14],
                    issuedDateTime: res[0][15],
                    individualID: res[0][17],
                    fundingSource: res[0][18],
                    // isDocumentUploaded: res[0][19] === 1 ? "true" : "false",
                    // wantToReuseDocuments: res[0][19] === 1 ? "false" : "true",
                });
            }
        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        var photoIdfolderName = "";
        var tenantPayamentAgreementfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_tenant_payment_agreement/`;

        if (this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false") {
            photoIdfolderName = `AdditionalPayment/${this.state.reconciliationId}/rec_photo_identification/`;
        }
        else {
            photoIdfolderName = "/photo_identification/";
        }

        this.setState({ isLoading: true });
        try {
            // Remove the earlier files for the section for which the new files to be uploaded
            switch (fileToDelete) {
                case 1:
                    {
                        var key = (this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false") ?
                            `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliationId}/` :
                            `SantaAna/Case/Case_${this.state.individualID}/`;
                        var files = await Storage.list(key);
                        //console.log(files);

                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes(photoIdfolderName)) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ photo_identification: "" });
                        var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                    }
                    break;
                case 2:
                    {
                        var key = `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliationId}/`;
                        var files = await Storage.list(key);
                        //console.log(files);

                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes(tenantPayamentAgreementfolderName)) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm" >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ tenant_payment_agreement: "" });
                        var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                    }
                    break;
                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        var safeload = await this.loadUploadedDocuments();
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    downloadForm = async (e) => {
        e.preventDefault();
        // e.prevent
        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var template = await Storage.get(`StaticDocs/TenantPaymentAgreement/TenantPaymentAgreement.pdf`);
            //console.log(template);

            let link = document.createElement('a');
            link.href = template;
            link.target = '_blank';
            link.download = template;
            link.click();
        }
        catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    }

    OnUpdateReuseDocuments(event) {
        if (event.target.value === "true") {
            this.setState({ wantToReuseDocuments: "true" }, () => this.loadUploadedDocuments());
        }
        else {
            this.setState({ wantToReuseDocuments: "false" }, async () => { await this.loadUploadedDocuments(); alert("Please upload only those documents which are new compared to previously uploaded document."); });
        }
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    //console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    handleClose() {
        this.setState({ open: false });
        if (this.state.isSingalApplicant == true) {
            window.location.assign(`/`);
        }
        else {
            window.location.assign(`/santaanaclaim/applicantpayment`);
        }
    }

    googleTranslateElementInit() {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }

    closeSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    render() {
        return (
            <div className="d-flex flex-column sticky-footer-wrapper">
                {/* header starts */}
                <header>
                    <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                        <a className="navbar-brand" href="#">
                            <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                        </a>
                        <div className="ml-auto d-flex">
                            <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                <div className="landing-gtraslate"><GoogleTranslate /></div>
                                <button className="btn btn-light d-inline-block mr-3 ml-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                                <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                            </div>
                        </div>
                    </nav>
                </header>
                {/* header ends */}

                <main className="main flex-fill">
                    <div className="container-fluid">
                        <div className="box-shadow p-3 mb-3">
                            <h4 className="text-primary">City of Santa Ana, Emergency Rental Assistance</h4>
                            <hr />
                            <div className="wh-note">
                                <span>INSTRUCTIONS:</span>
                                <div className="note-list">
                                    <ol>
                                        <li>Review contact information.  If anything needs to be updated, please email ERASAConnect@UnitedWayOC.org.  Include your case number, tenant name and address in your email.</li>
                                        <li>Upload all required documents</li>
                                        <ul>
                                            <li>Please attach a file to be uploaded for all required documents by clicking on 'Choose File' button.</li>
                                            <li>Only 1 file allowed to upload per row.</li>
                                            <li>Size of file to be uploaded should be less or equal to 5 MB.</li>
                                            <li>All the documents with (*) sign are required to be uploaded in order to proceed with application under City of Santa Ana, Emergency Rental Assistance program.</li>
                                        </ul>
                                    </ol>
                                </div>
                                {/* <span>Note:r</span>
                                <div className="note-list">
                                    <ul>
                                        <li>After selecting the documents to be uploaded, please click on 'Save' button to upload.</li>
                                        <li>Only 1 file allowed to upload per Document Title.</li>
                                        <li>Size of file to be uploaded should be less or equal to 5 MB.</li>
                                        <li>All the documents with (*) sign are required to be uploaded in order to proceed with application under City of Santa Ana, Emergency Rental Assistance program.</li>
                                        {this.state.individualCase === 'Open'? 
                                            (
                                                <React.Fragment></React.Fragment>
                                            )
                                            :
                                            (
                                                <li>Uploaded documents cannot be changed while the application is under review or once a determination has been made.</li>
                                            )
                                        }
                                    </ul>
                                </div> */}
                            </div>
                            <hr />
                            <h2 className="profile-title d-inline-block"><i class="fas fa-user-circle mr-2"></i>Applicant</h2>
                            <hr />
                            {/* <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Applicant Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Date Applied</label>
                                    <input type="text" className="form-control" disabled value={this.state.dateApplied_Formatted} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Applicant ID</label>
                                    <input type="text" className="form-control" disabled value={this.state.individualID} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Funding Source</label>
                                    <input type="text" className="form-control" disabled value={this.state.fundingSource} />
                                </div>
                            </div> */}
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Applicant Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address1</label>
                                    <input type="text" className="form-control" disabled value={this.state.address1} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address2</label>
                                    <input type="text" className="form-control" disabled value={this.state.address2} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Landlord Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Email</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordEmail} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Mobile</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordPhone} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Request Date</label>
                                    <input type="text" className="form-control" disabled value={this.state.requestDate} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Applicant ID</label>
                                    <input type="text" className="form-control" disabled value={this.state.individualID} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Funding Source </label>
                                    <input type="text" className="form-control" disabled value={this.state.fundingSource} />
                                </div>
                                {this.state.informationStatus === "Issued" ? (
                                    <div className="col-md-4 form-group">
                                        <label>Issued TimeStamp </label>
                                        <input type="text" className="form-control" disabled value={this.state.issuedDateTime} />
                                    </div>
                                ) : null}
                            </div>

                            {/* Start */}
                            <hr />
                            <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                            <div className="table-responsive">
                                <table class="table table-bordered table-cgreen mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="35%">Required Document</th>
                                            <th scope="col" width="25%">File Name</th>
                                            <th scope="col" width="25%">Uploaded Date Time</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="tenant_payment_agreement_id">
                                            <td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href={this.state.staticDoc} class="sample-pdf" id="downloadForm" onClick={this.downloadForm} >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" />
                                                <div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="box-shadow p-3 mb-3">
                                <div className="col-auto">
                                    <label>For the remaining documents, do you want to re-use the previously submitted documents? To view current documents/files, click the ‘Eye’ icon in ACTION column.</label>
                                </div>
                                <div className="col-auto pha-radio"
                                    disabled={((this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment') || this.state.informationStatus === "Issued") ? true : false}>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio5" name="wantToReuseDocuments" class="custom-control-input"
                                            checked={this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "true"}
                                            onChange={this.OnUpdateReuseDocuments.bind(this)}
                                            disabled={((this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment') || this.state.informationStatus === "Issued") ? true : false}
                                            value="true" />
                                        <label class="custom-control-label" for="customRadio5">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" id="customRadio6" name="wantToReuseDocuments" class="custom-control-input"
                                            checked={this.state.wantToReuseDocuments && this.state.wantToReuseDocuments === "false"}
                                            onChange={this.OnUpdateReuseDocuments.bind(this)}
                                            disabled={((this.state.individualCase !== 'Open' && this.state.individualCase !== 'Additional Payment') || this.state.informationStatus === "Issued") ? true : false}
                                            value="false" />
                                        <label class="custom-control-label" for="customRadio6">No</label>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table class="table table-bordered table-cgreen mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col" width="35%">Document</th>
                                            <th scope="col" width="25%">File Name</th>
                                            <th scope="col" width="25%">Uploaded Date Time</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="photo_identification_id">
                                            <td>Photo Identification <div id="photo_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" />
                                                <div id="photo_identification_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            {/* End */}

                            <React.Fragment>
                                {/* new */}
                                <h2 className="profile-title d-inline-block"><i class="fas fa-user-circle mr-2"></i>Instructions Related to Payment</h2>
                                <hr />
                                <div className="box-shadow p-3 mb-3">
                                    <div className="row">
                                        <div className="col-auto">
                                            {/* <label>Instructions Related to Payment</label> */}
                                            <lable>RENTAL PAYMENT, CHECK PICK-UP INSTRUCTIONS</lable> <br />
                                            <lable>Rental assistance made directly to the Tenant is paid by check.</lable>
                                            <ol>
                                                {/* <ul> <li>RENTAL PAYMENT, CHECK PICK-UP INSTRUCTIONS </li></ul>
                                                <ul><li>Rental assistance made directly to the Tenant is paid by check.</li></ul> */}
                                                <li>When your check is ready, a program administrator will contact you by email to schedule a date and time for pick-up.</li>
                                                <li>Pick up is from Orange County United Way, Irvine CA 92614</li>
                                                <li>Only the tenant on this application, whose name is printed on the check, can pick up. Checks will NOT be released to anyone other than the payee listed on the check.</li>
                                                <li>Tenant is required to show valid photo identification at time of check pick-up.  Checks will NOT be released to tenant without valid proof of identification</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="text-right mt-3 mt-sm-0 mb-3">
                                    <button className="btn btn-primary ml-3 mb-3 mb-sm-0"  onClick={this.verifyBankDetails}>  SUBMIT</button>
                                </div> */}

                                {/* <div className="assistoc-speeddial">
                                    <Tooltip title="Save" arrow>
                                        <Button type="button" onClick={this.uploadDocuments} className="btn btn-primary whoc-float-btn">
                                            <i className="fas fa-save"></i>
                                        </Button>
                                    </Tooltip>
                                </div> */}

                                <Backdrop open={this.state.isLoading}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar
                                    anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    autoHideDuration={3000}
                                    open={this.state.openSnackbar}
                                    onClose={() => this.setState({ openSnackbar: false })}
                                    message={this.state.snackbarMessage}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                    className={this.state.snackbarMessageClass}
                                // action={
                                //     <React.Fragment>
                                //         <IconButton aria-label="close" color="inherit" onClick={this.closeSnackbar.bind()}>
                                //             <CloseIcon />
                                //         </IconButton>
                                //     </React.Fragment>
                                // }
                                />
                                <Dialog open={this.state.open} className="thankyoudialog" onClose={() => this.handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title">{"Information Submitted!"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.msg}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.handleClose()} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        </div>
                    </div>
                </main>
                {/* main content ends */}

                {/* footer starts */}
                <footer className="foot bg-primary text-white text-center p-2">
                    <p className="mb-1">Powered by Orange County United Way</p>
                </footer>
                {/* footer ends */}
            </div>
        );
    }
}

export default ApplicantPaymentDetails;