import React from "react";
import { Auth, API } from 'aws-amplify';
import Backdrop from '@material-ui/core/Backdrop';
import { Storage } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import imgLogo from './imgs/logo-assist-oc.png';
import GoogleTranslate from './google';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class ManageDocuments extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            //shouldBlockNavigation: false,
            staticDoc: "",
            email: "",
            individualID: "",
            fundingSource: "",
            applicantName: "",
            dateApplied_Formatted: "",
            claimStatus: null,
            applicantDocUploadStatus: "Pending",
            individualCase: 'Open',
            //new
            landloardEmail: "",
            address1: "",
            address2: "",
            dateApplied: "",
            requestDate: "",
            landlordName: "",
            landlordEmail: "",
            landlordPhone: "",
            //
            //Payment state started
            isSingalPayment: false,

            payeename: "",
            bankName: "",
            routingNumber: "",
            bankAccountType: "",
            bankAccountNumber: "",

            addresstobeUsedforMailing: "",
            payeenameCheck: "",

            updatedpayeename: "",
            updatedbankName: "",
            updatedroutingNumber: "",
            updatedbankAccountType: "",
            updatedbankAccountNumber: "",

            updatedpayeenameforCheck: "",
            updatedaddresstobeUsedforMailing: "",

            isPreviouslySelectedCheck: "",
            showCheck: "none",
            isReuseBankingDetails: "true",
            isShowReuseBankingDetailsRadio: false,
            transactionMode: "Check",
            isDisableCheckBankDetails: false,
            isDisableCheckBankDetails: false,

            santaAnaTransactionId: "",
            paymentId: 0,
            reconciliationId: "",

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            msg: "The documents are successfully submitted to City of Santa Ana, Emergency Rental Assistance program Administrator. In case any further information is required, the administrator will get in touch with you via email. You can now sign out of this application.",
            open: false,

            informationStatus: '',
            validationErrors: {},
            isLockPaymentSection: false,

            photo_identification: "",
            secondary_identification: "",
            // secondary_proof_of_residential_tenancy: "",
            proof_of_income: "",
            proof_of_income_loss: "",
            lease_agreement: "",
            // supplemental_proofof_Annual_2020_household_income: "",
            // participation_agreement: "",
            tenant_payment_agreement: "",
            // supplemental_proofof_unemployment: "",
            // authorization_to_release_information_to_SSA: "",
            // consent_to_exchange_information: "",
            // authorization_to_release_information_to_HUD: "",

            // Documents Uploaded
            photo_identification_uploaded: false,
            secondary_identification_uploaded: false,
            // secondary_proof_of_residential_tenancy_uploaded: false,
            proof_of_income_uploaded: false,
            proof_of_income_loss_uploaded: false,
            lease_agreement_uploaded: false,
            // supplemental_proofof_Annual_2020_household_income_uploaded: false,
            // participation_agreement_uploaded: false,
            tenant_payment_agreement_uploaded: false,
            // supplemental_proofof_unemployment_uploaded: false,
            // authorization_to_release_information_to_SSA_uploaded: false,
            // consent_to_exchange_information_uploaded: false,
            // authorization_to_release_information_to_HUD_uploaded: false,
        }

        this.photo_identification_upload = null;
        this.secondary_identification_upload = null;
        // this.secondary_proof_of_residential_tenancy_upload = null;
        this.proof_of_income_upload = null;
        this.proof_of_income_loss_upload = null;
        this.lease_agreement_upload = null;
        // this.supplemental_proofof_Annual_2020_household_income_upload = null;
        // this.participation_agreement_upload = null;
        this.tenant_payment_agreement_upload = null;
        // this.supplemental_proofof_unemployment_upload = null;
        // this.authorization_to_release_information_to_SSA_upload = null;
        // this.consent_to_exchange_information_upload = null;
        // this.authorization_to_release_information_to_HUD_upload = null;
    }

    async componentDidMount() {
        window.scrollTo(0, 0); // scroll to the top of the window

        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;

        this.setState({ isLoading: true });

        var user = await this.getCurrentUser();
        //  this.setState({ email: user.attributes.email });

        if (this.props.match.params.mode == "S") {
            this.setState({ isSingalApplicant: true });
        }
        else {
            this.setState({ isSingalApplicant: false });
        }

        // this.setState({ email: user.attributes.email, individualID: this.props.match.params.id, fundingSource: this.props.match.params.fundingSource });
        this.setState({ email: user.attributes.email, individualID: this.props.match.params.id, fundingSource: this.props.match.params.fundingSource, santaAnaTransactionId: this.props.match.params.transId });
        var res = await this.getApplicantInfo();
        //   var res = await this.getLandloardInfo();
        // console.log(res);

        this.setState({
            applicantName: res[0][0],
            address1: res[0][1],
            address2: res[0][2],
            requestDate: res[0][3],
            landlordName: res[0][4],
            landlordEmail: res[0][5],
            landlordPhone: res[0][6],
            email: res[0][7],
            individualCase: res[0][8],
            //ST.transactionType as Paymentmode = res[0][9],
            addresstobeUsedforMailing: res[0][10],
            payeenameCheck: res[0][14],

            santaAnaTransactionId: res[0][11],
            paymentId: res[0][12],

            informationStatus: res[0][13],
            applicantDocUploadStatus: res[0][15],
            // individualCase: res[0][20],
            //reconciliationId: res[18],
        });

        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var template = await Storage.get(`StaticDocs/TenantPaymentAgreement/TenantPaymentAgreement.pdf`);
            this.setState({ staticDoc: template });
        }
        catch (e) {
            console.log(e);
        }
        res = await this.loadUploadedDocuments();
        //console.log(res);

        this.setState({ isLoading: false });
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
            // return;
        }
    }

    getApplicantInfo = async (e) => {
        //console.log(this.state.email);

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        //console.log(apiName);
        let path = '/getsantaanaapplicantdetailsbyid';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
                "info": {
                    individualID: this.state.individualID,
                    fundingSource: this.state.fundingSource,
                    santaAnaTransactionId: this.state.santaAnaTransactionId,
                    email: this.state.email
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    handleBack = () => {
        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        //  this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
        // this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
        //  this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
        //  this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
        //  this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
        // this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
        // this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
        // this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
        // this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
        // this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
        // this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");

        var blockNavigation = false;
        // Check if there are any unsaved changes.
        if ((this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) ||
            //  (this.secondary_identification_upload !== null && this.secondary_identification_upload.files != null && this.secondary_identification_upload.files.length > 0) ||
            // (this.secondary_proof_of_residential_tenancy_upload !== null && this.secondary_proof_of_residential_tenancy_upload.files != null && this.secondary_proof_of_residential_tenancy_upload.files.length > 0) ||
            //  (this.proof_of_income_upload !== null && this.proof_of_income_upload.files != null && this.proof_of_income_upload.files.length > 0) ||
            //  (this.proof_of_income_loss_upload !== null && this.proof_of_income_loss_upload.files != null && this.proof_of_income_loss_upload.files.length > 0) ||
            //  (this.lease_agreement_upload !== null && this.lease_agreement_upload.files != null && this.lease_agreement_upload.files.length > 0) ||
            // (this.supplemental_proofof_Annual_2020_household_income_upload !== null && this.supplemental_proofof_Annual_2020_household_income_upload.files != null && this.supplemental_proofof_Annual_2020_household_income_upload.files.length > 0) ||
            // (this.participation_agreement_upload !== null && this.participation_agreement_upload.files != null && this.participation_agreement_upload.files.length > 0) ||
            (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0)) //||
        // (this.supplemental_proofof_unemployment_upload !== null && this.supplemental_proofof_unemployment_upload.files != null && this.supplemental_proofof_unemployment_upload.files.length > 0) ||
        // (this.authorization_to_release_information_to_SSA_upload !== null && this.authorization_to_release_information_to_SSA_upload.files != null && this.authorization_to_release_information_to_SSA_upload.files.length > 0) ||
        // (this.consent_to_exchange_information_upload !== null && this.consent_to_exchange_information_upload.files != null && this.consent_to_exchange_information_upload.files.length > 0) ||
        // (this.authorization_to_release_information_to_HUD_upload !== null && this.authorization_to_release_information_to_HUD_upload.files != null && this.authorization_to_release_information_to_HUD_upload.files.length > 0))
        {

            blockNavigation = true;
        }

        if (blockNavigation === true) {
            var result = window.confirm("You have unsaved changes, are you sure you want to leave?");
            if (result === false) {
                return;
            }
        }

        this.setState({ isLoading: false });
        window.location.assign("/");
    }

    validateUploadFileSizes = () => {
        var validationError = false;

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
            // early return
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        //  this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
        // this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
        //  this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
        //  this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
        //  this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
        // this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
        // this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
        // this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
        // this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
        // this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
        // this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        ///////////////////////////   VALIDATION STARTS   ///////////////////////////

        // Validate the file size which is being uploaded
        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            if (this.photo_identification_upload.files[0].size > maxAllowedSize) {
                document.getElementById("photo_identification_size_validation_id").style.display = "block";
                this.photo_identification_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("photo_identification_size_validation_id").style.display = "none";
            }
        }

        // if (this.secondary_identification_upload !== null && this.secondary_identification_upload.files != null && this.secondary_identification_upload.files.length > 0) {
        //     if (this.secondary_identification_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("secondary_identification_size_validation_id").style.display = "block";
        //         this.secondary_identification_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("secondary_identification_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.secondary_proof_of_residential_tenancy_upload !== null && this.secondary_proof_of_residential_tenancy_upload.files != null && this.secondary_proof_of_residential_tenancy_upload.files.length > 0) {
        //     if (this.secondary_proof_of_residential_tenancy_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("secondary_proof_of_residential_tenancy_size_validation_id").style.display = "block";
        //         this.secondary_proof_of_residential_tenancy_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("secondary_proof_of_residential_tenancy_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.proof_of_income_upload !== null && this.proof_of_income_upload.files != null && this.proof_of_income_upload.files.length > 0) {
        //     if (this.proof_of_income_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("proof_of_income_size_validation_id").style.display = "block";
        //         this.proof_of_income_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("proof_of_income_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.proof_of_income_loss_upload !== null && this.proof_of_income_loss_upload.files != null && this.proof_of_income_loss_upload.files.length > 0) {
        //     if (this.proof_of_income_loss_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("proof_of_income_loss_size_validation_id").style.display = "block";
        //         this.proof_of_income_loss_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("proof_of_income_loss_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.lease_agreement_upload !== null && this.lease_agreement_upload.files != null && this.lease_agreement_upload.files.length > 0) {
        //     if (this.lease_agreement_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("lease_agreement_size_validation_id").style.display = "block";
        //         this.lease_agreement_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("lease_agreement_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.supplemental_proofof_Annual_2020_household_income_upload !== null && this.supplemental_proofof_Annual_2020_household_income_upload.files != null && this.supplemental_proofof_Annual_2020_household_income_upload.files.length > 0) {
        //     if (this.supplemental_proofof_Annual_2020_household_income_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("supplemental_proofof_Annual_2020_household_income_size_validation_id").style.display = "block";
        //         this.supplemental_proofof_Annual_2020_household_income_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("supplemental_proofof_Annual_2020_household_income_size_validation_id").style.display = "none";
        //     }
        // }

        // if (this.participation_agreement_upload !== null && this.participation_agreement_upload.files != null && this.participation_agreement_upload.files.length > 0) {
        //     if (this.participation_agreement_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("participation_agreement_size_validation_id").style.display = "block";
        //         this.participation_agreement_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("participation_agreement_size_validation_id").style.display = "none";
        //     }
        // }

        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            if (this.tenant_payment_agreement_upload.files[0].size > maxAllowedSize) {
                document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "block";
                this.tenant_payment_agreement_upload = null;

                // this.elementRef.current.focus();

                validationError = true;
            }
            else {
                document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "none";
            }
        }
        // if (this.supplemental_proofof_unemployment_upload !== null && this.supplemental_proofof_unemployment_upload.files != null && this.supplemental_proofof_unemployment_upload.files.length > 0) {
        //     if (this.supplemental_proofof_unemployment_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("supplemental_proofof_unemployment_size_validation_id").style.display = "block";
        //         this.supplemental_proofof_unemployment_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("supplemental_proofof_unemployment_size_validation_id").style.display = "none";
        //     }
        // }
        // if (this.authorization_to_release_information_to_SSA_upload !== null && this.authorization_to_release_information_to_SSA_upload.files != null && this.authorization_to_release_information_to_SSA_upload.files.length > 0) {
        //     if (this.authorization_to_release_information_to_SSA_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("authorization_to_release_information_to_SSA_size_validation_id").style.display = "block";
        //         this.authorization_to_release_information_to_SSA_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("authorization_to_release_information_to_SSA_size_validation_id").style.display = "none";
        //     }
        // }
        // if (this.consent_to_exchange_information_upload !== null && this.consent_to_exchange_information_upload.files != null && this.consent_to_exchange_information_upload.files.length > 0) {
        //     if (this.consent_to_exchange_information_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("consent_to_exchange_information_size_validation_id").style.display = "block";
        //         this.consent_to_exchange_information_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("consent_to_exchange_information_size_validation_id").style.display = "none";
        //     }
        // }
        // if (this.authorization_to_release_information_to_HUD_upload !== null && this.authorization_to_release_information_to_HUD_upload.files != null && this.authorization_to_release_information_to_HUD_upload.files.length > 0) {
        //     if (this.authorization_to_release_information_to_HUD_upload.files[0].size > maxAllowedSize) {
        //         document.getElementById("authorization_to_release_information_to_HUD_size_validation_id").style.display = "block";
        //         this.authorization_to_release_information_to_HUD_upload = null;

        //         // this.elementRef.current.focus();

        //         validationError = true;
        //     }
        //     else {
        //         document.getElementById("authorization_to_release_information_to_HUD_size_validation_id").style.display = "none";
        //     }
        // }

        // Reset elements
        this.photo_identification_upload = null;
        //  this.secondary_identification_upload = null;
        // this.secondary_proof_of_residential_tenancy_upload = null;
        // this.proof_of_income_upload = null;
        // this.proof_of_income_loss_upload = null;
        //  this.lease_agreement_upload = null;
        // this.supplemental_proofof_Annual_2020_household_income_upload = null;
        // this.participation_agreement_upload = null;
        this.tenant_payment_agreement_upload = null;
        // this.supplemental_proofof_unemployment_upload = null;
        // this.authorization_to_release_information_to_SSA_upload = null;
        // this.consent_to_exchange_information_upload = null;
        // this.authorization_to_release_information_to_HUD_upload = null;

        ///////////////////////////   VALIDATION ENDS   ///////////////////////////

        if (validationError === true) {
            return false;
        }

        return true;
    }

    UploadIndividualDocuments = async () => {

        if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
            // early return
            return;
        }

        if (this.state.individualCase !== 'Open') {
            // early return. don't allow to upload the documents if individual case is set to other than open.
            return;
        }

        this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
        // this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
        // this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
        // this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
        // this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
        //  this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
        // this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
        // this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
        this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
        // this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
        // this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
        // this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
        // this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");

        // Upload the new documents to be uploaded `ERA-IRVINE/${this.state.email}/`
        if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/photo_identification/${this.photo_identification_upload.files[0].name}`,
                    this.photo_identification_upload.files[0],
                    { contentType: this.photo_identification_upload.files[0].type });
                //console.log(result);
                this.photo_identification_upload = null;

                this.setState({ photo_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        // if (this.secondary_identification_upload !== null && this.secondary_identification_upload.files != null && this.secondary_identification_upload.files.length > 0) {
        //     try {
        //        // result = await Storage.put(`ERA-IRVINE/${this.state.email}/secondary_identification/${this.secondary_identification_upload.files[0].name}`,
        //        result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/proof_of_address/${this.secondary_identification_upload.files[0].name}`,
        //             this.secondary_identification_upload.files[0],
        //             { contentType: this.secondary_identification_upload.files[0].type });
        //         //console.log(result);
        //         this.secondary_identification_upload = null;

        //         this.setState({ secondary_identification_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.secondary_proof_of_residential_tenancy_upload !== null && this.secondary_proof_of_residential_tenancy_upload.files != null && this.secondary_proof_of_residential_tenancy_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/secondary_proof_of_residential_tenancy/${this.secondary_proof_of_residential_tenancy_upload.files[0].name}`,
        //             this.secondary_proof_of_residential_tenancy_upload.files[0],
        //             { contentType: this.secondary_proof_of_residential_tenancy_upload.files[0].type });
        //         //console.log(result);
        //         this.secondary_proof_of_residential_tenancy_upload = null;

        //         this.setState({ secondary_proof_of_residential_tenancy_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.proof_of_income_upload !== null && this.proof_of_income_upload.files != null && this.proof_of_income_upload.files.length > 0) {
        //     try {
        //         // result = await Storage.put(`ERA-IRVINE/${this.state.email}/proof_of_income/${this.proof_of_income_upload.files[0].name}`,
        //         result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/proof_of_income/${this.proof_of_income_upload.files[0].name}`,
        //             this.proof_of_income_upload.files[0],
        //             { contentType: this.proof_of_income_upload.files[0].type });
        //         //console.log(result);
        //         this.proof_of_income_upload = null;

        //         this.setState({ proof_of_income_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.proof_of_income_loss_upload !== null && this.proof_of_income_loss_upload.files != null && this.proof_of_income_loss_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/proof_of_income_loss/${this.proof_of_income_loss_upload.files[0].name}`,
        //             this.proof_of_income_loss_upload.files[0],
        //             { contentType: this.proof_of_income_loss_upload.files[0].type });
        //         //console.log(result);
        //         this.proof_of_income_loss_upload = null;

        //         this.setState({ proof_of_income_loss_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.lease_agreement_upload !== null && this.lease_agreement_upload.files != null && this.lease_agreement_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/lease_agreement/${this.lease_agreement_upload.files[0].name}`,
        //             this.lease_agreement_upload.files[0],
        //             { contentType: this.lease_agreement_upload.files[0].type });
        //         //console.log(result);
        //         this.lease_agreement_upload = null;

        //         this.setState({ lease_agreement_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.supplemental_proofof_Annual_2020_household_income_upload !== null && this.supplemental_proofof_Annual_2020_household_income_upload.files != null && this.supplemental_proofof_Annual_2020_household_income_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/supplemental_proofof_Annual_2020_household_income/${this.supplemental_proofof_Annual_2020_household_income_upload.files[0].name}`,
        //             this.supplemental_proofof_Annual_2020_household_income_upload.files[0],
        //             { contentType: this.supplemental_proofof_Annual_2020_household_income_upload.files[0].type });
        //         //console.log(result);
        //         this.supplemental_proofof_Annual_2020_household_income_upload = null;

        //         this.setState({ supplemental_proofof_Annual_2020_household_income_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // if (this.participation_agreement_upload !== null && this.participation_agreement_upload.files != null && this.participation_agreement_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/participation_agreement/${this.participation_agreement_upload.files[0].name}`,
        //             this.participation_agreement_upload.files[0],
        //             { contentType: this.participation_agreement_upload.files[0].type });
        //         //console.log(result);
        //         this.participation_agreement_upload = null;

        //         this.setState({ participation_agreement_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
            try {
                //  result = await Storage.put(`ERA-IRVINE/${this.state.email}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
                result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
                    this.tenant_payment_agreement_upload.files[0],
                    { contentType: this.tenant_payment_agreement_upload.files[0].type });
                //console.log(result);
                this.tenant_payment_agreement_upload = null;

                this.setState({ tenant_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
        // if (this.supplemental_proofof_unemployment_upload !== null && this.supplemental_proofof_unemployment_upload.files != null && this.supplemental_proofof_unemployment_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/supplemental_proofof_unemployment/${this.supplemental_proofof_unemployment_upload.files[0].name}`,
        //             this.supplemental_proofof_unemployment_upload.files[0],
        //             { contentType: this.supplemental_proofof_unemployment_upload.files[0].type });
        //         //console.log(result);
        //         this.supplemental_proofof_unemployment_upload = null;

        //         this.setState({ supplemental_proofof_unemployment_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }
        // if (this.authorization_to_release_information_to_SSA_upload !== null && this.authorization_to_release_information_to_SSA_upload.files != null && this.authorization_to_release_information_to_SSA_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/authorization_to_release_information_to_SSA/${this.authorization_to_release_information_to_SSA_upload.files[0].name}`,
        //             this.authorization_to_release_information_to_SSA_upload.files[0],
        //             { contentType: this.authorization_to_release_information_to_SSA_upload.files[0].type });
        //         //console.log(result);
        //         this.authorization_to_release_information_to_SSA_upload = null;

        //         this.setState({ authorization_to_release_information_to_SSA_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }
        // if (this.consent_to_exchange_information_upload !== null && this.consent_to_exchange_information_upload.files != null && this.consent_to_exchange_information_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/consent_to_exchange_information/${this.consent_to_exchange_information_upload.files[0].name}`,
        //             this.consent_to_exchange_information_upload.files[0],
        //             { contentType: this.consent_to_exchange_information_upload.files[0].type });
        //         //console.log(result);
        //         this.consent_to_exchange_information_upload = null;

        //         this.setState({ consent_to_exchange_information_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }
        // if (this.authorization_to_release_information_to_HUD_upload !== null && this.authorization_to_release_information_to_HUD_upload.files != null && this.authorization_to_release_information_to_HUD_upload.files.length > 0) {
        //     try {
        //         result = await Storage.put(`ERA-IRVINE/${this.state.email}/authorization_to_release_information_to_HUD/${this.authorization_to_release_information_to_HUD_upload.files[0].name}`,
        //             this.authorization_to_release_information_to_HUD_upload.files[0],
        //             { contentType: this.authorization_to_release_information_to_HUD_upload.files[0].type });
        //         //console.log(result);
        //         this.authorization_to_release_information_to_HUD_upload = null;

        //         this.setState({ authorization_to_release_information_to_HUD_uploaded: true });
        //     }
        //     catch (err) {
        //         console.log(err)
        //         return;
        //     }
        // }

        // Reset elements
        this.photo_identification_upload = null;
        //  this.secondary_identification_upload = null;
        // this.secondary_proof_of_residential_tenancy_upload = null;
        //  this.proof_of_income_upload = null;
        //  this.proof_of_income_loss_upload = null;
        //  this.lease_agreement_upload = null;
        // this.supplemental_proofof_Annual_2020_household_income_upload = null;
        // this.participation_agreement_upload = null;
        this.tenant_payment_agreement_upload = null;
        // this.supplemental_proofof_unemployment_upload = null;
        // this.authorization_to_release_information_to_SSA_upload = null;
        // this.consent_to_exchange_information_upload = null;
        // this.authorization_to_release_information_to_HUD_upload = null;
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    //console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    uploadDocuments = async () => {
        this.setState({ isLoading: true });

        if (this.validateUploadFileSizes() === false) {
            // Validation Failed - early return.
            this.setState({ isLoading: false });
            return;
        }

        // await this.dummyRequest();
        var res = await this.UploadIndividualDocuments();
        //console.log(res);

        res = await this.loadUploadedDocuments();
        //console.log(res);

        if (this.state.photo_identification_uploaded === true &&
            //  this.state.secondary_identification_uploaded === true &&
            //  this.state.lease_agreement_uploaded === true &&
            //  this.state.proof_of_income_uploaded === true &&
            this.state.tenant_payment_agreement_uploaded === true
            //  this.state.proof_of_income_loss_uploaded === true
            // this.state.participation_agreement_uploaded === true &&
            //  this.state.authorization_to_release_information_to_SSA_uploaded === true 
            // this.state.consent_to_exchange_information_uploaded === true &&
            // this.state.authorization_to_release_information_to_HUD_uploaded === true
        ) {
            var result = window.confirm("You have uploaded all mandatory documents. Do you want to submit these documents to City of Santa Ana, Emergency Rental Assistance program to review? Click OK, to submit.");
            if (result === true) {
                var uploadRes = await this.updateDocumentUploadStatus("Ready");
                this.setState({ open: true });
                // alert('The documents are successfully submitted to City of Santa Ana, Emergency Rental Assistance program Administrator. In case any further information is required, the administrator will get in touch with you via email. You can now sign out of this application.');
            }
        }
        this.setState({ isLoading: false });
    }

    checkAllDocumentsUpload = async () => {
        if (this.state.photo_identification_uploaded === true &&
            //  this.state.secondary_identification_uploaded === true &&
            //  this.state.lease_agreement_uploaded === true &&
            //  this.state.proof_of_income_uploaded === true &&
            this.state.tenant_payment_agreement_uploaded === true
            //  this.state.proof_of_income_loss_uploaded === true
            // this.state.participation_agreement_uploaded === true &&
            //  this.state.authorization_to_release_information_to_SSA_uploaded === true 
            // this.state.consent_to_exchange_information_uploaded === true &&
            // this.state.authorization_to_release_information_to_HUD_uploaded === true
        ) {
            var result = window.confirm("You have uploaded all mandatory documents. Do you want to submit these documents to City of Santa Ana, Emergency Rental Assistance program to review? Click OK, to submit.");
            if (result === true) {
                var uploadRes = await this.updateDocumentUploadStatus("Ready");
                this.setState({ open: true });
                // alert('The documents are successfully submitted to City of Santa Ana, Emergency Rental Assistance program Administrator. In case any further information is required, the administrator will get in touch with you via email. You can now sign out of this application.');
            }
        }
    }

    submitDocuments = async () => {
        this.setState({ isLoading: true });

        if (this.validateUploadFileSizes() === false) {
            // Validation Failed - early return.
            this.setState({ isLoading: false });
            return;
        }

        var res = await this.UploadIndividualDocuments();
        //console.log(res);

        res = await this.loadUploadedDocuments();
        //console.log(res);
        if (this.state.photo_identification_uploaded === true &&
            // this.state.secondary_identification_uploaded === true &&
            // this.state.proof_of_income_uploaded === true &&
            this.state.tenant_payment_agreement_uploaded === true
            // this.state.lease_agreement_uploaded === true &&
            // this.state.proof_of_income_loss_uploaded === true
            // this.state.participation_agreement_uploaded === true &&
            //  this.state.authorization_to_release_information_to_SSA_uploaded === true
            // this.state.consent_to_exchange_information_uploaded === true &&
            // this.state.authorization_to_release_information_to_HUD_uploaded === true
        ) {
            //  if (this.state.applicantDocUploadStatus === "Info Requested") {
            var uploadRes = await this.updateDocumentUploadStatus("Ready");
            // }
            this.setState({ open: true });
            //  alert('The documents are successfully submitted to City of Santa Ana, Emergency Rental Assistance program. In case any further information is required, the administrator will get in touch with you via email. You can now sign out of this application.');
        }
        else {
            this.setState({ openSnackbar: true, snackbarMessage: "Please upload required documents to submit", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }
        this.setState({ isLoading: false });
    }

    handleClose() {
        this.setState({ open: false });
        if (this.state.isSingalApplicant == true) {
            window.location.assign(`/`);
        }
        else {
            window.location.assign(`/santaanaclaim/applicantpayment`);
        }
    }

    updateDocumentUploadStatus = async (docUploadStatus) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/santaanaapplicantupdatedocuploadstatus';

        let dateApplicantDocumentSubmitted = null;
        let timeApplicantDocumentSubmitted = null;

        if (docUploadStatus === 'Ready') {
            var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
            var todaysDate = [
                d.getFullYear(),
                ('0' + (d.getMonth() + 1)).slice(-2),
                ('0' + d.getDate()).slice(-2)
            ].join('-');

            var todaysTime = [
                ('0' + (d.getHours())).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2),
            ].join(':');

            dateApplicantDocumentSubmitted = todaysDate;
            timeApplicantDocumentSubmitted = todaysTime;
        }

        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "individualID": this.state.individualID,
                    "fundingSource": this.state.fundingSource,
                    "applicantDocUploadStatus": docUploadStatus,
                    "dateApplicantDocumentSubmitted": dateApplicantDocumentSubmitted,
                    "timeApplicantDocumentSubmitted": timeApplicantDocumentSubmitted,
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    fileUploadOnChange = async (e, fileChanged) => {
        this.setState({ isLoading: true });

        let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

        //console.log(e, fileChanged);

        switch (fileChanged) {
            case 1:
                {
                    this.photo_identification_upload = document.getElementById("photo_identification_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.photo_identification_upload !== null && this.photo_identification_upload.files != null && this.photo_identification_upload.files.length > 0) {
                        if (this.photo_identification_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("photo_identification_size_validation_id").style.display = "block";
                            this.photo_identification_upload = null;
                        }
                        else {
                            document.getElementById("photo_identification_size_validation_id").style.display = "none";

                            // await this.dummyRequest();
                            var res = await this.UploadIndividualDocuments();
                            //console.log(res);

                            res = await this.loadUploadedDocuments();
                            //console.log(res);
                            await this.checkAllDocumentsUpload();
                        }
                    }
                }
                break;
            // case 2:
            //     {
            //         this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.secondary_identification_upload !== null && this.secondary_identification_upload.files != null && this.secondary_identification_upload.files.length > 0) {
            //             if (this.secondary_identification_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("secondary_identification_size_validation_id").style.display = "block";
            //                 this.secondary_identification_upload = null;
            //             }
            //             else {
            //                 document.getElementById("secondary_identification_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 3:
            //     {
            //         this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.secondary_proof_of_residential_tenancy_upload !== null && this.secondary_proof_of_residential_tenancy_upload.files != null && this.secondary_proof_of_residential_tenancy_upload.files.length > 0) {
            //             if (this.secondary_proof_of_residential_tenancy_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("secondary_proof_of_residential_tenancy_size_validation_id").style.display = "block";
            //                 this.secondary_proof_of_residential_tenancy_upload = null;
            //             }
            //             else {
            //                 document.getElementById("secondary_proof_of_residential_tenancy_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 4:
            //     {
            //         this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.proof_of_income_upload !== null && this.proof_of_income_upload.files != null && this.proof_of_income_upload.files.length > 0) {
            //             if (this.proof_of_income_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("proof_of_income_size_validation_id").style.display = "block";
            //                 this.proof_of_income_upload = null;
            //             }
            //             else {
            //                 document.getElementById("proof_of_income_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 5:
            //     {
            //         this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.proof_of_income_loss_upload !== null && this.proof_of_income_loss_upload.files != null && this.proof_of_income_loss_upload.files.length > 0) {
            //             if (this.proof_of_income_loss_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("proof_of_income_loss_size_validation_id").style.display = "block";
            //                 this.proof_of_income_loss_upload = null;
            //             }
            //             else {
            //                 document.getElementById("proof_of_income_loss_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 6:
            //     {
            //         this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.lease_agreement_upload !== null && this.lease_agreement_upload.files != null && this.lease_agreement_upload.files.length > 0) {
            //             if (this.lease_agreement_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("lease_agreement_size_validation_id").style.display = "block";
            //                 this.lease_agreement_upload = null;
            //             }
            //             else {
            //                 document.getElementById("lease_agreement_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 7:
            //     {
            //         this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.supplemental_proofof_Annual_2020_household_income_upload !== null && this.supplemental_proofof_Annual_2020_household_income_upload.files != null && this.supplemental_proofof_Annual_2020_household_income_upload.files.length > 0) {
            //             if (this.supplemental_proofof_Annual_2020_household_income_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("supplemental_proofof_Annual_2020_household_income_size_validation_id").style.display = "block";
            //                 this.supplemental_proofof_Annual_2020_household_income_upload = null;
            //             }
            //             else {
            //                 document.getElementById("supplemental_proofof_Annual_2020_household_income_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 8:
            //     {
            //         this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.participation_agreement_upload !== null && this.participation_agreement_upload.files != null && this.participation_agreement_upload.files.length > 0) {
            //             if (this.participation_agreement_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("participation_agreement_size_validation_id").style.display = "block";
            //                 this.participation_agreement_upload = null;
            //             }
            //             else {
            //                 document.getElementById("participation_agreement_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            case 9:
                {
                    this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");

                    // Validate the file size which is being uploaded
                    if (this.tenant_payment_agreement_upload !== null && this.tenant_payment_agreement_upload.files != null && this.tenant_payment_agreement_upload.files.length > 0) {
                        if (this.tenant_payment_agreement_upload.files[0].size > maxAllowedSize) {
                            document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "block";
                            this.tenant_payment_agreement_upload = null;
                        }
                        else {
                            document.getElementById("tenant_payment_agreement_size_validation_id").style.display = "none";
                            // await this.dummyRequest();
                            var res = await this.UploadIndividualDocuments();
                            //console.log(res);

                            res = await this.loadUploadedDocuments();
                            //console.log(res);
                            await this.checkAllDocumentsUpload();
                        }
                    }
                }
                break;
            // case 10:
            //     {
            //         this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.supplemental_proofof_unemployment_upload !== null && this.supplemental_proofof_unemployment_upload.files != null && this.supplemental_proofof_unemployment_upload.files.length > 0) {
            //             if (this.supplemental_proofof_unemployment_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("supplemental_proofof_unemployment_size_validation_id").style.display = "block";
            //                 this.supplemental_proofof_unemployment_upload = null;
            //             }
            //             else {
            //                 document.getElementById("supplemental_proofof_unemployment_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 11:
            //     {
            //         this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.authorization_to_release_information_to_SSA_upload !== null && this.authorization_to_release_information_to_SSA_upload.files != null && this.authorization_to_release_information_to_SSA_upload.files.length > 0) {
            //             if (this.authorization_to_release_information_to_SSA_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("authorization_to_release_information_to_SSA_size_validation_id").style.display = "block";
            //                 this.authorization_to_release_information_to_SSA_upload = null;
            //             }
            //             else {
            //                 document.getElementById("authorization_to_release_information_to_SSA_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 12:
            //     {
            //         this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.consent_to_exchange_information_upload !== null && this.consent_to_exchange_information_upload.files != null && this.consent_to_exchange_information_upload.files.length > 0) {
            //             if (this.consent_to_exchange_information_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("consent_to_exchange_information_size_validation_id").style.display = "block";
            //                 this.consent_to_exchange_information_upload = null;
            //             }
            //             else {
            //                 document.getElementById("consent_to_exchange_information_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;
            // case 13:
            //     {
            //         this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");

            //         // Validate the file size which is being uploaded
            //         if (this.authorization_to_release_information_to_HUD_upload !== null && this.authorization_to_release_information_to_HUD_upload.files != null && this.authorization_to_release_information_to_HUD_upload.files.length > 0) {
            //             if (this.authorization_to_release_information_to_HUD_upload.files[0].size > maxAllowedSize) {
            //                 document.getElementById("authorization_to_release_information_to_HUD_size_validation_id").style.display = "block";
            //                 this.authorization_to_release_information_to_HUD_upload = null;
            //             }
            //             else {
            //                 document.getElementById("authorization_to_release_information_to_HUD_size_validation_id").style.display = "none";
            //             }
            //         }
            //     }
            //     break;

            default:
                break;
        }

        this.setState({ isLoading: false });
    }

    lockDocuments = async () => {
        if (this.state.individualCase !== 'Open') {
            this.photo_identification_upload = document.getElementById("photo_identification_upload_id");
            // this.secondary_identification_upload = document.getElementById("secondary_identification_upload_id");
            // this.secondary_proof_of_residential_tenancy_upload = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
            // this.proof_of_income_upload = document.getElementById("proof_of_income_upload_id");
            //  this.proof_of_income_loss_upload = document.getElementById("proof_of_income_loss_upload_id");
            //  this.lease_agreement_upload = document.getElementById("lease_agreement_upload_id");
            // this.supplemental_proofof_Annual_2020_household_income_upload = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
            // this.participation_agreement_upload = document.getElementById("participation_agreement_upload_id");
            this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
            // this.supplemental_proofof_unemployment_upload = document.getElementById("supplemental_proofof_unemployment_upload_id");
            // this.authorization_to_release_information_to_SSA_upload = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
            // this.consent_to_exchange_information_upload = document.getElementById("consent_to_exchange_information_upload_id");
            // this.authorization_to_release_information_to_HUD_upload = document.getElementById("authorization_to_release_information_to_HUD_upload_id");

            if (this.photo_identification_upload !== null) {
                this.photo_identification_upload.disabled = true;
            }
            // if (this.secondary_identification_upload !== null) {
            //     this.secondary_identification_upload.disabled = true;
            // }
            // if (this.secondary_proof_of_residential_tenancy_upload !== null) {
            //     this.secondary_proof_of_residential_tenancy_upload.disabled = true;
            // }
            // if (this.proof_of_income_upload !== null) {
            //     this.proof_of_income_upload.disabled = true;
            // }
            // if (this.proof_of_income_loss_upload !== null) {
            //     this.proof_of_income_loss_upload.disabled = true;
            // }
            // if (this.lease_agreement_upload !== null) {
            //     this.lease_agreement_upload.disabled = true;
            // }
            // if (this.supplemental_proofof_Annual_2020_household_income_upload !== null) {
            //     this.supplemental_proofof_Annual_2020_household_income_upload.disabled = true;
            // }
            // if (this.participation_agreement_upload !== null) {
            //     this.participation_agreement_upload.disabled = true;
            // }
            if (this.tenant_payment_agreement_upload !== null) {
                this.tenant_payment_agreement_upload.disabled = true;
            }
            // if (this.supplemental_proofof_unemployment_upload !== null) {
            //     this.supplemental_proofof_unemployment_upload.disabled = true;
            // }
            // if (this.authorization_to_release_information_to_SSA_upload !== null) {
            //     this.authorization_to_release_information_to_SSA_upload.disabled = true;
            // }
            // if (this.consent_to_exchange_information_upload !== null) {
            //     this.consent_to_exchange_information_upload.disabled = true;
            // }
            // if (this.authorization_to_release_information_to_HUD_upload !== null) {
            //     this.authorization_to_release_information_to_HUD_upload.disabled = true;
            // }
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        //  document.getElementById('secondary_identification_id').innerHTML = `<td>Proof of Address <i class="fas fa-asterisk asterisk"></i><div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /><div id="secondary_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /><div id="secondary_proof_of_residential_tenancy_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        //   document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /><div id="proof_of_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        //  document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /><div id="proof_of_income_loss_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        //  document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /><div id="lease_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /><div id="supplemental_proofof_Annual_2020_household_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /><div id="participation_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /><div id="supplemental_proofof_unemployment_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /><div id="authorization_to_release_information_to_SSA_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /><div id="consent_to_exchange_information_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
        // document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /><div id="authorization_to_release_information_to_HUD_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;

        // Lock documents if individual case is set to other than open
        this.lockDocuments();

       // if (this.state.individualCase !== 'Open') {
            var fileUploadTag = document.getElementById("photo_identification_upload_id");
            if (fileUploadTag !== null && fileUploadTag !== undefined) {
                if (fileUploadTag.addEventListener) {
                    fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 1) });
                }
                else if (fileUploadTag.attachEvent) {
                    fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 1) });
                }
            }

            // fileUploadTag = document.getElementById("secondary_identification_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 2) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 2) });
            //     }
            // }

            // fileUploadTag = document.getElementById("secondary_proof_of_residential_tenancy_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 3) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 3) });
            //     }
            // }

            // fileUploadTag = document.getElementById("proof_of_income_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 4) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 4) });
            //     }
            // }

            // fileUploadTag = document.getElementById("proof_of_income_loss_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 5) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 5) });
            //     }
            // }

            // fileUploadTag = document.getElementById("lease_agreement_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 6) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 6) });
            //     }
            // }

            // fileUploadTag = document.getElementById("supplemental_proofof_Annual_2020_household_income_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 7) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 7) });
            //     }
            // }

            // fileUploadTag = document.getElementById("participation_agreement_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 8) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 8) });
            //     }
            // }

            fileUploadTag = document.getElementById("tenant_payment_agreement_upload_id");
            if (fileUploadTag !== null && fileUploadTag !== undefined) {
                if (fileUploadTag.addEventListener) {
                    fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 9) });
                }
                else if (fileUploadTag.attachEvent) {
                    fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 9) });
                }
            }

            // fileUploadTag = document.getElementById("supplemental_proofof_unemployment_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 10) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 10) });
            //     }
            // }

            // fileUploadTag = document.getElementById("authorization_to_release_information_to_SSA_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 11) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 11) });
            //     }
            // }

            // fileUploadTag = document.getElementById("consent_to_exchange_information_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 12) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 12) });
            //     }
            // }

            // fileUploadTag = document.getElementById("authorization_to_release_information_to_HUD_upload_id");
            // if (fileUploadTag !== null && fileUploadTag !== undefined) {
            //     if (fileUploadTag.addEventListener) {
            //         fileUploadTag.addEventListener("change", (e) => { this.fileUploadOnChange(e, 13) });
            //     }
            //     else if (fileUploadTag.attachEvent) {
            //         fileUploadTag.attachEvent("change", (e) => { this.fileUploadOnChange(e, 13) });
            //     }
            // }
       // }

        // Reset all the flags
        this.setState({
            photo_identification_uploaded: false,
            //  secondary_identification_uploaded: false,
            // secondary_proof_of_residential_tenancy_uploaded: false,
            //  proof_of_income_uploaded: false,
            //  proof_of_income_loss_uploaded: false,
            //  lease_agreement_uploaded: false,
            // supplemental_proofof_Annual_2020_household_income_uploaded: false,
            // participation_agreement_uploaded: false,
            tenant_payment_agreement_uploaded: false,
            // supplemental_proofof_unemployment_uploaded: false,
            // authorization_to_release_information_to_SSA_uploaded: false,
            // consent_to_exchange_information_uploaded: false,
            // authorization_to_release_information_to_HUD_uploaded: false,
        });

        //get file names for s3 keys
        try {
            // var key = `ERA-IRVINE/${this.state.email}/`;
            var key = `SantaAna/Case/Case_${this.state.individualID}/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("photo_identification/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ photo_identification: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode === "User") {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag1 = document.getElementById('deleteS3File1');
                        if (aTag1 !== null && aTag1 !== undefined) {
                            if (this.state.individualCase !== 'Open') {
                                aTag1.style.visibility = "hidden";
                            }
                            else {
                                if (aTag1.addEventListener) {
                                    aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                                }
                                else if (aTag1.attachEvent) {
                                    aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                                }
                            }
                        }
                    }
                    this.setState({ photo_identification_uploaded: true });
                }
                catch (err) {
                    console.log("Error photo_identification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 1;
                }
            }
            // else if (files[i].key.includes("proof_of_address/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ secondary_identification: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('secondary_identification_id').innerHTML = `<td>Proof of Address <i class="fas fa-asterisk asterisk"></i><div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('secondary_identification_id').innerHTML = `<td>Proof of Address <i class="fas fa-asterisk asterisk"></i><div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File2');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(2) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(2) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ secondary_identification_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error secondary_identification - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('secondary_identification_id').innerHTML = `<td>Proof of Address <i class="fas fa-asterisk asterisk"></i><div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /><div id="secondary_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 2;
            //     }
            // }
            // else if (files[i].key.includes("secondary_proof_of_residential_tenancy/")) {
            //     try {
            //         var file31 = await Storage.get(files[i].key);
            //         this.setState({ secondary_proof_of_residential_tenancy: file31 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_proof_of_residential_tenancy}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.secondary_proof_of_residential_tenancy}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag31 = document.getElementById('deleteS3File3');
            //             if (aTag31 !== null && aTag31 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag31.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag31.addEventListener) {
            //                         aTag31.addEventListener("click", () => { this.deleteS3File(3) });
            //                     }
            //                     else if (aTag31.attachEvent) {
            //                         aTag31.attachEvent("click", () => { this.deleteS3File(3) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ secondary_proof_of_residential_tenancy_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error secondary_proof_of_residential_tenancy - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /><div id="secondary_proof_of_residential_tenancy_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 3;
            //     }
            // }
            // else if (files[i].key.includes("proof_of_income/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ proof_of_income: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File4');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(4) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(4) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ proof_of_income_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error proof_of_income - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /><div id="proof_of_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 4;
            //     }
            // }
            // else if (files[i].key.includes("proof_of_income_loss/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ proof_of_income_loss: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File5');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(5) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(5) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ proof_of_income_loss_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error proof_of_income_loss - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /><div id="proof_of_income_loss_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 5;
            //     }
            // }
            // else if (files[i].key.includes("lease_agreement/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ lease_agreement: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File6');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(6) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(6) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ lease_agreement_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error lease_agreement - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /><div id="lease_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 6;
            //     }
            // }
            // else if (files[i].key.includes("supplemental_proofof_Annual_2020_household_income/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ supplemental_proofof_Annual_2020_household_income: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_Annual_2020_household_income}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File7');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(7) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(7) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ supplemental_proofof_Annual_2020_household_income_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error supplemental_proofof_Annual_2020_household_income - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /><div id="supplemental_proofof_Annual_2020_household_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 7;
            //     }
            // }
            // else if (files[i].key.includes("participation_agreement/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ participation_agreement: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.participation_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.participation_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File8');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(8) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(8) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ participation_agreement_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error participation_agreement - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /><div id="participation_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 8;
            //     }
            // }
            else if (files[i].key.includes("tenant_payment_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tenant_payment_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    if (this.state.mode && this.state.mode === "User") {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
                    }
                    else {
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm">Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red"></i></a></td>`;
                        var aTag2 = document.getElementById('deleteS3File9');
                        if (aTag2 !== null && aTag2 !== undefined) {
                            if (this.state.individualCase !== 'Open') {
                                aTag2.style.visibility = "hidden";
                            }
                            else {
                                if (aTag2.addEventListener) {
                                    aTag2.addEventListener("click", () => { this.deleteS3File(9) });
                                }
                                else if (aTag2.attachEvent) {
                                    aTag2.attachEvent("click", () => { this.deleteS3File(9) });
                                }
                            }
                        }
                    }
                    var aTagDownload = document.getElementById('downloadForm');
                    if (aTagDownload !== null && aTagDownload !== undefined) {

                        if (aTagDownload.addEventListener) {
                            aTagDownload.addEventListener("click", () => { this.downloadForm() });
                        }
                        else if (aTagDownload.attachEvent) {
                            aTagDownload.attachEvent("click", () => { this.downloadForm() });
                        }

                    }
                    this.setState({ tenant_payment_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error tenant_payment_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm" >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    return 9;
                }
            }
            // else if (files[i].key.includes("supplemental_proofof_unemployment/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ supplemental_proofof_unemployment: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.supplemental_proofof_unemployment}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File10');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(10) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(10) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ supplemental_proofof_unemployment_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error supplemental_proofof_unemployment - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /><div id="supplemental_proofof_unemployment_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 10;
            //     }
            // }
            // else if (files[i].key.includes("authorization_to_release_information_to_SSA/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ authorization_to_release_information_to_SSA: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_SSA}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_SSA}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File11');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(11) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(11) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ authorization_to_release_information_to_SSA_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error authorization_to_release_information_to_SSA - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /><div id="authorization_to_release_information_to_SSA_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 11;
            //     }
            // }
            // else if (files[i].key.includes("consent_to_exchange_information/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ consent_to_exchange_information: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.consent_to_exchange_information}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.consent_to_exchange_information}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File12');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(12) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(12) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ consent_to_exchange_information_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error consent_to_exchange_information - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /><div id="consent_to_exchange_information_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 12;
            //     }
            // }
            // else if (files[i].key.includes("authorization_to_release_information_to_HUD/")) {
            //     try {
            //         var file2 = await Storage.get(files[i].key);
            //         this.setState({ authorization_to_release_information_to_HUD: file2 });

            //         var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
            //         var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
            //         if (shortFileName.length > 35) {
            //             shortFileName = shortFileName.substring(0, 35) + '...';
            //         }
            //         shortFileName = shortFileName + '.' + fileExtension;

            //         var lastModified = `${files[i].lastModified}`;
            //         if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            //             lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
            //         }

            //         if (this.state.mode && this.state.mode === "User") {
            //             document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_HUD}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
            //         }
            //         else {
            //             document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.authorization_to_release_information_to_HUD}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red"></i></a></td>`;
            //             var aTag2 = document.getElementById('deleteS3File13');
            //             if (aTag2 !== null && aTag2 !== undefined) {
            //                 if (this.state.individualCase !== 'Open') {
            //                     aTag2.style.visibility = "hidden";
            //                 }
            //                 else {
            //                     if (aTag2.addEventListener) {
            //                         aTag2.addEventListener("click", () => { this.deleteS3File(13) });
            //                     }
            //                     else if (aTag2.attachEvent) {
            //                         aTag2.attachEvent("click", () => { this.deleteS3File(13) });
            //                     }
            //                 }
            //             }
            //         }
            //         this.setState({ authorization_to_release_information_to_HUD_uploaded: true });
            //     }
            //     catch (err) {
            //         console.log("Error authorization_to_release_information_to_HUD - " + err);
            //         this.setState({ isLoading: false });
            //         document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /><div id="authorization_to_release_information_to_HUD_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
            //         return 13;
            //     }
            // }
        }

        // Set the document upload flag to Pending in case if any mandatory document is missing.
        if (this.state.photo_identification_uploaded === false ||
            //  this.state.proof_of_income_uploaded === false ||
            this.state.tenant_payment_agreement_uploaded === false
            //  this.state.lease_agreement_uploaded === false ||
            //  this.state.proof_of_income_loss_uploaded === false 
            // this.state.participation_agreement_uploaded === false ||
            // this.state.authorization_to_release_information_to_SSA_uploaded === false 
            // this.state.consent_to_exchange_information_uploaded === false ||
            // this.state.authorization_to_release_information_to_HUD_uploaded === false
        ) {
            if (this.state.applicantDocUploadStatus !== "Info Requested") {
                var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //console.log(uploadRes);

                var res = await this.getApplicantInfo();
                this.setState({
                    applicantName: res[0][0],
                    address1: res[0][1],
                    address2: res[0][2],
                    requestDate: res[0][3],
                    landlordName: res[0][4],
                    landlordEmail: res[0][5],
                    landlordPhone: res[0][6],
                    email: res[0][7],
                    individualCase: res[0][8],
                    //ST.transactionType as Paymentmode = res[0][9],
                    addresstobeUsedforMailing: res[0][10],
                    payeenameCheck: res[0][14],

                    santaAnaTransactionId: res[0][11],
                    paymentId: res[0][12],

                    informationStatus: res[0][13],
                    applicantDocUploadStatus: res[0][15],
                });
                // this.setState({
                //     applicantName: res[0][0],
                //     individualID: res[0][1],
                //     dateApplied: res[0][2],
                //     claimStatus: res[0][3],
                //     applicantDocUploadStatus: res[0][4],
                //     individualCase: res[0][5],
                // });
            }
        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        this.setState({ isLoading: true });
        try {

            // var key = `ERA-IRVINE/${this.state.email}/`;
            var key = `SantaAna/Case/Case_${this.state.individualID}/`;
            var files = await Storage.list(key);
            //console.log(files);

            // Remove the earlier files for the section for which the new files to be uploaded

            switch (fileToDelete) {
                case 1:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("photo_identification/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('photo_identification_id').innerHTML = `<td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /><div id="photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ photo_identification: "" });
                        var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                        //var res = await this.getApplicantInfo();
                        // this.setState({
                        //     applicantName: res[0][0],
                        //     individualID: res[0][1],
                        //     dateApplied: res[0][2],
                        //     claimStatus: res[0][3],
                        //     applicantDocUploadStatus: res[0][4],
                        //     individualCase: res[0][5],
                        // });
                    }
                    break;
                // case 2:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("proof_of_address/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('secondary_identification_id').innerHTML = `<td>Proof of Address <i class="fas fa-asterisk asterisk"></i><div id="secondary_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" /><div id="secondary_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ secondary_identification: "" });
                //         var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //         //var res = await this.getApplicantInfo();
                //         // this.setState({
                //         //     applicantName: res[0][0],
                //         //     individualID: res[0][1],
                //         //     dateApplied: res[0][2],
                //         //     claimStatus: res[0][3],
                //         //     applicantDocUploadStatus: res[0][4],
                //         //     individualCase: res[0][5],
                //         // });
                //     }
                //     break;
                // case 3:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("secondary_proof_of_residential_tenancy/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('secondary_proof_of_residential_tenancy_id').innerHTML = `<td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" /><div id="secondary_proof_of_residential_tenancy_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ secondary_proof_of_residential_tenancy: "" });
                //     }
                //     break;
                // case 4:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("proof_of_income/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('proof_of_income_id').innerHTML = `<td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /><div id="proof_of_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ proof_of_income: "" });
                //         var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //         // var res = await this.getApplicantInfo();
                //         // this.setState({
                //         //     applicantName: res[0][0],
                //         //     individualID: res[0][1],
                //         //     dateApplied: res[0][2],
                //         //     claimStatus: res[0][3],
                //         //     applicantDocUploadStatus: res[0][4],
                //         //     individualCase: res[0][5],
                //         // });
                //     }
                //     break;
                // case 5:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("proof_of_income_loss/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('proof_of_income_loss_id').innerHTML = `<td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /><div id="proof_of_income_loss_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ proof_of_income_loss: "" });
                //         var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //         // var res = await this.getApplicantInfo();
                //         // this.setState({
                //         //     applicantName: res[0][0],
                //         //     individualID: res[0][1],
                //         //     dateApplied: res[0][2],
                //         //     claimStatus: res[0][3],
                //         //     applicantDocUploadStatus: res[0][4],
                //         //     individualCase: res[0][5],
                //         // });
                //     }
                //     break;
                // case 6:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("lease_agreement/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('lease_agreement_id').innerHTML = `<td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /><div id="lease_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ lease_agreement: "" });
                //         var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                //         // var res = await this.getApplicantInfo();
                //         // this.setState({
                //         //     applicantName: res[0][0],
                //         //     individualID: res[0][1],
                //         //     dateApplied: res[0][2],
                //         //     claimStatus: res[0][3],
                //         //     applicantDocUploadStatus: res[0][4],
                //         //     individualCase: res[0][5],
                //         // });
                //     }
                //     break;
                // case 7:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("supplemental_proofof_Annual_2020_household_income/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('supplemental_proofof_Annual_2020_household_income_id').innerHTML = `<td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" /><div id="supplemental_proofof_Annual_2020_household_income_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ supplemental_proofof_Annual_2020_household_income: "" });
                //     }
                //     break;
                // case 8:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("participation_agreement/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('participation_agreement_id').innerHTML = `<td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" /><div id="participation_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ participation_agreement: "" });
                //     }
                //     break;
                case 9:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("tenant_payment_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href=${this.state.staticDoc} class="sample-pdf" id="downloadForm" >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /><div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                        this.setState({ tenant_payment_agreement: "" });
                        var uploadRes = await this.updateDocumentUploadStatus("Info Requested");
                        // var res = await this.getApplicantInfo();
                        // this.setState({
                        //     applicantName: res[0][0],
                        //     individualID: res[0][1],
                        //     dateApplied: res[0][2],
                        //     claimStatus: res[0][3],
                        //     applicantDocUploadStatus: res[0][4],
                        //     individualCase: res[0][5],
                        // });
                    }
                    break;
                // case 10:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("supplemental_proofof_unemployment/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('supplemental_proofof_unemployment_id').innerHTML = `<td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" /><div id="supplemental_proofof_unemployment_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ supplemental_proofof_unemployment: "" });
                //     }
                //     break;
                // case 11:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("authorization_to_release_information_to_SSA/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('authorization_to_release_information_to_SSA_id').innerHTML = `<td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" /><div id="authorization_to_release_information_to_SSA_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ authorization_to_release_information_to_SSA: "" });
                //     }
                //     break;
                // case 12:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("consent_to_exchange_information/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('consent_to_exchange_information_id').innerHTML = `<td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" /><div id="consent_to_exchange_information_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ consent_to_exchange_information: "" });
                //     }
                //     break;
                // case 13:
                //     {
                //         for (var i = 0; i < files.length; i++) {
                //             if (files[i].key.includes("authorization_to_release_information_to_HUD/")) {
                //                 var key1 = files[i].key;
                //                 var res = await Storage.remove(key1);
                //                 //console.log(res);
                //             }
                //         }
                //         document.getElementById('authorization_to_release_information_to_HUD_id').innerHTML = `<td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" /><div id="authorization_to_release_information_to_HUD_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                //         this.setState({ authorization_to_release_information_to_HUD: "" });
                //     }
                //     break;

                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        // var safeload = await this.loadUploadedDocuments();
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    googleTranslateElementInit() {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }

    closeSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    //downloadForm = () => {
    downloadForm = async (e) => {
        e.preventDefault();
        // e.prevent
        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3FrontendBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var template = await Storage.get(`StaticDocs/TenantPaymentAgreement/TenantPaymentAgreement.pdf`);
            //console.log(template);

            let link = document.createElement('a');
            link.href = template;
            link.target = '_blank';
            link.download = template;
            link.click();
        }
        catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    }

    render() {
        return (
            <div className="d-flex flex-column sticky-footer-wrapper">
                {/* header starts */}
                <header>
                    <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                        <a className="navbar-brand" href="#">
                            <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                        </a>
                        <div className="ml-auto d-flex">
                            <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                <div className="landing-gtraslate"><GoogleTranslate /></div>
                                <button className="btn btn-light d-inline-block mr-3 ml-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                                <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                            </div>
                        </div>
                    </nav>
                </header>
                {/* header ends */}

                <main className="main flex-fill">
                    <div className="container-fluid">
                        <div className="box-shadow p-3 mb-3">
                            <h4 className="text-primary">City of Santa Ana, Emergency Rental Assistance</h4>
                            <hr />
                            <div className="wh-note">
                                <span>INSTRUCTIONS:</span>
                                <div className="note-list">
                                    <ol>
                                        <li>Review contact information.  If anything needs to be updated, please email ERASAConnect@UnitedWayOC.org.  Include your case number, tenant name and address in your email.</li>
                                        <li>Upload all required documents</li>
                                        <ul>
                                            <li>Please attach a file to be uploaded for all required documents by clicking on 'Choose File' button.</li>
                                            <li>Only 1 file allowed to upload per row.</li>
                                            <li>Size of file to be uploaded should be less or equal to 5 MB.</li>
                                            <li>All the documents with (*) sign are required to be uploaded in order to proceed with application under City of Santa Ana, Emergency Rental Assistance program.</li>
                                            {/* {this.state.individualCase === 'Open'? 
                                            (
                                                <React.Fragment></React.Fragment>
                                            )
                                            :
                                            (
                                                <li>Uploaded documents cannot be changed while the application is under review or once a determination has been made.</li>
                                            )
                                        } */}
                                        </ul>
                                    </ol>
                                </div>
                            </div>
                            <hr />
                            <h2 className="profile-title d-inline-block"><i class="fas fa-user-circle mr-2"></i>Applicant</h2>
                            <hr />
                            {/* <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Applicant Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Date Applied</label>
                                    <input type="text" className="form-control" disabled value={this.state.dateApplied_Formatted} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Applicant ID</label>
                                    <input type="text" className="form-control" disabled value={this.state.individualID} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Funding Source</label>
                                    <input type="text" className="form-control" disabled value={this.state.fundingSource} />
                                </div>
                            </div> */}
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Applicant Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address1</label>
                                    <input type="text" className="form-control" disabled value={this.state.address1} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Address2</label>
                                    <input type="text" className="form-control" disabled value={this.state.address2} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Landlord Name</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordName} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Email</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordEmail} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Mobile</label>
                                    <input type="text" className="form-control" disabled value={this.state.landlordPhone} />
                                </div>
                            </div>
                            <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Request Date</label>
                                    <input type="text" className="form-control" disabled value={this.state.requestDate} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Applicant ID</label>
                                    <input type="text" className="form-control" disabled value={this.state.individualID} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Funding Source </label>
                                    <input type="text" className="form-control" disabled value={this.state.fundingSource} />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Document Status </label>
                                    <input type="text" className="form-control" disabled value={this.state.applicantDocUploadStatus} />
                                </div>
                            </div>

                            <React.Fragment>
                                {/* <Prompt
                                    when={this.state.shouldBlockNavigation}
                                    message='You have unsaved changes, are you sure you want to leave?'
                                /> */}
                                <input className="form-control" type="hidden" ></input>
                                <hr />
                                <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                                <div className="table-responsive">
                                    <table class="table table-bordered table-cgreen mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" width="35%">Document</th>
                                                <th scope="col" width="25%">File Name</th>
                                                <th scope="col" width="25%">Uploaded Date Time</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="photo_identification_id">
                                                <td>Photo Identification <i class="fas fa-asterisk asterisk"></i><div id="photo_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" />
                                                    <div id="photo_identification_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            {/* <tr id="secondary_identification_id">
                                                <td>Proof of Address <i class="fas fa-asterisk asterisk"></i> <div id="secondary_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="secondary_identification_upload_id" />
                                                    <div id="secondary_identification_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            <tr id="lease_agreement_id">
                                                <td>Lease Agreement<i class="fas fa-asterisk asterisk"></i><div id="lease_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" />
                                                    <div id="lease_agreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="secondary_proof_of_residential_tenancy_id">
                                                <td>Secondary Proof of Residential Tenancy, if applicable <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3urz52B">Download Form</a><div id="secondary_proof_of_residential_tenancy_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="secondary_proof_of_residential_tenancy_upload_id" />
                                                    <div id="secondary_proof_of_residential_tenancy_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="proof_of_income_id">
                                                <td>Proof of Income <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" />
                                                    <div id="proof_of_income_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            <tr id="tenant_payment_agreement_id">
                                                <td>Tenant Payment Agreement <i class="fas fa-asterisk asterisk"></i><a target="_blank" href={this.state.staticDoc} class="sample-pdf" id="downloadForm" onClick={this.downloadForm} >Download Form</a><div id="tenant_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" />
                                                    <div id="tenant_payment_agreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            {/* <tr id="supplemental_proofof_unemployment_id">
                                                <td>Supplemental Proof of Unemployment and/or Financial Hardship Due to COVID-19 <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3fMWvf1">Download Form</a><div id="supplemental_proofof_unemployment_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_unemployment_upload_id" />
                                                    <div id="supplemental_proofof_unemployment_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="proof_of_income_loss_id">
                                                <td>Proof of Income loss <i class="fas fa-asterisk asterisk"></i> <i class="fas fa-asterisk asterisk"></i><div id="proof_of_income_loss_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" />
                                                    <div id="proof_of_income_loss_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="supplemental_proofof_Annual_2020_household_income_id">
                                                <td>Supplemental Proof of Annual 2020 Household Income for All Household Members <a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/31Prv5B">Download Form</a><div id="supplemental_proofof_Annual_2020_household_income_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="supplemental_proofof_Annual_2020_household_income_upload_id" />
                                                    <div id="supplemental_proofof_Annual_2020_household_income_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="participation_agreement_id">
                                                <td>City of Irvine, Tenant Applicant, Participation Agreement <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rTzIk4">Download Form</a><div id="participation_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="participation_agreement_upload_id" />
                                                    <div id="participation_agreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="authorization_to_release_information_to_SSA_id">
                                                <td>Authorization to Release Information to Social Services Agency (for all other Housing Authorities) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/2RetJcU">Download Form</a><div id="authorization_to_release_information_to_SSA_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_SSA_upload_id" />
                                                    <div id="authorization_to_release_information_to_SSA_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="authorization_to_release_information_to_HUD_id">
                                                <td>Authorization to Release Information to Housing and Urban Development (for OC Housing Authority) <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/39LmyPW">Download Form</a><div id="authorization_to_release_information_to_HUD_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="authorization_to_release_information_to_HUD_upload_id" />
                                                    <div id="authorization_to_release_information_to_HUD_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                            {/* <tr id="consent_to_exchange_information_id">
                                                <td>Consent to Exchange Information with Homeless Management Information System and other parties such as Landlord <i class="fas fa-asterisk asterisk"></i><a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3rYR9Q2">Download Form</a><div id="consent_to_exchange_information_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="consent_to_exchange_information_upload_id" />
                                                    <div id="consent_to_exchange_information_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                {/* new */}
                                <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Instructions Related to Payment</h2>
                                <hr />
                                <div className="box-shadow p-3 mb-3">
                                    <div className="row">
                                        <div className="col-auto">
                                            {/* <label>Instructions Related to Payment</label> */}
                                            <lable>RENTAL PAYMENT, CHECK PICK-UP INSTRUCTIONS</lable> <br/>
                                            <lable>Rental assistance made directly to the Tenant is paid by check.</lable>
                                            <ol>
                                                {/* <ul> <li>RENTAL PAYMENT, CHECK PICK-UP INSTRUCTIONS </li></ul>
                                                <ul><li>Rental assistance made directly to the Tenant is paid by check.</li></ul> */}
                                                <li>When your check is ready, a program administrator will contact you by email to schedule a date and time for pick-up.</li>
                                                <li>Pick up is from Orange County United Way, Irvine CA 92614</li>
                                                <li>Only the tenant on this application, whose name is printed on the check, can pick up. Checks will NOT be released to anyone other than the payee listed on the check.</li>
                                                <li>Tenant is required to show valid photo identification at time of check pick-up.  Checks will NOT be released to tenant without valid proof of identification</li>
                                            </ol>

                                        </div>
                                    </div>
                                </div>

                                <div className="text-right mt-3 mt-sm-0 mb-3">
                                    {/* <button className="btn btn-secondary mb-3 mb-sm-0" disabled={this.state.individualCase !== 'Open'} onClick={this.uploadDocuments}>  SAVE AS DRAFT</button> */}
                                    <button className="btn btn-primary ml-3 mb-3 mb-sm-0" disabled={this.state.individualCase !== 'Open'} onClick={this.submitDocuments}>  SUBMIT</button>
                                </div>

                                {/* <div className="assistoc-speeddial">
                                    <Tooltip title="Save" arrow>
                                        <Button type="button" onClick={this.uploadDocuments} className="btn btn-primary whoc-float-btn">
                                            <i className="fas fa-save"></i>
                                        </Button>
                                    </Tooltip>
                                </div> */}

                                <Backdrop open={this.state.isLoading}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <Snackbar
                                    anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                    autoHideDuration={3000}
                                    open={this.state.openSnackbar}
                                    onClose={() => this.setState({ openSnackbar: false })}
                                    message={this.state.snackbarMessage}
                                    key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                    className={this.state.snackbarMessageClass}
                                // action={
                                //     <React.Fragment>
                                //         <IconButton aria-label="close" color="inherit" onClick={this.closeSnackbar.bind()}>
                                //             <CloseIcon />
                                //         </IconButton>
                                //     </React.Fragment>
                                // }
                                />

                                <Dialog open={this.state.open} className="thankyoudialog" onClose={() => this.handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title">{"Information Submitted!"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            {this.state.msg}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.handleClose()} color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        </div>
                    </div>
                </main>
                {/* main content ends */}

                {/* footer starts */}
                <footer className="foot bg-primary text-white text-center p-2">
                    <p className="mb-1">Powered by Orange County United Way</p>
                </footer>
                {/* footer ends */}
            </div>
        );
    }
}

export default ManageDocuments;
