import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import RegisterERAIrvineUser from "./registerERAIrvineUser";
import AppWithAuth from "./AppWithAuth";
import UserDetails from "./userDetails";
import ManageDocuments from './manageDocuments';
import ApplicantGrid from './applicantGrid';
import ApplicantPaymentGrid from './applicantPaymentGrid';
import ApplicantPaymentDetails from './applicantPaymentDetail';
function Routes() {
    const { pathname } = useLocation();

    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path="/" component={AppWithAuth}></Route>
            <Route exact path="/registerapplicant" component={RegisterERAIrvineUser} ></Route>
            <Route exact path="/irvineclaim/edit/:id" component={UserDetails} ></Route>
            {/* <Route exact path="/irvineclaim/managedocuments/:id" component={ManageDocuments} ></Route> */}
            {/* <Route exact path="/santaana/managedocuments/:id/:fundingSource/:mode" component={ManageDocuments} ></Route>
            <Route exact path="/santaana/applicant" component={ApplicantGrid} ></Route> */}

            <Route exact path="/santaanaclaim/applicantdetails/:id/:fundingSource/:transId/:mode" component={ManageDocuments} ></Route>
            <Route exact path="/santaanaclaim/applicantdetails/:id/:mode" component={ApplicantPaymentDetails} ></Route>
            {/* <Route exact path="/santaanaclaim/applicantdetails/:id/:mode" component={LandlordPaymentDetails} ></Route> */}
            <Route exact path="/santaanaclaim/applicantpayment" component={ApplicantPaymentGrid} ></Route>
        </Switch>
    );
}

export default Routes;