import React from "react";
import imgLogo from './imgs/irvine-logo.svg';

class ThankYouERAIrvine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div>
                <div>

                    {/* header starts */}
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main">
                        <div className="container-fluid">
                            <div className="card-shadow mb-5 mb-md-0">
                                <div className="welcome-box text-center p-5">
                                    <img src={imgLogo} height="100" className="logo" alt="Assist OC logo" loading="lazy" />
                                    <h2 className="text-primary title-text font-weight-bold mb-0">City of Irvine, Emergency Rental Assistance Program (ERA)</h2>
                                    <hr />
                                    <p><i class="fas fa-check-circle success-icon"></i></p>
                                    <p className="text-center">Application Submitted!<br /><br />Your application number is <b>{this.props.individualID}</b>. <br /><br />Your application has been recorded and will be reviewed for its potential eligibility when the application period is closed. <br />For more information about the program timeline, go to <a href="https://www.cityofirvine.org/news-media/news-article/city-irvine-emergency-rental-assistance-program">City of Irvine ERA Program</a>.</p>
                                    <hr />
                                </div>
                            </div>
                        </div>
                    </main>
                    {/* main content ends */}

                    {/* footer starts */}
                    <footer className="foot bg-primary text-white text-center p-2">
                        <p className="mb-1">Powered by Orange County United Way</p>
                    </footer>
                    {/* footer ends */}

                </div>
            </div>
        );
    }
}

export default ThankYouERAIrvine;