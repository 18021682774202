import React from "react";
import imgLogo from './imgs/logo-assist-oc.png';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

import { Auth, API } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GoogleTranslate from './google';

class UserDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,

            disqualified: true,
            showPopup: false,
            enableEligibleButton: false,

            dialogText: "",
            disqualifiedText: "According to your response, you do not qualify for the City of Irvine's current Emergency Rental Assistance Program. To learn about other assistance programs please call 211.",
            userRegisteredSuccessfully: false,


            doYouLiveInIrvine: null,
            doYouRent: null,
            areYouBehindOnRent: null,
            doYouHoldSection8HousingVoucher: null,
            experiencedFinancialHardshipInCovid: null,
            isAnnualIncomeLessThanMaxLimit: null,

            firstName: "",
            middleName: "",
            lastName: "",
            suffix: "",
            addressLine1: "",
            addressLine2: "",
            city: "Irvine",
            state: "",
            zipcode: "",
            phone: "",
            phoneType: "",
            email: "",
            gender: "",
            race: "",
            ethnicity: "",
            dateOfBirth: '0000-00-00',
            dontKnowDateOfBirth: false,
            interpretationServicesRequiredForLanguage: "",
            secondaryFirstName: "",
            secondaryLastName: "",
            secondaryEmail: "",
            secondaryPhone: "",
            householdNumber: null,
            countAdult: null,
            countChildren: null,
            headOfHousehold: null,
            veteran: null,
            currentMonthlyIncome: "",
            totalHouseholdAnnualIncome: "",
            // impactOfCovid19: "",
            qualifiedForUnemploymentBenefits: false,
            experiencedReductionInHouseholdIncome: false,
            incurredSignificantCosts: false,
            reductionOfChildSupport: false,
            otherFinancialHardship: false,
            isSomeoneOnRentalOrLeaseAgreement: null,
            landlordFullName: "",
            landlordEmail: "",
            landlordPhone: "",
            monthlyRentPayment: "",
            monthsBehindOnRent: "",
            amountBehindOnRent: "",
            receivedHousingSubsidy: null,
            subsidyExplanation: "",
            filedCovid19FinancialStressDeclaration: null,
            receivedEvictionNotice: null,
            dateEvictionNoticeReceived: '0000-00-00',
            createdDate: null,
            createdTime: null,
            ipAddress: null,



            // Validation Error States
            doYouLiveInIrvine_Error: "",
            doYouRent_Error: "",
            areYouBehindOnRent_Error: "",
            doYouHoldSection8HousingVoucher_Error: "",
            experiencedFinancialHardshipInCovid_Error: "",
            isAnnualIncomeLessThanMaxLimit_Error: "",

            firstName_Error: "",
            middleName_Error: "",
            lastName_Error: "",
            suffix_Error: "",
            addressLine1_Error: "",
            addressLine2_Error: "",
            city_Error: "",
            state_Error: "",
            zipcode_Error: "",
            phone_Error: "",
            phoneType_Error: "",
            email_Error: "",
            gender_Error: "",
            race_Error: "",
            ethnicity_Error: "",
            dateOfBirth_Error: "",
            interpretationServicesRequiredForLanguage_Error: "",
            secondaryFirstName_Error: "",
            secondaryLastName_Error: "",
            secondaryEmail_Error: "",
            secondaryPhone_Error: "",
            householdNumber_Error: "",
            countAdult_Error: "",
            countChildren_Error: "",
            headOfHousehold_Error: "",
            veteran_Error: "",
            currentMonthlyIncome_Error: "",
            totalHouseholdAnnualIncome_Error: "",
            impactOfCovid19_Error: "",
            isSomeoneOnRentalOrLeaseAgreement_Error: "",
            landlordFullName_Error: "",
            landlordEmail_Error: "",
            landlordPhone_Error: "",
            monthlyRentPayment_Error: "",
            monthsBehindOnRent_Error: "",
            amountBehindOnRent_Error: "",
            receivedHousingSubsidy_Error: "",
            subsidyExplanation_Error: "",
            filedCovid19FinancialStressDeclaration_Error: "",
            receivedEvictionNotice_Error: "",
            dateEvictionNoticeReceived_Error: "",

        }

        // preserve the initial state in a new object
        this.initialState = { ...this.state };

        this.rentalAgreementUpload = null;

        /////////// Validation Control References - Start ///////////
        this.doYouLiveInIrvineRef = React.createRef();
        this.doYouRentRef = React.createRef();
        this.areYouBehindOnRentRef = React.createRef();
        this.doYouHoldSection8HousingVoucherRef = React.createRef();
        this.experiencedFinancialHardshipInCovidRef = React.createRef();
        this.isAnnualIncomeLessThanMaxLimitRef = React.createRef();

        this.firstNameRef = React.createRef();
        this.middleNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.suffixRef = React.createRef();
        this.addressLine1Ref = React.createRef();
        this.addressLine2Ref = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipcodeRef = React.createRef();
        this.phoneRef = React.createRef();
        this.phoneTypeRef = React.createRef();
        this.emailRef = React.createRef();
        this.genderRef = React.createRef();
        this.raceRef = React.createRef();
        this.ethnicityRef = React.createRef();
        this.dateOfBirthRef = React.createRef();
        this.interpretationServicesRequiredForLanguageRef = React.createRef();
        this.secondaryFirstNameRef = React.createRef();
        this.secondaryLastNameRef = React.createRef();
        this.secondaryEmailRef = React.createRef();
        this.secondaryPhoneRef = React.createRef();
        this.householdNumberRef = React.createRef();
        this.countAdultRef = React.createRef();
        this.countChildrenRef = React.createRef();
        this.headOfHouseholdRef = React.createRef();
        this.veteranRef = React.createRef();
        this.currentMonthlyIncomeRef = React.createRef();
        this.totalHouseholdAnnualIncomeRef = React.createRef();
        this.impactOfCovid19Ref = React.createRef();
        this.qualifiedForUnemploymentBenefitsRef = React.createRef();
        this.experiencedReductionInHouseholdIncomeRef = React.createRef();
        this.incurredSignificantCostsRef = React.createRef();
        this.reductionOfChildSupportRef = React.createRef();
        this.otherFinancialHardshipRef = React.createRef();
        this.isSomeoneOnRentalOrLeaseAgreementRef = React.createRef();
        this.landlordFullNameRef = React.createRef();
        this.landlordEmailRef = React.createRef();
        this.landlordPhoneRef = React.createRef();
        this.monthlyRentPaymentRef = React.createRef();
        this.monthsBehindOnRentRef = React.createRef();
        this.amountBehindOnRentRef = React.createRef();
        this.receivedHousingSubsidyRef = React.createRef();
        this.subsidyExplanationRef = React.createRef();
        this.filedCovid19FinancialStressDeclarationRef = React.createRef();
        this.receivedEvictionNoticeRef = React.createRef();
        this.dateEvictionNoticeReceivedRef = React.createRef();
        /////////// Validation Control References - Ends ///////////

    }

    async componentDidMount() {
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = this.googleTranslateElementInit;

        //console.log(this.props.match.params.id);
        this.loadData(this.props.match.params.id);

        await fetch('https://api.ipify.org/?format=json')
        .then(response => response.json())
        .then(data => {
            /* use data here */
            //console.log(data);
            if (data !== undefined) {
                this.setState({ ipAddress: data.ip });
            }
        });
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    // adminGetIndividual = async () => {
    //     var user = await Auth.currentAuthenticatedUser();
    //     let apiName = process.env.REACT_APP_APIGatewayName;
    //     let path = '/getirvineindividualbyindividualid';
    //     let myInit = { // OPTIONAL
    //         body: {
    //             "info": {
    //                 "individualID": this.state.individualID,
    //             }
    //         }, // replace this with attributes you need
    //         headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
    //     }
    //     return await API.post(apiName, path, myInit);
    // }

    adminGetIndividual = async (individualID) => {
        // Simple POST request with a JSON body using fetch
        var user = await Auth.currentAuthenticatedUser();
         let apiName = process.env.REACT_APP_APIGatewayName;
            //console.log(apiName);
        let path = '/getirvineindividualbyindividualid';
        let myInit = {};

        myInit = { // OPTIONAL
            body: {
              "info": {
                individualID: individualID,
              }
            }, // replace this with attributes you need
            headers: {
              Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
          }
          return await API.post(apiName, path, myInit);
    };

    loadData = async (individualID) => {
        try {
            this.setState({ isLoading: true });
            var res = await this.adminGetIndividual(individualID);
            this.setState({ isLoading: false });

            if (res.length === 0) {
                console.log("ERROR: IndividualID is not available in System." + individualID);
                return;
            }
            //console.log(res[0]);

            this.setState({

                firstName: res[0][0],
                middleName: res[0][1],
                lastName: res[0][2],
                suffix: res[0][3],
                addressLine1: res[0][4],
                addressLine2: res[0][5],
                city: res[0][6],
                state: res[0][7],
                zipcode: res[0][8],
                phone: res[0][9],
                phoneType: res[0][10],
                email: res[0][11],
                gender: res[0][12],
                race: res[0][13],
                ethnicity: res[0][14],
                dateOfBirth: (res[0][15] === '1000-01-01') ? '0000-00-00' : res[0][15],
                interpretationServicesRequiredForLanguage: res[0][16],
                secondaryFirstName: res[0][17],
                secondaryLastName: res[0][18],
                secondaryEmail: res[0][19],
                secondaryPhone: res[0][20],
                householdNumber: res[0][21],
                countAdult: res[0][22],
                countChildren: res[0][23],
                headOfHousehold: res[0][24],
                veteran: res[0][25],
                currentMonthlyIncome: res[0][26],
                totalHouseholdAnnualIncome: res[0][27],
                qualifiedForUnemploymentBenefits: res[0][28],
                experiencedReductionInHouseholdIncome: res[0][29],
                incurredSignificantCosts: res[0][30],
                reductionOfChildSupport: res[0][31],
                otherFinancialHardship: res[0][32],
                isSomeoneOnRentalOrLeaseAgreement: res[0][33],

                landlordFullName: res[0][34],
                landlordEmail: res[0][35],
                landlordPhone: res[0][36],
                monthlyRentPayment: res[0][37],
                monthsBehindOnRent: res[0][38],
                amountBehindOnRent: res[0][39],

                receivedHousingSubsidy: res[0][40],
                subsidyExplanation: res[0][41],
                filedCovid19FinancialStressDeclaration: res[0][42],
                receivedEvictionNotice: res[0][42],
                dateEvictionNoticeReceived: (res[0][44] === '1000-01-01') ? '0000-00-00' : res[0][44],

                createdDate: res[0][45],
                createdTime: res[0][46],

                doYouLiveInIrvine: res[0][47],
                doYouRent: res[0][48],
                areYouBehindOnRent: res[0][49],
                doYouHoldSection8HousingVoucher: res[0][50],
                experiencedFinancialHardshipInCovid: res[0][51],
                isAnnualIncomeLessThanMaxLimit: res[0][52],
                belowThirtyPercAMI: res[0][53],
                thirtyToFiftyPercAMI: res[0][54],
                fiftyToEightyPercAMI: res[0][55],

                individualID: res[0][56],
                agent: res[0][57],
                individualCase: res[0][58],
                rentPaymentStatus: res[0][59],
                rentPaymentStatus_Original: res[0][59],
                dontKnowDateOfBirth: res[0][60],
                ipAddress: res[0][61],

                age: res[0][62],
                applicationAccepted: res[0][63],
                householdApprovedForUnitedWay: res[0][64],
                programTitle: res[0][65],


                // Other Details Section	
                uniqueID: res[0][66],
                SSN: res[0][67],
                socialSecurityFirstName: res[0][68],
                socialSecurityLastName: res[0][69],

                lengthOfTimeForAward: null, // (Calculated based on Date Applied and Date on which the rent payment status updated to Issued)

                householdID: res[0][70],
                transactionID: res[0][71],
                birthdayCode: res[0][72],
                otherPhone: res[0][73],
                spaAssignmentNo: res[0][74],
                spaServiceProviderEmail: res[0][75],
                partnerFirstName: res[0][76],
                partnerLastName: res[0][77],
                openToFollowup: res[0][78],
                noOfTimesParticipated: res[0][79],
                boardDistrict: res[0][80],
                legallyAbleToWork: res[0][81],
                workIndustry: res[0][82],
                industry_other: res[0][83],
                eligibleForStimulusFunds: res[0][84],
                employmentStatus: res[0][85],

                effectOfCovid19Financially: res[0][86],
                covid_other: res[0][87],


                // Landlord Details Section	
                rentAmountCovered: res[0][88],
                percentageOfMonthlyRent: res[0][89],
                landlordBankName: res[0][90],
                landlordAccountNumber: res[0][91],
                landlordRoutingNumber: res[0][92],
                landlordSSN: res[0][93],
                landlordTaxID: res[0][94],
                landlordDUNSNumber: res[0][95],
                memoPropertyAddress: res[0][96],
                rentTypeOfAssistance: res[0][97],
                outstandingRentalArrearsAmount: res[0][98],
                //--rentPaymentStatus: null
                landlordFirstAttempt: res[0][99],
                reasonPaymentNotProcessedIn21Days: res[0][100],
                landlordACHReceived: res[0][101],
                dateRentPaymentStatusUpdated: (res[0][102] === '1000-01-01') ? '0000-00-00' : res[0][102],
                timeRentPaymentStatusUpdated: res[0][103],

                dateEmailTriggered: (res[0][104] === '1000-01-01') ? '0000-00-00' : res[0][104],
                timeEmailTriggered: res[0][105],


                // Electric Details Section	
                electricProvider: res[0][106],
                electricUtilityAccountNo: res[0][107],
                custElectricBillingAccountNo: res[0][108],
                monthlyElectricBillAmount: res[0][109],
                noOfMonthsElectricUtilityPayments: res[0][110],
                electricTypeOfAssistance: res[0][111],
                amountDueforElectricity: res[0][112],
                electricAmount: res[0][113],
                percentageOfElectricBill: res[0][114],
                electricBankName: res[0][115],
                electricUtilityProviderAccountNo: res[0][116],
                electricRoutingNumber: res[0][117],
                electricMemoAccountNo: res[0][118],
                electricUtilityTaxID: res[0][119],
                electricUtilityDUNSNumber: res[0][120],
                electricPaymentStatus: res[0][121],
                electricPaymentStatus_Original: res[0][121],
                dateElectricPaymentStatusUpdated: (res[0][122] === '1000-01-01') ? '0000-00-00' : res[0][122],
                timeElectricPaymentStatusUpdated: res[0][123],


                // Gas Details Section	
                gasProvider: res[0][124],
                gasUtilityAccountNo: res[0][125],
                custGasBillingAccountNo: res[0][126],
                monthlyGasBillAmount: res[0][127],
                noOfMonthsGasUtilityPayments: res[0][128],
                gasTypeOfAssistance: res[0][129],
                amountDueforGas: res[0][130],
                gasAmount: res[0][131],
                percentageOfGasBill: res[0][132],
                gasBankName: res[0][133],
                gasUtilityProviderAccountNo: res[0][134],
                gasRoutingNumber: res[0][135],
                gasMemoAccountNo: res[0][136],
                gasUtilityTaxID: res[0][137],
                gasUtilityDUNSNumber: res[0][138],
                gasPaymentStatus: res[0][139],
                gasPaymentStatus_Original: res[0][139],
                dateGasPaymentStatusUpdated: (res[0][140] === '1000-01-01') ? '0000-00-00' : res[0][140],
                timeGasPaymentStatusUpdated: res[0][141],


                // Water/Sewer Details Section	
                waterProvider: res[0][142],
                waterUtilityAccountNo: res[0][143],
                custWaterBillingAccountNo: res[0][144],
                monthlyWaterBillAmount: res[0][145],
                noOfMonthsWaterUtilityPayments: res[0][146],
                waterTypeOfAssistance: res[0][147],
                amountDueforWater: res[0][148],
                waterAmount: res[0][149],
                percentageOfWaterBill: res[0][150],
                waterBankName: res[0][151],
                waterUtilityProviderAccountNo: res[0][152],
                waterRoutingNumber: res[0][153],
                waterMemoAccountNo: res[0][154],
                waterUtilityTaxID: res[0][155],
                waterUtilityDUNSNumber: res[0][156],
                waterPaymentStatus: res[0][157],
                waterPaymentStatus_Original: res[0][157],
                dateWaterPaymentStatusUpdated: (res[0][158] === '1000-01-01') ? '0000-00-00' : res[0][158],
                timeWaterPaymentStatusUpdated: res[0][159],


                // Trash Details Section	
                trashProvider: res[0][160],
                trashUtilityAccountNo: res[0][161],
                custTrashBillingAccountNo: res[0][162],
                monthlyTrashBillAmount: res[0][163],
                noOfMonthsTrashUtilityPayments: res[0][164],
                trashTypeOfAssistance: res[0][165],
                amountDueforTrash: res[0][166],
                trashAmount: res[0][167],
                percentageOfTrashBill: res[0][168],
                trashBankName: res[0][169],
                trashUtilityProviderAccountNo: res[0][170],
                trashRoutingNumber: res[0][171],
                trashMemoAccountNo: res[0][172],
                trashUtilityTaxID: res[0][173],
                trashUtilityDUNSNumber: res[0][174],
                trashPaymentStatus: res[0][175],
                trashPaymentStatus_Original: res[0][175],
                dateTrashPaymentStatusUpdated: (res[0][176] === '1000-01-01') ? '0000-00-00' : res[0][176],
                timeTrashPaymentStatusUpdated: res[0][177],


                // Energy Details Section	
                energyProvider: res[0][178],
                energyUtilityAccountNo: res[0][179],
                custEnergyBillingAccountNo: res[0][180],
                monthlyEnergyBillAmount: res[0][181],
                noOfMonthsEnergyUtilityPayments: res[0][182],
                energyTypeOfAssistance: res[0][183],
                amountDueforEnergy: res[0][184],
                energyAmount: res[0][185],
                percentageOfEnergyBill: res[0][186],
                energyBankName: res[0][187],
                energyUtilityProviderAccountNo: res[0][188],
                energyRoutingNumber: res[0][189],
                energyMemoAccountNo: res[0][190],
                energyUtilityTaxID: res[0][191],
                energyUtilityDUNSNumber: res[0][192],
                energyPaymentStatus: res[0][193],
                energyPaymentStatus_Original: res[0][193],
                dateEnergyPaymentStatusUpdated: (res[0][194] === '1000-01-01') ? '0000-00-00' : res[0][194],
                timeEnergyPaymentStatusUpdated: res[0][195],


                // Individual Payments Details Section	
                individualPaymentReason: res[0][196],
                individualPaymentAmount: res[0][197],
                individualMemoPropertyAddress: res[0][198],
                individualBankName: res[0][199],
                individualAccountNumber: res[0][200],
                individualRoutingNumber: res[0][201],
                individualPaymentStatus: res[0][202],
                individualPaymentStatus_Original: res[0][202],
                individualACHReceived: res[0][203],
                dateIndividualPaymentStatusUpdated: (res[0][204] === '1000-01-01') ? '0000-00-00' : res[0][204],
                timeIndividualPaymentStatusUpdated: res[0][205],

                // Other Payments Details Section	
                otherPayeeName: res[0][206],
                otherDesription: res[0][207],
                otherPaymentAmount: res[0][208],
                otherMemoPropertyAddress: res[0][209],
                otherBankName: res[0][210],
                otherAccountNumber: res[0][211],
                otherRoutingNumber: res[0][212],
                otherPaymentStatus: res[0][213],
                otherPaymentStatus_Original: res[0][213],
                dateOtherPaymentStatusUpdated: (res[0][214] === '1000-01-01') ? '0000-00-00' : res[0][214],
                timeOtherPaymentStatusUpdated: res[0][215],

                // Note Section	
                notes: res[0][216],


                dateClaimModified: (res[0][217] === '1000-01-01') ? '0000-00-00' : res[0][217],
                timeClaimModified: res[0][218],
                modifiedBy: res[0][219],
                createdBy: res[0][220],

                requestedLandlordInfo: res[0][221],
                dateLandlordInfoRequested: (res[0][222] === '1000-01-01') ? '0000-00-00' : res[0][222],
                timeLandlordInfoRequested: res[0][223],
    
            });


             // Load the checkboxes with their values
             if (res[0][28] === 1 || res[0][28] === "1" || res[0][28] === true) { // qualifiedForUnemploymentBenefits
                document.getElementById("qualifiedForUnemploymentBenefitsId").checked = true;
            }
            if (res[0][29] === 1 || res[0][29] === "1" || res[0][29] === true) { // experiencedReductionInHouseholdIncome
                document.getElementById("experiencedReductionInHouseholdIncomeId").checked = true;
            }
            if (res[0][30] === 1 || res[0][30] === "1" || res[0][30] === true) { // incurredSignificantCosts
                document.getElementById("incurredSignificantCostsId").checked = true;
            }
            if (res[0][31] === 1 || res[0][31] === "1" || res[0][31] === true) { // reductionOfChildSupport
                document.getElementById("reductionOfChildSupportId").checked = true;
            }
            if (res[0][32] === 1 || res[0][32] === "1" || res[0][32] === true) { // otherFinancialHardship
                document.getElementById("otherFinancialHardshipId").checked = true;
            }
            //console.log(this.state.veteran);
        }
        catch (err) {
            console.log(err);
            console.log("ERROR: Failed to load the individual info.");
            return;
        }
    }

    resetValues = () => {
        // Reset to initial state
        this.setState(this.initialState);
    }

    handleBack = () => {
        this.setState({ isLoading: false, disqualified: true });
         window.location.assign("/");
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    closePopup = () => {
        if (this.state.userRegisteredSuccessfully === true) {
            window.location.reload();
        }
        this.setState({ isLoading: false, showPopup: false, userRegisteredSuccessfully: false });
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstName: name, firstName_Error: "" });
    }
    updateMiddleName = (evt) => {
        var name = evt.target.value;
        this.setState({ middleName: name, middleName_Error: "" });
    }
    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastName: name, lastName_Error: "" });
    }
    updateSuffix = (evt) => {
        var name = evt.target.value;
        this.setState({ suffix: name, suffix_Error: "" });
    }
    updateAddressLine1 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine1: name, addressLine1_Error: "" });
    }
    updateAddressLine2 = (evt) => {
        var name = evt.target.value;
        this.setState({ addressLine2: name, addressLine2_Error: "" });
    }
    updateCity = (evt) => {
        var name = evt.target.value;
        this.setState({ city: name, city_Error: "" });
    }
    updateState = (evt) => {
        var name = evt.target.value;
        this.setState({ state: name, state_Error: "" });
    }
    updateZipcode = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ zipcode: name, zipcode_Error: "" });
    }
    updatePhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ phone: name, phone_Error: "" });
    }
    updatePhoneType = (evt) => {
        var name = evt.target.value;
        this.setState({ phoneType: name, phoneType_Error: "" });
    }
    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name, email_Error: "" });
    }
    updateGender = (evt) => {
        var name = evt.target.value;
        this.setState({ gender: name, gender_Error: "" });
    }
    updateRace = (evt) => {
        var name = evt.target.value;
        this.setState({ race: name, race_Error: "" });
    }
    updateEthnicity = (evt) => {
        var name = evt.target.value;
        this.setState({ ethnicity: name, ethnicity_Error: "" });
    }
    updateDateOfBirth = (evt) => {
        var name = evt.target.value;
        this.setState({ dateOfBirth: name, dateOfBirth_Error: "" });
    }
    updateDontKnowDateOfBirth = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ dontKnowDateOfBirth: true, dateOfBirth_Error: "" });
            document.getElementById("dateOfBirthId").readOnly = true;
        }
        else {
            this.setState({ dontKnowDateOfBirth: false, dateOfBirth_Error: "" });
            document.getElementById("dateOfBirthId").readOnly = false;
        }
    }
    updateInterpretationServicesRequiredForLanguage = (evt) => {
        var name = evt.target.value;
        this.setState({ interpretationServicesRequiredForLanguage: name, interpretationServicesRequiredForLanguage_Error: "" });
    }
    updateSecondaryFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryFirstName: name, secondaryFirstName_Error: "" });
    }
    updateSecondaryLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryLastName: name, secondaryLastName_Error: "" });
    }
    updateSecondaryEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ secondaryEmail: name, secondaryEmail_Error: "" });
    }
    updateSecondaryPhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ secondaryPhone: name, secondaryPhone_Error: "" });
    }
    updateHouseholdNumber = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ householdNumber: name, householdNumber_Error: "" });
    }
    updateCountAdult = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countAdult: name, countAdult_Error: "" });
    }
    updateCountChildren = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ countChildren: name, countChildren_Error: "" });
    }
    updateHeadOfHousehold = (evt) => {
        var name = evt.target.value;
        this.setState({ headOfHousehold: name, headOfHousehold_Error: "" });
    }
    updateVeteranYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ veteran: true, veteran_Error: "" });
        }
        else {
            this.setState({ veteran: false, veteran_Error: "" });
        }
    }
    updateVeteranNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ veteran: false, veteran_Error: "" });
        }
        else {
            this.setState({ veteran: true, veteran_Error: "" });
        }
    }
    updateCurrentMonthlyIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ currentMonthlyIncome: name, currentMonthlyIncome_Error: "" });
    }
    updateTotalHouseholdAnnualIncome = (evt) => {
        var name = evt.target.value;
        this.setState({ totalHouseholdAnnualIncome: name, totalHouseholdAnnualIncome_Error: "" });
    }
    //updateImpactOfCovid19 = (evt) => {
    //}
    updateQualifiedForUnemploymentBenefits = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ qualifiedForUnemploymentBenefits: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ qualifiedForUnemploymentBenefits: false, impactOfCovid19_Error: "" });
        }
    }
    updateExperiencedReductionInHouseholdIncome = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ experiencedReductionInHouseholdIncome: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ experiencedReductionInHouseholdIncome: false, impactOfCovid19_Error: "" });
        }
    }
    updateIncurredSignificantCosts = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ incurredSignificantCosts: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ incurredSignificantCosts: false, impactOfCovid19_Error: "" });
        }
    }
    updateReductionOfChildSupport = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ reductionOfChildSupport: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ reductionOfChildSupport: false, impactOfCovid19_Error: "" });
        }
    }
    updateOtherFinancialHardship = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ otherFinancialHardship: true, impactOfCovid19_Error: "" });
        }
        else {
            this.setState({ otherFinancialHardship: false, impactOfCovid19_Error: "" });
        }
    }
    updateIsSomeoneOnRentalOrLeaseAgreementYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: true, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: false, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
    }
    updateIsSomeoneOnRentalOrLeaseAgreementNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: false, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
        else {
            this.setState({ isSomeoneOnRentalOrLeaseAgreement: true, isSomeoneOnRentalOrLeaseAgreement_Error: "" });
        }
    }
    updateLandlordFullName = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordFullName: name, landlordFullName_Error: "" });
    }
    updateLandlordEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ landlordEmail: name, landlordEmail_Error: "" });
    }
    updateLandlordPhone = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ landlordPhone: name, landlordPhone_Error: "" });
    }
    updateMonthlyRentPayment = (evt) => {
        var name = evt.target.value;
        this.setState({ monthlyRentPayment: name, monthlyRentPayment_Error: "" });
    }
    updateMonthsBehindOnRent = (evt) => {
        var name = evt.target.value;
        name = name.replace(/\D/g, '');
        this.setState({ monthsBehindOnRent: name, monthsBehindOnRent_Error: "" });
    }
    updateAmountBehindOnRent = (evt) => {
        var name = evt.target.value;
        this.setState({ amountBehindOnRent: name, amountBehindOnRent_Error: "" });
    }
    updateReceivedHousingSubsidyYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedHousingSubsidy: true, receivedHousingSubsidy_Error: "" });
        }
        else {
            this.setState({ receivedHousingSubsidy: false, receivedHousingSubsidy_Error: "" });
        }
    }
    updateReceivedHousingSubsidyNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedHousingSubsidy: false, receivedHousingSubsidy_Error: "" });
        }
        else {
            this.setState({ receivedHousingSubsidy: true, receivedHousingSubsidy_Error: "" });
        }
    }
    updateSubsidyExplanation = (evt) => {
        var name = evt.target.value;
        this.setState({ subsidyExplanation: name, subsidyExplanation_Error: "" });
    }
    updateFiledCovid19FinancialStressDeclarationYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ filedCovid19FinancialStressDeclaration: true, filedCovid19FinancialStressDeclaration_Error: "" });
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration: false, filedCovid19FinancialStressDeclaration_Error: "" });
        }
    }
    updateFiledCovid19FinancialStressDeclarationNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ filedCovid19FinancialStressDeclaration: false, filedCovid19FinancialStressDeclaration_Error: "" });
        }
        else {
            this.setState({ filedCovid19FinancialStressDeclaration: true, filedCovid19FinancialStressDeclaration_Error: "" });
        }
    }
    updateReceivedEvictionNoticeYes = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedEvictionNotice: true, receivedEvictionNotice_Error: "" });
        }
        else {
            this.setState({ receivedEvictionNotice: false, receivedEvictionNotice_Error: "" });
        }
    }
    updateReceivedEvictionNoticeNo = (evt) => {
        if (evt.target.checked === true) {
            this.setState({ receivedEvictionNotice: false, receivedEvictionNotice_Error: "" });
        }
        else {
            this.setState({ receivedEvictionNotice: true, receivedEvictionNotice_Error: "" });
        }
    }
    updateDateEvictionNoticeReceived = (evt) => {
        var name = evt.target.value;
        this.setState({ dateEvictionNoticeReceived: name, dateEvictionNoticeReceived_Error: "" });
    }

    googleTranslateElementInit() {
        new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
    }

    render() {
        return (
            <div className="d-flex flex-column sticky-footer-wrapper">

                {/* header starts */}
                <header>
                    <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                        <a className="navbar-brand" href="#">
                            <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                        </a>
                        <div className="ml-auto d-flex mt-3 mt-sm-0">
                            <GoogleTranslate />
                            <button className="btn btn-light d-inline-block mr-3 ml-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                            <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                        </div>
                    </nav>
                </header>
                {/* header ends */}

                {/* main content starts */}
                <main className="main flex-fill">
                    <div className="container-fluid">
                        <div className="details-page">
                            {/* content area starts */}
                            <div className="box-shadow p-3 mb-3">
                                <h4 className="text-primary">City of Irvine, Emergency Rental Assistance Program (ERAP)</h4>
                                <p className="wh-note erap-para"><span>Note: </span><b> To request a modification, please do so in writing by emailing <a href="mailto:ERAIrvine@unitedwayoc.org">ERAIrvine@unitedwayoc.org</a></b></p>
                                <hr />
                                
                                <div>
                                    <div className="record-detail-main validation-text">
                                        <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none"><i class="fas fa-edit"></i> EDIT ERA-OC APPLICATION</h4>
                                        <div className="data-section">
                                            <div className="">
                                                <React.Fragment>
                                                    <fieldset disabled>
                                                    <div className="box-gray mb-4 py-3">
                                                        <div className="box-details">
                                                            <div className="form-row">
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>First Name <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.firstNameRef} onChange={this.updateFirstName} value={this.state.firstName} maxlength="100"></input>
                                                                        <div className="alert-small-text">{this.state.firstName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>Middle Name </label>
                                                                        <input className="form-control" type="text" ref={this.middleNameRef} onChange={this.updateMiddleName} value={this.state.middleName} maxlength="100"></input>
                                                                        <div className="alert-small-text">{this.state.middleName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div class="form-group">
                                                                        <label>Last Name <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.lastNameRef} onChange={this.updateLastName} value={this.state.lastName} maxlength="100"></input>
                                                                        <div class="alert-small-text">{this.state.lastName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label>Suffix </label>
                                                                        <input className="form-control" type="text" ref={this.suffixRef} onChange={this.updateSuffix} maxlength="10" value={this.state.suffix}></input>
                                                                        <div className="alert-small-text">{this.state.suffix_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label>Address Line 1 <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.addressLine1Ref} onChange={this.updateAddressLine1} maxlength="255" value={this.state.addressLine1}></input>
                                                                        <div className="alert-small-text">{this.state.addressLine1_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="form-group">
                                                                        <label>Address Line 2 </label>
                                                                        <input className="form-control" type="text" ref={this.addressLine2Ref} onChange={this.updateAddressLine2} maxlength="255" value={this.state.addressLine2}></input>
                                                                        <div class="alert-small-text">{this.state.addressLine2_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.cityRef} disabled onChange={this.updateCity} maxlength="30" value={this.state.city}></input>
                                                                        <div class="alert-small-text">{this.state.city_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>State <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.stateRef} onChange={this.updateState} value={this.state.state}>
                                                                            <option value="selectstate">-- select --</option>
                                                                            <option value="AL">AL</option>
                                                                            <option value="AK">AK</option>
                                                                            <option value="AR">AR</option>
                                                                            <option value="AZ">AZ</option>
                                                                            <option value="CA">CA</option>
                                                                            <option value="CO">CO</option>
                                                                            <option value="CT">CT</option>
                                                                            <option value="DC">DC</option>
                                                                            <option value="DE">DE</option>
                                                                            <option value="FL">FL</option>
                                                                            <option value="GA">GA</option>
                                                                            <option value="HI">HI</option>
                                                                            <option value="IA">IA</option>
                                                                            <option value="ID">ID</option>
                                                                            <option value="IL">IL</option>
                                                                            <option value="IN">IN</option>
                                                                            <option value="KS">KS</option>
                                                                            <option value="KY">KY</option>
                                                                            <option value="LA">LA</option>
                                                                            <option value="MA">MA</option>
                                                                            <option value="MD">MD</option>
                                                                            <option value="ME">ME</option>
                                                                            <option value="MI">MI</option>
                                                                            <option value="MN">MN</option>
                                                                            <option value="MO">MO</option>
                                                                            <option value="MS">MS</option>
                                                                            <option value="MT">MT</option>
                                                                            <option value="NC">NC</option>
                                                                            <option value="NE">NE</option>
                                                                            <option value="NH">NH</option>
                                                                            <option value="NJ">NJ</option>
                                                                            <option value="NM">NM</option>
                                                                            <option value="NV">NV</option>
                                                                            <option value="NY">NY</option>
                                                                            <option value="ND">ND</option>
                                                                            <option value="OH">OH</option>
                                                                            <option value="OK">OK</option>
                                                                            <option value="OR">OR</option>
                                                                            <option value="PA">PA</option>
                                                                            <option value="RI">RI</option>
                                                                            <option value="SC">SC</option>
                                                                            <option value="SD">SD</option>
                                                                            <option value="TN">TN</option>
                                                                            <option value="TX">TX</option>
                                                                            <option value="UT">UT</option>
                                                                            <option value="VT">VT</option>
                                                                            <option value="VA">VA</option>
                                                                            <option value="WA">WA</option>
                                                                            <option value="WI">WI</option>
                                                                            <option value="WV">WV</option>
                                                                            <option value="WY">WY</option>
                                                                        </select>

                                                                        <div class="alert-small-text">{this.state.state_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Zip Code <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.zipcodeRef} onChange={this.updateZipcode} maxlength="5" value={this.state.zipcode}></input>
                                                                        <div class="alert-small-text">{this.state.zipcode_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Phone <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.phoneRef} onChange={this.updatePhone} maxlength="10" value={this.state.phone}></input>
                                                                        <div class="alert-small-text">{this.state.phone_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Phone Type <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.phoneTypeRef} onChange={this.updatePhoneType} value={this.state.phoneType}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Mobile">Mobile</option>
                                                                            <option value="Home">Home</option>
                                                                            <option value="Work">Work</option>
                                                                            <option value="Other">Other</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.phoneType_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Email <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.emailRef} onChange={this.updateEmail} value={this.state.email} maxlength="255" ></input>
                                                                        <div class="alert-small-text">{this.state.email_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>Gender <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.genderRef} onChange={this.updateGender} value={this.state.gender}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Female">Female</option>
                                                                            <option value="Male">Male</option>
                                                                            <option value="Trans Female (MTF or Male to Female)">Trans Female (MTF or Male to Female)</option>
                                                                            <option value="Trans Male (FTM or Female to Male)">Trans Male (FTM or Female to Male)</option>
                                                                            <option value="Gender Non-Conforming">Gender Non-Conforming</option>
                                                                            <option value="Do not know">Do not know</option>
                                                                            <option value="Refuse to answer">Refuse to answer</option>
                                                                        </select>
                                                                        <div className="alert-small-text">{this.state.gender_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Race <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.raceRef} onChange={this.updateRace} value={this.state.race}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="White (Hispanic or Latino)">White (Hispanic or Latino)</option>
                                                                            <option value="White (Non-Hispanic or Non-Latino)">White (Non-Hispanic or Non-Latino)</option>
                                                                            <option value="Black or African American">Black or African American</option>
                                                                            <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
                                                                            <option value="Middle Eastern and North African">Middle Eastern and North African</option>
                                                                            <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
                                                                            <option value="Asian">Asian</option>
                                                                            <option value="Other">Other</option>
                                                                            <option value="Do not know">Do not know</option>
                                                                            <option value="Refuse to answer">Refuse to answer</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.race_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Ethnicity <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.ethnicityRef} onChange={this.updateEthnicity} value={this.state.ethnicity}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Non-Hispanic or Latino">Non-Hispanic or Latino</option>
                                                                            <option value="Hispanic or Latino">Hispanic or Latino</option>
                                                                            <option value="Do not know">Do not know</option>
                                                                            <option value="Refuse to answer">Refuse to answer</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.ethnicity_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Date of Birth <i class="fas fa-asterisk asterisk mr-3"></i>
                                                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                                                <input type="checkbox" class="custom-control-input" checked = {this.state.dontKnowDateOfBirth === 1 ? true : false} onChange={this.updateDontKnowDateOfBirth} id="dontKnowDateOfBirthId" />
                                                                                <label class="custom-control-label" for="dontKnowDateOfBirthId">I don't know</label>
                                                                            </div>
                                                                        </label>
                                                                        <input className="form-control" type="date" ref={this.dateOfBirthRef} onChange={this.updateDateOfBirth} value={this.state.dateOfBirth} id="dateOfBirthId"></input>
                                                                        <div className="alert-small-text">{this.state.dateOfBirth_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Are interpretation services required in one of the following languages?</label>
                                                                        <select className="form-control" onChange={this.updateInterpretationServicesRequiredForLanguage} value={this.state.interpretationServicesRequiredForLanguage}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Spanish">Spanish</option>
                                                                            <option value="Farsi">Farsi</option>
                                                                            <option value="Mandarin">Mandarin</option>
                                                                            <option value="Vietnamese">Vietnamese</option>
                                                                            <option value="Korean">Korean</option>
                                                                            <option value="None">None</option>
                                                                        </select>
                                                                        <div class="alert-small-text">{this.state.interpretationServicesRequiredForLanguage_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <div className="form-group">
                                                                        <label>Secondary Contact First Name </label>
                                                                        <input className="form-control" type="text" ref={this.secondaryFirstNameRef} onChange={this.updateSecondaryFirstName} value={this.state.secondaryFirstName} maxlength="100"></input>
                                                                        <div className="alert-small-text">{this.state.secondaryFirstName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <div class="form-group">
                                                                        <label>Secondary Contact Last Name </label>
                                                                        <input className="form-control" type="text" ref={this.secondaryLastNameRef} onChange={this.updateSecondaryLastName} value={this.state.secondaryLastName} maxlength="100"></input>
                                                                        <div class="alert-small-text">{this.state.secondaryLastName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <div class="form-group">
                                                                        <label>Secondary Contact Email</label>
                                                                        <input className="form-control" type="text" ref={this.secondaryEmailRef} onChange={this.updateSecondaryEmail} value={this.state.secondaryEmail} maxlength="255" ></input>
                                                                        <div class="alert-small-text">{this.state.secondaryEmail_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-lg-3">
                                                                    <div class="form-group">
                                                                        <label>Secondary Contact Phone </label>
                                                                        <input className="form-control" type="text" ref={this.secondaryPhoneRef} onChange={this.updateSecondaryPhone} value={this.state.secondaryPhone} maxlength="10" ></input>
                                                                        <div class="alert-small-text">{this.state.secondaryPhone_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4">
                                                                    <div class="form-group">
                                                                        <label>How many total people live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="number" name="household" ref={this.householdNumberRef} onChange={this.updateHouseholdNumber} value={this.state.householdNumber} />
                                                                        <div class="alert-small-text">{this.state.householdNumber_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 col-xl-4">
                                                                    <div class="form-group">
                                                                        <label>How many adults live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="number" name="household" ref={this.countAdultRef} onChange={this.updateCountAdult} value={this.state.countAdult} />
                                                                        <div class="alert-small-text">{this.state.countAdult_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-xl-4">
                                                                    <div class="form-group">
                                                                        <label>How many children age 0-17 live in your household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="number" name="household" ref={this.countChildrenRef} onChange={this.updateCountChildren} value={this.state.countChildren} />
                                                                        <div class="alert-small-text">{this.state.countChildren_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <label>Are you the Head of Household? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <select className="form-control" ref={this.headOfHouseholdRef} onChange={this.updateHeadOfHousehold} value={this.state.headOfHousehold}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                            <option value="Don't Know">Don't Know</option>
                                                                        </select>
                                                                        <div className="alert-small-text">{this.state.headOfHousehold_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <div><label>Are you or anyone in your household a U.S. Veteran? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateVeteranYes}  ref={this.veteranRef} checked = {this.state.veteran === 1 ? true : false} id="veteranYes" name="veteran" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="veteranYes">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateVeteranNo} id="veteranNo" checked = {this.state.veteran === 0 ? true : false}  name="veteran" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="veteranNo">No</label>
                                                                        </div>
                                                                        <div class="alert-small-text">{this.state.veteran_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="form-group">
                                                                        <label>What is your current monthly household income? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.currentMonthlyIncomeRef} onChange={this.updateCurrentMonthlyIncome} value={this.state.currentMonthlyIncome}></input>
                                                                        <div class="alert-small-text">{this.state.currentMonthlyIncome_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div class="form-group">
                                                                        <label>What is your total household annual income? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.totalHouseholdAnnualIncomeRef} onChange={this.updateTotalHouseholdAnnualIncome} value={this.state.totalHouseholdAnnualIncome}></input>
                                                                        <div class="alert-small-text">{this.state.totalHouseholdAnnualIncome_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div className="form-group">
                                                                        <div><label>Check all that apply to you and/or anyone in your household due to the impact of the COVID19 coronavirus pandemic <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                        <div className="form-row">
                                                                            <div className="col-md-6">
                                                                                <div class="custom-control custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateQualifiedForUnemploymentBenefits} id="qualifiedForUnemploymentBenefitsId" ref={this.impactOfCovid19Ref} />
                                                                                    <label class="custom-control-label" for="qualifiedForUnemploymentBenefitsId">Qualified for unemployment benefits</label>
                                                                                </div>
                                                                                <div class="custom-control custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateExperiencedReductionInHouseholdIncome} id="experiencedReductionInHouseholdIncomeId" />
                                                                                    <label class="custom-control-label" for="experiencedReductionInHouseholdIncomeId">Experienced a reduction in household income</label>
                                                                                </div>
                                                                                <div class="custom-control custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateIncurredSignificantCosts} id="incurredSignificantCostsId" />
                                                                                    <label class="custom-control-label" for="incurredSignificantCostsId">Incurred significant costs</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <div class="custom-control custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateReductionOfChildSupport} id="reductionOfChildSupportId" />
                                                                                    <label class="custom-control-label" for="reductionOfChildSupportId">Reduction/loss of child support</label>
                                                                                </div>
                                                                                <div class="custom-control custom-checkbox">
                                                                                    <input type="checkbox" class="custom-control-input" onChange={this.updateOtherFinancialHardship} id="otherFinancialHardshipId" />
                                                                                    <label class="custom-control-label" for="otherFinancialHardshipId">Other financial hardship(s)</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="alert-small-text">{this.state.impactOfCovid19_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <div><label>Is someone in your household listed on the rental or lease agreement? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateIsSomeoneOnRentalOrLeaseAgreementYes}  checked= {this.state.isSomeoneOnRentalOrLeaseAgreement === 1 ? true : false} ref={this.isSomeoneOnRentalOrLeaseAgreementRef} id="isSomeoneOnRentalOrLeaseAgreementYes" name="isSomeoneOnRentalOrLeaseAgreement" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="isSomeoneOnRentalOrLeaseAgreementYes">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateIsSomeoneOnRentalOrLeaseAgreementNo} checked= {this.state.isSomeoneOnRentalOrLeaseAgreement === 0 ? true : false} id="isSomeoneOnRentalOrLeaseAgreementNo" name="isSomeoneOnRentalOrLeaseAgreement" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="isSomeoneOnRentalOrLeaseAgreementNo">No</label>
                                                                        </div>
                                                                        <div class="alert-small-text">{this.state.isSomeoneOnRentalOrLeaseAgreement_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Landlord's full name <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.landlordFullNameRef} onChange={this.updateLandlordFullName} value={this.state.landlordFullName} maxlength="255"></input>
                                                                        <div class="alert-small-text">{this.state.landlordFullName_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Landlord email address <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.landlordEmailRef} onChange={this.updateLandlordEmail} value={this.state.landlordEmail} maxlength="255" ></input>
                                                                        <div class="alert-small-text">{this.state.landlordEmail_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Landlord phone number <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.landlordPhoneRef} onChange={this.updateLandlordPhone} value={this.state.landlordPhone} maxlength="10" ></input>
                                                                        <div class="alert-small-text">{this.state.landlordPhone_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>What is your monthly rent payment? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.monthlyRentPaymentRef} onChange={this.updateMonthlyRentPayment} value={this.state.monthlyRentPayment}></input>
                                                                        <div class="alert-small-text">{this.state.monthlyRentPayment_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>How many months are you behind on rent? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.monthsBehindOnRentRef} onChange={this.updateMonthsBehindOnRent} value={this.state.monthsBehindOnRent}></input>
                                                                        <div class="alert-small-text">{this.state.monthsBehindOnRent_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>What is the total dollar amount that you are behind on rent? <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.amountBehindOnRentRef} onChange={this.updateAmountBehindOnRent} value={this.state.amountBehindOnRent}></input>
                                                                        <div class="alert-small-text">{this.state.amountBehindOnRent_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <div><label>Since April 2020, have you received a housing subsidy? <i class="fas fa-asterisk asterisk"></i></label></div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateReceivedHousingSubsidyYes} checked = {this.state.receivedHousingSubsidy === 1 ? true : false} ref={this.receivedHousingSubsidyRef} id="receivedHousingSubsidyYes" name="receivedHousingSubsidy" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="receivedHousingSubsidyYes">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateReceivedHousingSubsidyNo} checked = {this.state.receivedHousingSubsidy === 0 ? true : false} id="receivedHousingSubsidyNo" name="receivedHousingSubsidy" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="receivedHousingSubsidyNo">No</label>
                                                                        </div>
                                                                        <div class="alert-small-text">{this.state.receivedHousingSubsidy_Error}</div>
                                                                    </div>
                                                                    {this.state.receivedHousingSubsidy === 1 ? (
                                                                    <div class="form-group">
                                                                        <label>If yes, please explain <i class="fas fa-asterisk asterisk"></i></label>
                                                                        <input className="form-control" type="text" ref={this.subsidyExplanationRef} onChange={this.updateSubsidyExplanation} maxlength="255" value={this.state.subsidyExplanation}></input>
                                                                        <div class="alert-small-text">{this.state.subsidyExplanation_Error}</div>
                                                                    </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <div><label>Have you filed a Declaration of COVID-19-related financial distress with your landlord?</label></div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateFiledCovid19FinancialStressDeclarationYes} checked = {this.state.filedCovid19FinancialStressDeclaration === 1 ? true : false} ref={this.filedCovid19FinancialStressDeclarationRef} id="filedCovid19FinancialStressDeclarationYes" name="filedCovid19FinancialStressDeclaration" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="filedCovid19FinancialStressDeclarationYes">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateFiledCovid19FinancialStressDeclarationNo} checked = {this.state.filedCovid19FinancialStressDeclaration === 0 ? true : false} id="filedCovid19FinancialStressDeclarationNo" name="filedCovid19FinancialStressDeclaration" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="filedCovid19FinancialStressDeclarationNo">No</label>
                                                                        </div>
                                                                        <div class="alert-small-text">{this.state.filedCovid19FinancialStressDeclaration_Error}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <div><label>Have you received an eviction notice?</label></div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateReceivedEvictionNoticeYes} checked = {this.state.receivedEvictionNotice === 1 ? true : false} ref={this.receivedEvictionNoticeRef} id="receivedEvictionNoticeYes" name="receivedEvictionNotice" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="receivedEvictionNoticeYes">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" onChange={this.updateReceivedEvictionNoticeNo} checked = {this.state.receivedEvictionNotice === 0 ? true : false} id="receivedEvictionNoticeNo" name="receivedEvictionNotice" class="custom-control-input" />
                                                                            <label class="custom-control-label" for="receivedEvictionNoticeNo">No</label>
                                                                        </div>
                                                                        <div class="alert-small-text">{this.state.receivedEvictionNotice_Error}</div>
                                                                    </div>
                                                                    {this.state.receivedEvictionNotice === 1 ? (
                                                                    <div className="form-group">
                                                                        <label>If yes, what is the Eviction Notice Date</label>
                                                                        <input className="form-control" type="date" ref={this.dateEvictionNoticeReceivedRef} onChange={this.updateDateEvictionNoticeReceived} value={this.state.dateEvictionNoticeReceived}></input>
                                                                        <div className="alert-small-text">{this.state.dateEvictionNoticeReceived_Error}</div>
                                                                    </div>
                                                                     ) : null}
                                                                </div>
                                                                {/* <div className="col-md-12">
                                                                    <div class="form-group">
                                                                        <label>Upload a copy of your rental or lease agreement.</label>
                                                                        <div>
                                                                            <div class="file-upload mt-2">
                                                                                <input id="upload" ref={ref => (this.rentalAgreementUpload = ref)} type="file" name="file-upload" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                            {/* <div className="text-right">
                                                                <button class="btn btn-primary mb-3 mb-sm-0 mr-3" onClick={this.submitApplication}><i class="fas fa-user-check mr-2"></i> Submit</button>
                                                            </div> */}

                                                        </div>
                                                    </div>
                                                    </fieldset>
                                                    <Dialog
                                                        open={this.state.showPopup}
                                                        onClose={this.closePopup}
                                                        aria-labelledby="alert-dialog-title"
                                                        aria-describedby="alert-dialog-description"
                                                    >
                                                        <DialogTitle id="alert-dialog-title">{"Register User"}</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText id="alert-dialog-description">
                                                                {this.state.dialogText}
                                                            </DialogContentText>
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={this.closePopup} color="primary" autoFocus>
                                                                OK
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>

                                                    <Backdrop className="MuiBackdrop-root irvine-loader" open={this.state.isLoading} >
                                                        <CircularProgress />
                                                    </Backdrop>
                                                </React.Fragment>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* content area ends */}
                    </div>
                </main>
                {/* main content ends */}

                {/* footer starts */}
                <footer className="foot bg-primary text-white text-center p-2">
                    <p className="mb-1">Powered by Orange County United Way</p>
                </footer>
                {/* footer ends */}

            </div>
        );
    }
}

export default UserDetails;
