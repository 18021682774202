import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import MUIDataTable from "mui-datatables";
import { API, Auth, Storage } from 'aws-amplify';
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import imgLogo from './imgs/logo-assist-oc.png';
import Tooltip from '@material-ui/core/Tooltip';
import { NavLink } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';

const headers = [
    "",    // 1st column header
    "SA.individualID",
    "fundingSource",
    "Name",
    "addressLine1",
    "addressLine2",
    "dateLandlordInfoRequested",
    "landlordInfoRequestedStatus"
];

class ApplicantGrid extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            columns: [
                {
                    name: "loadClaim",  // 1st column
                    label: "Action",  // 1st column
                    options: {
                        download: false,
                        display: true,
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <NavLink to={'/santaana/managedocuments/' + value + "/M"}>
                                    <Tooltip title={'Edit'} arrow placement="left">
                                        <div style={{
                                            width: "15px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            //textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            <i class="fas fa-edit text-primary"></i>
                                        </div>
                                    </Tooltip>
                                </NavLink>
                            );
                        },
                    },
                },
                {
                    name: "individualID",
                    label: "Individual ID",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "60px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "60px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "fundingSource",
                    label: "Funding Source",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "60px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "60px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Name",
                    label: "Landlord Name",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "170px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "170px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "addressLine1",
                    label: "Address1",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "addressLine2",
                    label: "Address2",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "130px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "130px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                //  {
                //   name: "createdDate",
                //   label: "Date Applied",
                //   options: {
                //     download: true,
                //     display: true,
                //      filter: false,
                //     sort: false,
                //     customBodyRender: (value, tableMeta, updateValue) => {
                //       if (value && value.length > 15) {
                //         return (
                //           <Tooltip title={value} arrow placement="left">
                //             <div style={{
                //               width: "130px",
                //               overflow: "hidden",
                //               whiteSpace: 'nowrap',
                //               textOverflow: 'ellipsis',
                //               position: 'relative',
                //             }}>
                //               {value}
                //             </div>
                //           </Tooltip>
                //         );
                //       }
                //       else
                //         return (
                //           <div style={{
                //             width: "130px",
                //             overflow: "hidden",
                //             whiteSpace: 'nowrap',
                //             textOverflow: 'ellipsis',
                //             position: 'relative',
                //           }}>
                //             {value}
                //           </div>
                //         );
                //     },
                //   },
                // },
                {
                    name: "dateLandlordInfoRequested",
                    label: "Requested Date",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "130px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "130px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "landlordInfoRequestedStatus",
                    label: "Status",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        sort: false,
                        //filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let readyClass = "";
                            if (value == "Ready") {
                                readyClass = "pstatus";
                            }
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div class={readyClass} style={{
                                            width: "130px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div class={readyClass} style={{
                                        width: "130px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
            ],
            hideCompleted: 1,
            landloardEmail: "",
            msg: "",
            email: null,
            showDetails: "none",
            showAlert: "none",
            dataheight: null,
            notes: "",
            files: null,
            file1: null,
            file2: null,
            cardValue: "500.00",
            file31: null,
            file32: null,
            file41: null,
            file42: null,
            rowSelected: [],
            data: [["Loading Data..."]],

            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'SA.individualID',                  // default sort by column dateUserCreated in desc order
                direction: 'asc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            selectedRowFirstName: '',
            selectedRowLastName: '',
            options: {
                viewColumns: false,
                download: false,
                downloadOptions: {
                    filename: 'tableDownload.csv',
                    separator: ',',
                    filterOptions: {
                        useDisplayedColumnsOnly: true,          // downloads only columns that are visible (e.g. after filtering)
                        useDisplayedRowsOnly: false              // downloads only rows that are visible (e.g. after filtering)
                    }                                         // default false - downloads all data
                },
                selectableRows: false,
                selectableRowsOnClick: false,
                filter: false,
                sort: false,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,
                pagination: false,
                search: false,

                setRowProps: row => {
                    if (row[6].props && row[7].props.class === 'pstatus') {
                        return {
                            style: { background: '#dffee6' }
                        };
                    }
                },
                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,

                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    //console.log('customFilterDialogFooter');
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },

                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    //console.log('onFilterConfirm');
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                    //console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    //console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    //console.log('onFilterChange');
                    //console.log("column: " + column);
                    //console.log("filterList: " + filterList);
                    //console.log("type: " + type);
                    this.setState({ filters: filterList, page: 0 });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        //console.log('updating filters via chip');
                        this.handleFilterSubmit(newFilters);
                    }
                },

                //pagination: true,
                count: 1,
                rowsPerPage: 50,
                rowsPerPageOptions: [10, 20, 50],
                sortOrder: {
                    name: 'dateImported',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },

                onTableChange: (action, tableState) => {

                    //console.log(action, tableState);

                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want

                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                        //console.log('action not handled - ' + action);
                    }
                },
                onViewColumnsChange: (column, action) => {
                    //console.log(column, action);
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                // onRowClick: (rowData, rowState) => {
                //   this.setState({ rowClickProcessed: false });
                //   console.log(rowData, rowState);

                //   if (rowData[10] !== null && rowData[10].props !== undefined) {
                //     if (rowData[10].props["title"] !== undefined) {
                //       this.setState({
                //         individualID: rowData[10].props["title"]
                //       });
                //     }
                //     else {
                //       this.setState({
                //         individualID: rowData[10].props["children"]
                //       });
                //     }
                //   }
                //   else {
                //     this.setState({
                //       individualID: rowData[10]
                //     });
                //   }

                //   console.log(rowData[10].props["children"]);
                //   console.log(this.state.individualID);
                //   this.setState({ rowClickProcessed: true });
                // },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                // customToolbar: () => {
                //   return (
                //     <CustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} downloadCallback={this.OnDownloadClick.bind(this)} hideCompletedCallback={this.OnHideCompletedClick.bind(this)} handleCaseClickCallback={this.OnCaseChangeClick.bind(this)} isceppClaims={true} />
                //   );
                // },
                searchText: '',
                // searchProps: {
                //   onBlur: (e) => {
                //     //console.log('onBlur!');
                //     this.OnSearchIdleTimeout(this.state.searchText);
                //   },
                //   onKeyUp: (e) => {
                //     //console.log('onKeyUp!');
                //     if (e.keyCode === 13) { // Enter pressed
                //       this.OnSearchIdleTimeout(this.state.searchText);
                //     }
                //   },
                // },
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    //console.log('onSearchClose - ' + this.state.searchTimerID);
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                // customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                //   return (
                //     <TableSearch
                //       searchText={searchText}
                //       onSearch={handleSearch}
                //       onHide={hideSearch}
                //       options={options}
                //       onSearchClick={this.OnSearchClick}
                //     />
                //   );
                // },

                individualID: 0,
                rowClickProcessed: false,
            }
        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    "& > .MuiPaper-elevation1": {
                        order: 999
                    }
                    // // target any icon
                    // "& > span:nth-child(4), & > button:nth-child(4)": {
                    //   order: 2
                    // }
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    //width:'200px'
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

    getData = async () => {
        this.setState({ isLoading: true });
        // var retrievedObject = localStorage.getItem('retainObject');

        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        // console.log(apiName);
        let path = '/getsantaanaapplicantgrid';
        let myInit = {};

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
            }
        }

        myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                    "case": this.state.case,
                    "email": this.state.landloardEmail
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    postData = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    componentDidMount = async () => {
        var user = await Auth.currentAuthenticatedUser();
        this.setState({ landloardEmail: user.attributes.email });
        // this.props.parentCallback("Search Claim IRVINE", "", imgSearchclaim);
        var height;
        if (isMobile) {
            height = "60vh";
        }
        else {
            height = "70vh"
        }
        var res = await this.getData();
        this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        //this.setState({
        //  data:res,
        //  dataheight:height
        //})
    }

    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getData().then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
                isLoading: false,
            });
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
            });
        });
    };

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                sortOrder: sortOrder,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    };

    delay = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    testLoad = async () => {
        this.setState({ isLoading: true });

        while (true) {
            var res = await this.delay();
            if (this.state.rowClickProcessed === true) {
                break;
            }
        }

        // console.log(this.state.individualID);
        this.setState({ isLoading: false, rowClickProcessed: false });

        // this.props.showRecordDetails(this.state.individualID);
    }

    close = () => {
        this.props.updateFunction(4);
        this.setState({ email: "" });
        this.closeDetails();
    }

    closeDetails = () => {
        document.body.classList.remove('modal-open');
        if (isMobile) {
            this.setState({ showDetails: "none", dataheight: "60vh", email: "", selectedRowFirstName: "", selectedRowLastName: "" });
        }
        else {
            this.setState({ showDetails: "none", email: "", selectedRowFirstName: "", selectedRowLastName: "" }); //,dataheight:"70vh"
        }
    }

    closeMsg = () => {
        this.setState({ showAlert: "none" });
    }

    OnSearchClick = async () => {
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        //console.log("Refresh!!!");
        var res = await this.getData();
        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        })
    }

    OnRefreshClick = async () => {
        //console.log("OnRefreshClick!!!");
        await this.refresh();
    }

    componentDidUpdate() {
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        //console.log('handleFilterSubmit');
        //console.log('filterList: ' + filterList);
        this.setState({ isLoading: true, filters: filterList, page: 0 });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    };

    OnSearchTextChange(searchQuery) {
        //console.log('OnSearchTextChange - ' + searchQuery);

        this.setState({ searchText: searchQuery });

        // if (searchQuery !== null && searchQuery !== 'undefined') {
        //   clearTimeout(this.state.searchTimerID);
        //   this.setState({ searchTimerID: setTimeout(this.OnSearchIdleTimeout, 2000, searchQuery) });
        // }
        // else {
        //   this.setState({ searchText: '' });
        // }
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            //console.log('OnSearchIdleTimeout - ' + searchQuery);

            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getData();

            //console.log('SearchResult');
            //console.log(res.data);

            this.setState({
                isLoading: false,
                //handleSearch: 0,
                //searchText: '',
                data: res.data,
                page: res.page,
                count: res.total,
            });
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);

        //console.log('buildCSV');
        //console.log(columns);

        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);

        // const csv = options.onDownload
        //   ? options.onDownload(buildHead, buildBody, columns, data)
        //   : `${CSVHead}${CSVBody}`.trim();

        const csv = `${CSVHead}${CSVBody}`.trim();

        //console.log('csv');
        //console.log(csv);
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnCaseChangeClick = async (e) => {
        this.setState({ case: e, page: 0 });
        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnDownloadClick = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
        });

        var res = await this.getData();
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });

        var file1 = await Storage.get(`ERA-IRVINE/CSVExport/${res.filename}`);
        // console.log(file1);

        let link = document.createElement('a');
        link.href = file1;
        link.download = file1;
        link.click();
        // let dataDictionary = [];
        // res.data.forEach((el, index) => dataDictionary.push({ index: index, data: el }));

        // let dataToDownload = cloneDeep(dataDictionary);
        // let columnsToDownload = columns;

        // if (options.downloadOptions && options.downloadOptions.filterOptions) {
        //   // check rows first:
        //   if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
        //     dataToDownload = displayData.map((row, index) => {
        //       let i = -1;

        //       // Help to preserve sort order in custom render columns
        //       row.index = index;

        //       return {
        //         data: row.data.map(column => {
        //           i += 1;

        //           // if we have a custom render, which will appear as a react element, we must grab the actual value from data
        //           // that matches the dataIndex and column
        //           // TODO: Create a utility function for checking whether or not something is a react object
        //           return typeof column === 'object' && column !== null && !Array.isArray(column)
        //             ? find(data, d => d.index === row.dataIndex).data[i]
        //             : column;
        //         }),
        //       };
        //     });
        //   }

        //   // now, check columns:
        //   if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
        //     columnsToDownload = columns.filter((_, index) => _.options.display === true);

        //     dataToDownload = dataToDownload.map(row => {
        //       row.data = row.data.filter((_, index) => columns[index].options.display === true);
        //       return row;
        //     });
        //   }
        // }
        // this.createCSVDownload(columnsToDownload, dataToDownload, options);
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e)
        }
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    handleBack = () => {
        window.location.assign("/");
    }

    render() {

        this.state.options.page = this.state.page;
        this.state.options.sortOrder = this.state.sortOrder;
        this.state.options.count = this.state.count;
        this.state.options.rowsPerPage = this.state.rowsPerPage;
        this.state.options.searchText = this.state.searchText;

        return (
            <div className="d-flex flex-column sticky-footer-wrapper">
                <header>
                    <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                        <a className="navbar-brand" href="#">
                            <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                        </a>
                        <div className="ml-auto d-flex">
                            <div style={{ display: this.state.disqualified === true ? 'none' : 'block' }}>
                                <button className="btn btn-light d-inline-block mr-3 ml-3" type="button" onClick={this.handleBack} ><i className="fas fa-reply"></i></button>
                                <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                            </div>
                        </div>
                    </nav>
                </header>
                {/* main content starts */}
                <main className="main flex-fill mt-2 mt-md-3">
                    <div className="container-fluid">

                        {/* <div className="row p-0">
                                <div className="col-md-4 form-group">
                                    <label>Landlord Name</label>
                                    <input type="text" className="form-control" disabled  />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Email</label>
                                    <input type="text" className="form-control" disabled  />
                                </div>
                                <div className="col-md-4 form-group">
                                    <label>Landlord Phone</label>
                                    <input type="text" className="form-control" disabled />
                                </div>
                            </div> */}
                        {/* content area starts */}
                        <div className="search-claims-form w-100 h-100 p-0 cepp-grid">
                            <h4 className="font-weight-bold text-uppercase text-primary d-block d-sm-none py-2" style={{ whiteSpace: 'nowrap' }}>
                                <svg className="icon-title" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 45.6 48" xmlSpace="preserve"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#005191}" }} /><path className="st0" d="M6.1 11.6h3.8v3.8H6.1zM31.3 6.5h-5.9V.6z" /><path className="st0" d="M24.4 8.5c-.6 0-1-.5-1-1V0H1C.4 0 0 .5 0 1v39.1c0 .5.4 1 1 1h19.3l-.3-.3c-2.5-2.5-3.8-5.7-3.8-9.2 0-3.5 1.3-6.7 3.8-9.2 2.5-2.5 5.7-3.8 9.2-3.8.9 0 1.7.1 2.6.3V8.5h-7.4zM4.1 10.6c0-.6.4-1 1-1h5.8c.6 0 1 .4 1 1v5.8c0 .5-.4 1-1 1H5.1c-.6 0-1-.5-1-1v-5.8zm8.8 21.6H5.1c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1 0 .5-.4 1-1 1zm0-3.8H5.1c-.6 0-1-.5-1-1s.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1s-.4 1-1 1zm8.6-4.3h-7.6c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.6c.6 0 1 .4 1 1s-.5 1-1 1zm0-4.4h-7.6c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.6c.6 0 1 .5 1 1 0 .6-.5 1-1 1z" /><path className="st0" d="M44.7 42.5L39 36.8c.8-1.6 1.3-3.3 1.3-5.2 0-2.9-1.2-5.7-3.2-7.8-2.1-2.1-4.8-3.2-7.8-3.2-2.9 0-5.7 1.2-7.8 3.2-2.1 2.1-3.2 4.8-3.2 7.8 0 2.9 1.1 5.7 3.2 7.8 2.2 2.1 5 3.2 7.8 3.2 1.8 0 3.5-.4 5.1-1.3l5.7 5.7c.6.6 1.5.9 2.3.9.8 0 1.7-.3 2.3-1 1.3-1.1 1.3-3.1 0-4.4zM22.9 38c-1.7-1.7-2.6-4-2.6-6.4 0-2.4.9-4.7 2.6-6.4 1.7-1.7 4-2.6 6.4-2.6 2.4 0 4.7.9 6.4 2.6s2.6 4 2.6 6.4c0 2.4-.9 4.7-2.6 6.4-3.6 3.5-9.3 3.5-12.8 0z" /></svg>
                                Search Claim ERA-OC
                            </h4>

                            <div className="data-section landlord-table">
                                <div className="data-table" style={{ height: this.state.dataheight }}>
                                    <React.Fragment>
                                        <div className="mui-table-border">
                                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                                <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} />
                                            </MuiThemeProvider>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>

                            <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert }}>
                                <button className="close-panel2" onClick={this.closeMsg}>X</button>
                                <div className="alert-text">
                                    {
                                        this.state.msg
                                    }
                                </div>
                            </div>

                        </div>
                        {/* content area ends */}
                        <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                            <CircularProgress />
                        </Backdrop>
                    </div>
                </main>
                {/* </div>
        </main> */}
                {/* main content ends */}

            </div>
        );
    }
};

export default ApplicantGrid;